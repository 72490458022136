import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ItemDate from '../components/ItemDate';
import NewsSubNav from '../components/NewsSubNav';

//define graphql query for contentful
const query = `
{
	rssNewsCollection(order: [date_DESC]) {
	items {
		sys {
		id
		}
		title
		titleNoSpaces
		date
		postText
	}
	}
}`;

const News = () => {
	//define initial state
	const [news, setNews] = useState(null);

	//call use effect to make api call for data
	useEffect(() => {
		console.log('use effect ran');

		//use fetch to get data from api at contentful
		window
			.fetch(
				`https://graphql.contentful.com/content/v1/spaces/2coqe7ro5z7q/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer c7JkGXtPKoWHPUqEGeN1hnoJYPLYET4KhVwBE8oy7b0',
					},
					body: JSON.stringify({ query }),
				}
			)
			.then((response) => response.json())
			.then(({ data, errors }) => {
				if (errors) {
					console.error(errors);
				}
				setNews(data.rssNewsCollection);
			});
	}, []);

	//if the news item is the default value of null, it will return false, and if it does show loading because otherwise we will throw an error when we try to access data that is not there
	if (!news) {
		return 'Loading...';
	}

	return (
		<>
			<section className='section section-container'>
				<div className='d-flex flex-row justify-content-between'>
				<div className='page-title-with-sub-nav'>News</div>
				<NewsSubNav/>
				</div>
				{news.items.map((article) => {
					return (
						<div key={article.sys.id}>
							<h2 className='news-title'>
								<Link to={`/news/${article.titleNoSpaces}`} className='article-title-text text-decoration-none'>
									{article.title}
								</Link>
							</h2>
							<div className='article-date-text'>
								<ItemDate theDate={article.date}/>
							</div>
							{/*the way we are showing only the first paragraph is clunky and might be better done with a renderer from react markdown, do later*/}
							<ReactMarkdown className='item-summary'>
								{article.postText}
							</ReactMarkdown>
						</div>
					);
				})}
			</section>
		</>
	);
};

export default News;
