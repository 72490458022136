/*the date does not come in a format we like, even though it is the vastly superior YYYY-MM-DD format, this is not what americans will be used to and so we splice around to display the date in a format we want. we could probably simplify this by using a date object in at the end of the fetch request */

const ItemDate = (props) => {
	const theDate = props.theDate;

	return (
		<p className='news-date'>
			{theDate.slice(5, 7) +
				'.' +
				theDate.slice(8, 10) +
				'.' +
				theDate.slice(0, 4)}
		</p>
	);
};

export default ItemDate;
