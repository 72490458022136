import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ItemDate from '../components/ItemDate';
import NewsSubNav from '../components/NewsSubNav';

const query = `{
    rssNeedsCollection(order: [date_DESC]) {
        items {
            sys {
                id
            }
            titleNoSpaces
            title
            date
            shortSummary
        }
    } 
}`;

const Needs = () => {
	const [needs, setNeeds] = useState(null);

	useEffect(() => {
		window
			.fetch(
				`https://graphql.contentful.com/content/v1/spaces/2coqe7ro5z7q/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer c7JkGXtPKoWHPUqEGeN1hnoJYPLYET4KhVwBE8oy7b0',
					},
					body: JSON.stringify({ query }),
				}
			)
			.then((response) => response.json())
			.then(({ data, errors }) => {
				if (errors) {
					console.error(errors);
				}
				setNeeds(data.rssNeedsCollection);
			});
	}, []);

	if (!needs) {
		return 'Loading...';
	}

	return (
		<section className='section section-container'>
			<div className='d-flex flex-row justify-content-between'>
			<div className='page-title-with-sub-nav'>Needs</div>
			<NewsSubNav/>
			</div>
			{needs.items.map((need) => {
				return (
					<div key={need.sys.id}>
						<h2 className='needs-title'>
							<Link to={`/needs/${need.titleNoSpaces}`} className='article-title-text text-decoration-none'>
								{need.title}
							</Link>
						</h2>
						<div className='article-date-text'>
						<ItemDate theDate={need.date}/>
						</div>
						<ReactMarkdown className='item-summary'>
							{need.shortSummary}
						</ReactMarkdown>
					</div>
				);
			})}
		</section>
	);
};

export default Needs;
