import { useState } from 'react';
import SvgMap from '../components/SvgMap';
import MapInfo from '../components/MapInfo';

const Map = () => {
	const [activeMarker, setActiveMarker] = useState('unselected');
	const [markers, setMarkers] = useState({
		unselected: true,
		juba: false,
		rumbek: false,
		lietnhom: false,
		bor: false,
		boma: false,
		lainya: false,
		yei: false,
		kajo: false,
		adjumani: false,
	});

	const mapHighlight = (id) => {
		if (id === activeMarker) {
			return;
		} else {
			if (markers.unselected === true) {
				setMarkers({ ...markers, unselected: false, [id]: true });

				setActiveMarker(id);
			} else {
				let oldId = activeMarker;
				setMarkers({ ...markers, [id]: true, [oldId]: false });

				setActiveMarker(id);
			}
		}
	};

	return (
		<section className='section section-container'>
			<div className='page-title'>Map</div>
			{/* SvgMap component just contains the svg map to be acted upon by the rest of this page component, this is done because go look at SvgMap.js, that crap is long and makes the file annoying to edit */}

			<div className='d-flex justify-content-center h-200'>
				<div className='text-center'>
					<MapInfo {...markers} />
				</div>
			</div>

			<div className='map-container'>
				<div className='d-flex justify-content-center width-80'>
					<SvgMap {...markers} />
				</div>
				<div className='svg-map-buttons d-flex flex-column justify-content-center align-items-center mb-3 width-20'>
					<button
						className={`btn map-button ${
							markers.juba ? 'active-map-button' : null
						}`}
						id='juba-but'
						onClick={() => mapHighlight('juba')}
					>
						Juba
					</button>
					<button
						className={`btn map-button ${
							markers.rumbek ? 'active-map-button' : null
						}`}
						id='rumbek-but'
						onClick={() => mapHighlight('rumbek')}
					>
						Rumbek
					</button>
					<button
						className={`btn map-button ${
							markers.lietnhom ? 'active-map-button' : null
						}`}
						id='lietnhom-but'
						onClick={() => mapHighlight('lietnhom')}
					>
						Lietnhom
					</button>
					<button
						className={`btn map-button ${
							markers.bor ? 'active-map-button' : null
						}`}
						id='bor-but'
						onClick={() => mapHighlight('bor')}
					>
						Bor
					</button>
					<button
						className={`btn map-button ${
							markers.boma ? 'active-map-button' : null
						}`}
						id='boma-but'
						onClick={() => mapHighlight('boma')}
					>
						Boma
					</button>
					<button
						className={`btn map-button ${
							markers.lainya ? 'active-map-button' : null
						}`}
						id='lainya-but'
						onClick={() => mapHighlight('lainya')}
					>
						Lainya
					</button>
					<button
						className={`btn map-button ${
							markers.yei ? 'active-map-button' : null
						}`}
						id='yei-but'
						onClick={() => mapHighlight('yei')}
					>
						Yei
					</button>
					<button
						className={`btn map-button ${
							markers.kajo ? 'active-map-button' : null
						}`}
						id='kajo-keji-but'
						onClick={() => mapHighlight('kajo')}
					>
						Kajo-Keji
					</button>
					<button
						className={`btn map-button ${
							markers.adjumani ? 'active-map-button' : null
						}`}
						id='adjumani-but'
						onClick={() => mapHighlight('adjumani')}
					>
						Adjumani, Uganda
					</button>
				</div>
			</div>
		</section>
	);
};

export default Map;
