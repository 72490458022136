import { NavLink } from 'react-router-dom';
import logo from '../assets/logo_radio-south-sudan.png';

const Navbar = () => {
	return (
		<nav className='navbar navbar-expand-lg navbar-dark bg-dark-green'>
			<div className='navbar-brand d-flex align-items-center'>
				<NavLink className='ms-3 my-1 m' to='/'>
					<img src={logo} alt='Radio South Sudan Logo' />
				</NavLink>
				<div className='d-flex justify-content-center align-items-center mx-3 donate-but font-brandonOne'>
					<NavLink to='/donate' className='donate-button'>
						Donate
					</NavLink>
				</div>
			</div>
			<button
				className='navbar-toggler w-auto'
				type='button'
				data-bs-toggle='collapse'
				data-bs-target='#navbarSupportedContent'
				aria-controls='navbarSupportedContent'
				aria-expanded='false'
				aria-label='Toggle navigation'
			>
				<span className='navbar-toggler-icon'></span>
			</button>
			<div
				className='collapse navbar-collapse justify-content-lg-end align-items-center me-2'
				id='navbarSupportedContent'
			>
				<ul className='navbar-nav font-brandonOne align-items-center'>
					<li className='nav-item'>
						<NavLink className='nav-link' to='/about'>
							About
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink className='nav-link' to='/news'>
							News
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink className='nav-link' to='/map'>
							Map
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink className='nav-link' to='/gallery'>
							Gallery
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink className='nav-link' to='/partners'>
							Partners
						</NavLink>
					</li>
					<li className='nav-item'>
						<NavLink className='nav-link' to='/contact'>
							Contact
						</NavLink>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default Navbar;
