const MapInfo = (props) => {
	//	console.log(props);
	return (
		<>
			<div
				id='intro-para'
				className={
					props.unselected
						? 'map-help-text'
						: 'map-help-text, hide-map-info'
				}
			>
				<p>
					Click a city below to highlight a radio station's location
					and see more information about the station.
				</p>
			</div>
			<div
				id='juba-info'
				className={props.juba ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>Radio Sit Sara FM 92.6</p>
				<p>ACROSS FM Radio</p>
			</div>
			<div
				id='rumbek-info'
				className={props.rumbek ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>Word of Hope FM 94.5</p>
			</div>
			<div
				id='lietnhom-info'
				className={props.lietnhom ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>Peace and Reconciliation Radio FM 91.15</p>
			</div>
			<div
				id='bor-info'
				className={props.bor ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>Peace and Reconciliation Radio FM 91.15</p>
				<p>Voice of Reconciliation 98.4 FM</p>
			</div>
			<div
				id='boma-info'
				className={props.boma ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>SEA Partners</p>
			</div>
			<div
				id='lainya-info'
				className={props.lainya ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>Radio Gumbiri FM 102.6</p>
			</div>
			<div
				id='yei-info'
				className={props.yei ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>EPC Radio Services FM 88.6</p>
			</div>
			<div
				id='kajo-keji-info'
				className={props.kajo ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>Hope FM 89.6</p>
			</div>
			<div
				id='adjumani-info'
				className={props.adjumani ? 'active-map-info' : 'hide-map-info'}
			>
				<h5>Stations</h5>
				<p>Radio Usalama FM 99.7</p>
			</div>
		</>
	);
};

export default MapInfo;
