import aboutImageOne from '../assets/2014-02-23-15.51.41-300x225.jpg';
//import aboutImageTwo from '../assets/IMG_0865-300x225.jpg';

const About = () => {
	return (
		<section className='section section-container'>
			<div className='page-title'>About</div>
			<img
				className='img-fluid float-end ms-3'
				src={aboutImageOne}
				alt='EPC Raidio 88.4'
			/>
			<p>
				On the 9th of July 2011, South Sudan voted to become the world's
				newest independent country. Since then, the South Sudanese have
				been hard at work creating the physical building blocks of a
				nation, roads, local governments, economic systems, schools, and
				more as well as to create a national culture that unifies all
				60+ indigenous ethnic groups after a half-century of civil war.
			</p>

			<p>
				<b className='fst-italic'>Radio South Sudan</b> began
				broadcasting with EPC Radio Services on February 22, 2014 over a
				low-power FM signal on frequency 88.4, in Yei, South Sudan.
			</p>

			<p>
				We partner with churches and organizations having installed and
				launched 10 stations.
			</p>

			<p>
				South Sudan Yei, South Sudan - EPC Radio Services FM 88.6 <br />
				Kajo-Keji, South Sudan - Hope FM 89.6 Rumbek, South Sudan - Word
				<br />
				of Hope FM 94.5 Lietnhom, South Sudan - Peace and Reconciliation
				<br />
				Radio FM 91.15 Bor, South Sudan - Peace and Reconciliation Radio
				<br />
				FM 91.15 Juba, South Sudan - Radio Sit Sarah FM 92.6 Bor, South
				<br />
				Sudan - Voice of Reconciliation 98.4 FM Lainya, South
				<br />
				Sudan - Radio Gumbiri FM 102.6 Juba, South Sudan - ACROSS FM
				Radio Upper <br />
				Boma, South Sudan - SEA Partners
			</p>

			<p>
				Uganda Adjumani, Uganda - Radio Usalama FM 99.7 <br />
				(partnering with High Adventure Gospel Communication Ministries
				reaching South Sudanese in displaced camps in Northern Uganda)
			</p>

			<p>
				These stations are run by South Sudanese for South Sudanese. The
				programming of the radio stations spread the gospel from local
				churches and pastors, feature conversations about conflict
				resolution, mediation, forgiveness, and reconciliation, as well
				as general information related to health services and the new
				programs of the local governments.
			</p>

			<h2 className='article-title-text'>What We Do</h2>
			<p>
				Radio South Sudan serves the people of South Sudan through
				Radio. We do not own the stations. The stations are given to the
				Churches and they are responsible for running the station. We
				partner with Churches to bring radio sites to help spread the
				gospel from local churches and pastors, conversations about
				conflict resolution, mediation, forgiveness, and reconciliation,
				as well as general information related to health services and
				the new programs of the local governments.
			</p>

			<p>
				Radio South Sudan is a United States 501c3 non-profit with a
				mission to serve the people of South Sudan through Radio. Radio
				South Sudan will supply a 150 watt transmitter, sound equipment,
				antenna, computer and other needed equipment. The equipment, a
				$20,000 USD value, including installation is given at no charge
				to them. South Sudanese are responsible for the daily operation
				and broadcasting of the station, indigenous programming created
				by the indigenous community.
			</p>
		</section>
	);
};

export default About;
