import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import ItemDate from '../components/ItemDate';
import Error from './Error';
import NewsSubNav from '../components/NewsSubNav';
// eslint-disable-line react-hooks/exhaustive-deps

const NewsSingle = () => {
	const params = useParams();
	const newsTitle = params.newsTitle;

	const query = `{
		rssNewsCollection(where: {titleNoSpaces: "${newsTitle}"}) {
			items {
				title
				titleNoSpaces
				date
				postText
			}
		}
	}`;

	const [newsItem, setNewsItem] = useState(null);

	useEffect(() => {
		window
			.fetch(
				`https://graphql.contentful.com/content/v1/spaces/2coqe7ro5z7q/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer c7JkGXtPKoWHPUqEGeN1hnoJYPLYET4KhVwBE8oy7b0',
					},
					body: JSON.stringify({ query }),
				}
			)
			.then((response) => response.json())
			.then(({ data, errors }) => {
				if (errors) {
					console.error(errors);
				}
				if (data.rssNewsCollection.items.length === 0) {
					setNewsItem('error');
				} else {
					setNewsItem(data.rssNewsCollection.items[0]);
				}
			});
	}, [query]);

	if (!newsItem) {
		return 'Loading...';
	}

	if (newsItem === 'error') {
		return (
			<>
				<Error />
			</>
		);
	}

	return (
		<>
			<section className='section section-container'>
				<div>
					<div className='d-flex flex-row justify-content-between'>
						<div className='page-title-with-sub-nav'>News</div>
						<NewsSubNav/>
					</div>
					<h1 className='article-title-text'>{newsItem.title}</h1>
					<div className='article-date-text'>
						<ItemDate theDate={newsItem.date} />
					</div>
					<ReactMarkdown>{newsItem.postText}</ReactMarkdown>
					<Link to='/news'>Back to News</Link>
				</div>
			</section>
		</>
	);
};

export default NewsSingle;
