import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import Home from './pages/Home';
import About from './pages/About';
import Map from './pages/Map';
import News from './pages/News';
import NewsSingle from './pages/NewsSingle';
import Needs from './pages/Needs';
import NeedsSingle from './pages/NeedsSingle';
import Gallery from './pages/Gallery';
import Partners from './pages/Partners';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import Error from './pages/Error';
import SharedLayout from './pages/SharedLayout';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<SharedLayout />}>
					<Route index element={<Home />} />
					<Route path='about' element={<About />} />
					<Route path='news'>
						<Route index element={<News />} />
						<Route path=':newsTitle' element={<NewsSingle />} />
					</Route>
					<Route path='needs'>
						<Route index element={<Needs />} />
						<Route path=':needsTitle' element={<NeedsSingle />} />
					</Route>
					<Route path='map' element={<Map />} />
					<Route path='gallery' element={<Gallery />} />
					<Route path='partners' element={<Partners />} />
					<Route path='contact' element={<Contact />} />
					<Route path='donate' element={<Donate />} />
					<Route path='*' element={<Error />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
