import { Link, Outlet } from 'react-router-dom';
import gdLogo from '../assets/gwartney-designs-logo.png';

import Navbar from '../components/Navbar';
const SharedLayout = () => {
	return (
		<>
			<Navbar />
			<Outlet />
			<footer className='d-flex flex-row justify-content-between align-items-center bg-dark-green mt-3'>
				<div className='ms-4'>&copy; Radio South Sudan 2023</div>
				<div className='me-4'>
					<Link href='https://gwartneydesigns.com/'>
						<img src={gdLogo} alt='Gwartney Designs Logo' />
					</Link>
				</div>
			</footer>
		</>
	);
};
export default SharedLayout;
