import mikeImage from '../assets/2014-02-25-09.46.58.jpg';

const Contact = () => {
	return (
		<section className='section section-container'>
			<div className='page-title'>Contact</div>
			<div className='row align-items-start'>
				<div className='col'>
					<p>
						For more information about Radio South Sudan, please
						contact Mike Gwartney.
					</p>

					{/*yes this address is wrong, will correct later*/}
					<h4>Mailing Address:</h4>
					<p>129 Chapel Hill Dr</p>
					<p>Double Oak, Texas 75077</p>

					<h4>Phone:</h4>
					<p>214.738.7303</p>

					<h4>Email:</h4>
					<a href='mailto: mgwartney@radiosouthsudan.com'>
						mgwartney@radiosouthsudan.com
					</a>
				</div>
				<div className='col'>
					<img src={mikeImage} alt='Mike Gwartney and Bishop Taban' />
				</div>
			</div>
		</section>
	);
};

export default Contact;
