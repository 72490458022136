import { Link, useParams, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import ItemDate from '../components/ItemDate';
import Error from './Error';
import NewsSubNav from '../components/NewsSubNav';

const NewsSingle = () => {
	const params = useParams();
	const needsTitle = params.needsTitle;

	const query = `{
        rssNeedsCollection(where: {titleNoSpaces: "${needsTitle}"}) {
            items {
                title
                titleNoSpaces
                date
                shortSummary
                description
                cost
                location
                needs 
            }
        } 
    }`;

	const [needsItem, setNeedsItem] = useState(null);

	useEffect(() => {
		window
			.fetch(
				`https://graphql.contentful.com/content/v1/spaces/2coqe7ro5z7q/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer c7JkGXtPKoWHPUqEGeN1hnoJYPLYET4KhVwBE8oy7b0',
					},
					body: JSON.stringify({ query }),
				}
			)
			.then((response) => response.json())
			.then(({ data, errors }) => {
				if (errors) {
					console.error(errors);
				}
				if (data.rssNeedsCollection.items.length === 0) {
					setNeedsItem('error');
				} else {
					setNeedsItem(data.rssNeedsCollection.items[0]);
				}
			});
	}, [query]);

	if (!needsItem) {
		return 'Loading...';
	}

	console.log(needsItem);

	if (needsItem === 'error') {
		return (
			<>
				<Error />
			</>
		);
	}

	return (
		<>
			<section className='section section-container'>
				<div className='d-flex flex-row justify-content-between'>
					<div className='page-title-with-sub-nav'>Needs</div>
					<NewsSubNav />
				</div>
				<h1 className='article-title-text'>{needsItem.title}</h1>
				<div className='article-date-text'>
					<ItemDate theDate={needsItem.date} />
				</div>
				<ReactMarkdown>{needsItem.description}</ReactMarkdown>
				<p>
					<b>Cost:</b> $ {needsItem.cost}
				</p>
				<p>
					<b>Location:</b> {needsItem.location}
				</p>
				<p>
					<b>Radio Needs:</b> {needsItem.needs}
				</p>
				<div className='d-flex justify-content-center align-items-center mx-3 donate-but-needs font-brandonOne mb-50'>
					<NavLink to='/donate' className='donate-but-needs-text'>
						Donate
					</NavLink>
				</div>
				<Link to='/needs'>Back to Needs</Link>
			</section>
		</>
	);
};

export default NewsSingle;
