const SvgMap = (props) => {
	return (
		<svg
			className='w-100 h-auto'
			version='1.1'
			id='Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			x='0px'
			y='0px'
			width='1000px'
			height='1000px'
			viewBox='0 0 1000 1000'
			style={{ enableBackground: 'new 0 0 1000 1000' }}
			xmlSpace='preserve'
		>
			<title>Map of South Sudan</title>
			<desc>
				Map of South Sudan with markers for Radio South Sudan radio
				station locations.
			</desc>
			<path
				className='svg-map-fill mapOutline'
				d='M574.73,857.02c-6.7,0-12.11-4.75-15.15-7.42l-54.75-48.07c-3.28-2.88-6.34-7.53-9.59-12.46
	c-2.97-4.5-6.33-9.6-8.92-11.54c-4.41-3.31-11.31-5.1-15.96-2.1c-1.66,1.07-3.17,2.8-4.76,4.62c-1.24,1.42-2.52,2.89-4.04,4.27
	c-12.05,10.94-30.54,8.6-43.83,2.76l-1.71-0.76c-6.5-2.89-13.23-5.89-17.74-3.24c-1.32,0.78-2.59,2.16-3.93,3.63
	c-1.06,1.16-2.16,2.36-3.47,3.5c-12.92,11.23-33.17,4.3-39.07,1.92c-8.49-3.44-14.99-9.3-18.29-16.5c-0.99-2.15-1.63-4.29-2.26-6.36
	c-0.52-1.73-1.02-3.36-1.66-4.81c-1.64-3.73-4.59-6.98-7.7-10.42c-2.68-2.96-5.45-6.01-7.67-9.66
	c-4.23-6.94-10.53-12.52-17.29-15.31l-0.57-0.23c-1.74-0.7-4.13-1.66-5.99-3.76c-2.73-3.07-2.96-6.9-3.14-9.69l-0.03-0.52
	c-0.85-12.96-8.88-25.16-23.87-36.26c-5.15-3.82-10.93-6.78-17.05-9.92c-8.84-4.53-17.98-9.22-25.26-16.75
	c-9.24-9.56-13.74-21.86-18.09-33.75c-3.41-9.32-6.63-18.12-11.93-25.58c-9.98-14.05-27-22.49-43.46-30.66
	c-20.39-10.11-41.48-20.57-50.95-42c-2.76-6.24-4.26-12.94-5.71-19.42c-2.29-10.23-4.46-19.9-11.2-26.13
	c-8.03-7.42-20.69-8.11-32.94-8.77l-1.55-0.08c-13.83-0.76-30.83-3.34-39.69-16.63c-10.63-15.95-2.4-36.08,5.95-51.83
	c12.37-23.32,25.16-47.42,39.16-70.4c1.03-1.68,2.1-3.32,3.14-4.9c2.04-3.1,3.96-6.03,5.43-9.13l8.38-17.66
	c0.92-1.94,2.32-4.88,5.2-6.78c2.96-1.95,6.27-2.02,8.47-2.07c20.57-0.47,41.43-0.39,62,0.25c1.82,0.06,3.48,1.07,4.36,2.66
	c2.24,4.05,4.51,8.08,6.78,12.12c4.5,7.99,9.15,16.24,13.49,24.52c0.86,1.63,7.31,2.65,9.43,2.98c0.53,0.08,1.03,0.16,1.5,0.24
	c5.35,0.9,11.06,1.2,17.44,0.92c0.74-0.03,1.48-0.11,2.23-0.18c1.16-0.11,2.35-0.22,3.62-0.24c5.9-0.57,11.87-1.62,18.19-2.73
	c12.59-2.21,25.61-4.5,38.39-2.81l31.5-0.13l0.27-30.94c0.01-1.41,0.6-2.75,1.62-3.71c1.02-0.97,2.39-1.47,3.8-1.4l97.19,4.87
	c2.5,0.13,4.55,2.02,4.87,4.5l2.74,21.42c4.81-2,9.99-2.83,14.77-3.6c1.21-0.2,2.42-0.39,3.61-0.6c3.17-0.56,6.79-1.26,9.54-2.45
	c3.04-1.32,5.97-2.41,8.87-3.31c0.27-4.14,0.3-8.32,0.08-12.46c-0.03-0.55-0.1-1.11-0.16-1.67c-0.25-2.19-0.56-4.91,0.48-7.74
	l0.12-0.32c0.77-2.1,1.72-4.71,3.84-6.87c1.94-1.98,4.6-2.76,6.73-3.39c0.5-0.15,0.98-0.28,1.39-0.44c4.87-1.8,9.73-3.61,14.6-5.43
	c4.86-1.82,9.72-3.65,14.57-5.49c2.43-0.92,4.85-1.84,7.28-2.77c0.17-0.07,0.54-0.25,1-0.46c4.78-2.24,7.67-3.59,10.58-1.76
	c1.61,1.01,3.22,2.06,4.83,3.12c3.07,2.01,5.96,3.9,8.96,5.56c1.79,0.99,3.66,1.88,5.64,2.81c2.54,1.2,5.17,2.44,7.75,3.98
	c9.74,5.82,19.09,12.06,28.13,18.1c7.32,4.88,14.84,9.91,22.54,14.69c3.09-5.08,7.61-12.35,8.37-13.4
	c3.76-5.14,8.04-10.35,13.47-16.4c4.3-4.8,8.79-9.53,13.14-14.1c6.56-6.9,13.34-14.04,19.46-21.43c5.44-6.57,9.76-12.65,13.2-18.58
	c0.34-0.58,0.68-1.28,1.05-2.01c0.56-1.11,1.13-2.27,1.86-3.4c0.44-0.7,1.15-1.58,2.46-3.22c1.66-2.08,5.55-6.94,6.21-8.72
	c0.56-1.51,0.21-3.56-0.19-5.95c-0.44-2.62-0.94-5.59-0.36-8.78c1.08-5.97,1.04-12.49,0.88-17.5c-0.22-7.22-1.04-13.37-2.49-18.8
	c-0.16-0.61-0.41-1.19-0.7-1.87c-0.67-1.57-1.5-3.53-1.53-6.34c-0.01-0.52,0-1.05,0.01-1.59c0.02-1.61,0.05-3.27-0.42-4.02
	c-0.31-0.49-1.22-1.16-2.03-1.75l-25.41-18.64c-1.85-1.36-2.58-3.76-1.8-5.92c0.78-2.15,2.88-3.54,5.17-3.4
	c8.34,0.5,17.3,0.62,28.18,0.39c4.15-0.09,8.53-0.23,14.22-0.45c0.84-0.03,1.67-0.07,2.51-0.11c1.54-0.07,3.14-0.15,4.75-0.18
	c0.49-0.01,0.96,0.02,1.39,0.05c0-0.32-0.01-0.62-0.01-0.86c-0.01-0.66-0.02-1.34,0.02-1.99c0.03-0.42,0.1-1.56,0.21-3.1
	c0.27-3.95,0.97-14.4,0.97-15.89c-0.07-1.7,0.71-3.33,2.08-4.35c1.4-1.04,3.24-1.3,4.87-0.7l27.62,10.22
	c1.27,0.47,3.19,1.18,4.8,2.79c3.06,3.06,2.96,6.93,2.9,9.24c-0.02,0.65-0.03,1.27,0.01,1.67c0.15,1.23,0.63,2.55,1.19,4.08
	c0.31,0.85,0.62,1.7,0.89,2.57c2.39,7.62,3.66,15.55,4.78,22.54l12.58,78.55c0.31,1.91,0.64,3.8,0.97,5.69
	c0.7,3.96,1.42,8.05,1.88,12.3l2.22,20.34c0.36,3.32,0.81,6.42,2.32,7.99c1.14,1.18,3.38,1.96,5.35,2.64l18.96,6.58
	c4.15,1.44,9.31,3.23,13.36,6.95c3.46,3.18,5.53,7.16,7.35,10.68l9.38,18.12c2.33,4.5,4.74,9.15,6.26,14.36
	c1.02,3.51,1.01,6.75,1,9.62c-0.01,1.81-0.01,3.52,0.24,5.11c0.26,1.63,0.6,3.25,0.97,4.97c0.58,2.76,1.19,5.61,1.46,8.67
	c0.51,5.66,0.54,11.35,0.1,16.92c-0.15,1.9-0.36,3.8-0.62,5.69c8.82-3.86,17.95-7.74,27.91-9.15c2.33-0.33,4.57,0.94,5.48,3.09
	c10.09,23.74,3.79,53.81-14.99,71.5c-16.81,15.84-42.94,21.11-64.81,13.79c-0.58,12.93-3.94,25.79-9.75,37.36
	c27.51,2.85,54.76,8.43,71.68,29.14c4.37,5.35,7.64,11.31,10.79,17.07c2.79,5.09,5.43,9.9,8.72,14.22
	c6.62,8.67,15.65,15.07,25.21,21.84c9.03,6.4,18.37,13.01,25.74,21.83c10.62,12.7,15.87,28.32,20.95,43.43
	c2.3,6.83,4.67,13.89,7.49,20.46c8.86,20.65,27.73,39.94,46.66,36.88c0.71-0.11,1.44-0.08,2.14,0.1
	c15.27,4.01,23.42,19.37,24.72,32.8c1.33,13.68-2.91,26.97-6.65,38.7c-0.68,2.15-2.68,3.6-4.92,3.6c-0.03,0-0.05,0-0.08,0
	c-37.77-0.6-76,0.61-113.67,3.6c-1.03,1.45-2.6,4.01-3.37,5.25c-1.07,1.73-1.6,2.59-2.05,3.18c-2.71,3.52-5.64,6.99-8.71,10.31
	c-5.84,6.34-12.37,12.35-19.4,17.88c-3.49,2.74-7.16,5.41-10.91,7.93c-1.64,1.1-3.29,1.92-4.89,2.71c-1.45,0.72-2.82,1.39-3.89,2.16
	c-1.54,1.1-3.04,2.79-4.63,4.57c-1.3,1.46-2.65,2.97-4.17,4.34c-3.87,3.48-7.61,6.14-11.42,8.15c-8.71,4.57-17.51,5.22-26.02,5.86
	c-4.9,0.37-9.53,0.71-14.07,1.77c-39.13,9.13-83.36,9.43-139.18,0.94c-11.15-1.7-23.8-3.62-32.8,2.18c-0.67,0.43-1.37,0.93-2.1,1.46
	c-2.6,1.88-5.56,4.01-9.52,4.61C576.1,856.97,575.41,857.02,574.73,857.02z M475.98,763.53c6.08,0,12.09,2.39,16.55,5.74
	c4.02,3.03,7.74,8.66,11.34,14.12c2.73,4.14,5.55,8.42,7.78,10.38l54.75,48.07c3.1,2.72,6.32,5.19,8.89,4.81
	c1.48-0.22,3.19-1.46,4.99-2.76c0.81-0.59,1.65-1.19,2.55-1.77c12.28-7.92,27.62-5.59,39.95-3.71c54.48,8.28,97.47,8.03,135.28-0.79
	c5.33-1.24,10.57-1.63,15.65-2.01c7.81-0.58,15.18-1.13,22-4.71c3.04-1.6,6.09-3.78,9.31-6.68c1.1-0.99,2.2-2.22,3.36-3.53
	c1.9-2.13,3.86-4.34,6.34-6.11c1.75-1.25,3.56-2.15,5.31-3.01c1.36-0.67,2.64-1.3,3.71-2.02c3.54-2.37,7-4.89,10.29-7.47
	c6.6-5.19,12.72-10.83,18.19-16.76c2.86-3.1,5.59-6.34,8.12-9.62c0.19-0.26,0.88-1.38,1.43-2.28c4.43-7.22,6.14-9.72,9.48-10
	c37.33-3.03,75.22-4.32,112.69-3.84c3.19-10.16,6.06-20.58,5.05-30.91c-0.9-9.26-6.32-20.36-16.12-23.53
	c-24.78,3.18-46.65-19.97-56.61-43.18c-2.98-6.95-5.43-14.21-7.79-21.24c-4.99-14.85-9.71-28.88-19.08-40.09
	c-6.51-7.79-14.9-13.73-23.79-20.02c-9.77-6.92-19.87-14.08-27.45-24c-3.77-4.94-6.72-10.32-9.57-15.53
	c-3.07-5.6-5.97-10.89-9.73-15.5c-16.01-19.59-44.6-23.66-72.52-26.14c-1.79-0.16-3.36-1.23-4.16-2.84
	c-0.8-1.61-0.71-3.51,0.24-5.04c8.57-13.77,12.91-30.29,12.2-46.5c-0.08-1.83,0.81-3.56,2.35-4.56s3.48-1.11,5.12-0.29
	c19.36,9.63,44.84,5.85,60.58-8.98c14.74-13.89,20.31-36.89,14-56.1c-7.59,1.69-15.05,4.96-22.3,8.13c-2.6,1.14-5.3,2.32-7.97,3.42
	c-1.69,0.69-3.61,0.44-5.07-0.65c-1.46-1.09-2.23-2.87-2.03-4.69c0.12-1.11,0.5-2.86,1.03-5.2c0.21-0.92,0.39-1.72,0.45-2.09
	c0.39-2.38,0.69-4.81,0.88-7.25c0.39-4.99,0.36-10.1-0.1-15.18c-0.22-2.45-0.73-4.88-1.28-7.46c-0.38-1.77-0.76-3.61-1.06-5.5
	c-0.38-2.42-0.37-4.63-0.37-6.76c0.01-2.48,0.02-4.62-0.59-6.69c-1.24-4.24-3.41-8.44-5.52-12.5l-9.38-18.12
	c-1.5-2.89-3.04-5.88-5.16-7.81c-2.47-2.27-6.18-3.56-9.76-4.8l-18.96-6.58c-2.76-0.96-6.55-2.27-9.4-5.23
	c-4.09-4.24-4.74-10.14-5.16-14.05l-2.22-20.34c-0.43-3.91-1.09-7.66-1.79-11.63c-0.34-1.94-0.68-3.88-1-5.85l-12.58-78.55
	c-1.06-6.64-2.27-14.17-4.44-21.08c-0.23-0.72-0.48-1.42-0.74-2.11c-0.68-1.86-1.45-3.97-1.74-6.39c-0.14-1.15-0.11-2.23-0.09-3.18
	c0.01-0.53,0.03-1.27-0.01-1.7c-0.28-0.12-0.69-0.28-0.94-0.37l-4.25-1.57l0,0l-16.78-6.21c-0.15,2.35-0.35,5.43-0.62,9.49
	c-0.1,1.5-0.18,2.62-0.2,3.03c-0.02,0.34-0.01,0.74,0,1.18c0.05,2.82,0.13,7.54-3.76,10.08c-2.51,1.64-5.25,1.43-6.88,1.3
	c-0.31-0.02-0.61-0.06-0.89-0.05c-1.48,0.03-2.96,0.1-4.44,0.17c-0.87,0.04-1.74,0.08-2.61,0.12c-5.75,0.22-10.19,0.36-14.39,0.45
	c-4.35,0.09-8.41,0.13-12.25,0.11l12,8.81c1.38,1.01,3.27,2.4,4.63,4.53c2.14,3.36,2.08,7.04,2.04,9.73
	c-0.01,0.45-0.01,0.89-0.01,1.33c0.01,0.72,0.21,1.22,0.7,2.39c0.37,0.86,0.82,1.93,1.18,3.25c1.66,6.21,2.59,13.13,2.84,21.15
	c0.17,5.52,0.21,12.75-1.04,19.66c-0.26,1.42,0.05,3.28,0.38,5.24c0.53,3.18,1.2,7.14-0.31,11.22c-1.14,3.11-4.23,7.09-7.84,11.61
	c-0.71,0.89-1.6,2.01-1.82,2.33c-0.43,0.67-0.86,1.54-1.32,2.46c-0.42,0.84-0.86,1.72-1.36,2.58c-3.73,6.42-8.37,12.96-14.17,19.98
	c-6.34,7.67-13.25,14.93-19.93,21.96c-4.29,4.52-8.73,9.19-12.93,13.87c-5.19,5.79-9.27,10.75-12.82,15.61
	c-0.89,1.25-9.44,15.1-10.34,16.76c-0.64,1.26-1.77,2.21-3.13,2.61c-1.39,0.42-2.89,0.23-4.14-0.52
	c-9.46-5.69-18.57-11.77-27.38-17.65c-8.94-5.97-18.18-12.14-27.7-17.83c-2.15-1.28-4.44-2.37-6.87-3.51
	c-2.04-0.96-4.15-1.96-6.23-3.11c-3.33-1.85-6.53-3.94-9.61-5.96c-0.93-0.61-1.85-1.21-2.78-1.81c-0.94,0.4-2.04,0.92-2.59,1.17
	c-0.79,0.37-1.41,0.65-1.69,0.76c-2.43,0.93-4.86,1.85-7.3,2.78c-4.87,1.85-9.74,3.68-14.62,5.51c-4.87,1.83-9.75,3.64-14.64,5.45
	c-0.68,0.25-1.38,0.46-2.06,0.66c-0.72,0.21-1.9,0.56-2.36,0.81c-0.52,0.62-1,1.93-1.42,3.08l-0.12,0.34c-0.19,0.52-0.04,1.9,0.09,3
	c0.09,0.78,0.17,1.55,0.21,2.3c0.3,5.82,0.17,11.7-0.4,17.49c-0.21,2.16-1.76,3.96-3.86,4.5c-3.55,0.91-7.12,2.15-10.91,3.79
	c-3.78,1.64-8.09,2.48-11.84,3.15c-1.24,0.22-2.51,0.42-3.78,0.63c-6.64,1.07-12.92,2.08-17.13,5.77c-1.44,1.26-3.45,1.62-5.23,0.94
	c-1.78-0.68-3.05-2.28-3.29-4.18l-3.19-24.95l-87.49-4.38l-0.27,30.66c-0.02,2.83-2.32,5.11-5.15,5.12l-36.96,0.15
	c-0.23-0.01-0.49-0.02-0.73-0.05c-11.29-1.56-23.63,0.61-35.56,2.7c-6.31,1.11-12.84,2.25-19.23,2.86
	c-0.16,0.02-0.32,0.02-0.49,0.02c-0.88,0-1.86,0.09-2.9,0.19c-0.92,0.09-1.84,0.17-2.75,0.21c-7.12,0.31-13.53-0.03-19.61-1.05
	c-0.44-0.07-0.9-0.15-1.39-0.22c-5.46-0.86-13.7-2.15-16.97-8.38c-4.27-8.14-8.89-16.33-13.35-24.25c-1.8-3.2-3.6-6.4-5.39-9.61
	c-19.43-0.56-39.1-0.61-58.51-0.16c-0.83,0.02-2.55,0.06-3.02,0.37c-0.47,0.31-1.17,1.78-1.54,2.58l-8.38,17.66
	c-1.77,3.74-3.98,7.11-6.12,10.37c-1.04,1.58-2.02,3.07-2.95,4.61C45.61,354.8,32.9,378.76,20.6,401.94
	c-7.02,13.23-14.09,29.86-6.49,41.27c6.31,9.47,20.24,11.41,31.66,12.04l1.54,0.08c13.42,0.73,28.63,1.55,39.39,11.5
	c9.1,8.42,11.73,20.13,14.27,31.46c1.41,6.27,2.73,12.19,5.08,17.5c7.96,18.01,26.48,27.2,46.09,36.92
	c17.6,8.73,35.8,17.76,47.29,33.93c6.1,8.59,9.72,18.46,13.21,28.01c4.17,11.38,8.1,22.13,15.82,30.12
	c6.09,6.3,14.08,10.4,22.54,14.73c6.22,3.19,12.66,6.49,18.49,10.81c17.54,12.99,26.97,27.76,28.03,43.89l0.04,0.55
	c0.07,1.07,0.19,3.07,0.55,3.48c0.25,0.28,1.42,0.75,2.12,1.03l0.66,0.27c8.87,3.66,16.75,10.58,22.17,19.49
	c1.71,2.81,4.04,5.38,6.5,8.09c3.53,3.9,7.18,7.93,9.5,13.19c0.9,2.03,1.51,4.04,2.09,5.99c0.55,1.82,1.07,3.53,1.76,5.04
	c2.17,4.73,6.82,8.82,12.77,11.23c8.89,3.6,21.67,5.73,28.42-0.14c0.86-0.75,1.72-1.68,2.62-2.67c1.71-1.87,3.66-4,6.32-5.56
	c9.08-5.34,19.11-0.88,27.17,2.71l1.66,0.74c10.32,4.53,24.34,6.67,32.73-0.95c1.08-0.98,2.11-2.16,3.2-3.41
	c1.9-2.18,4.06-4.65,6.95-6.52C468.28,764.49,472.14,763.53,475.98,763.53z M449.02,286.88L449.02,286.88L449.02,286.88z
	 M710.55,105.41L710.55,105.41L710.55,105.41z'
			/>

			<g className='label-south-sudan'>
				<g>
					<path
						className='svg-map-fill'
						d='M96.33,234.06c2.38,0,4.97,3.04,8.73,3.04c1.99,0,3.7-0.94,3.7-3.1c0-5.75-16.14-6.36-16.14-17.69
			c0-7.02,6.25-10.89,12.66-10.89c2.49,0,12.49,1.99,12.49,5.14c0,1.6-2.71,5.86-4.48,5.86c-2.21,0-5.03-3.26-8.4-3.26
			c-1.71,0-3.1,0.94-3.1,2.71c0,5.47,16.97,6.02,16.97,17.74c0,7.02-6.63,11.61-13.15,11.61c-2.82,0-12.82-1.6-12.82-5.36
			C92.8,238.92,95.39,234.06,96.33,234.06z M95.06,216.43l0.17,0.17l0.11-0.17l-0.11-0.22L95.06,216.43z M96.06,213.88
			c-0.5,0-0.72,0.22-0.72,0.72L96.06,213.88z M95.45,238.48l0.11,0.11l0.11-0.11l-0.11-0.11L95.45,238.48z M95.56,213h0.22v-0.17
			h-0.22V213z M95.95,239.7l0.22-0.39l-0.44-0.66L95.95,239.7z M95.78,216.76h0.17v-0.17L95.78,216.76z M95.95,219.47l-0.17,0.06
			h0.17V219.47z M95.84,217.53l0.11,0.17l0.11-0.17l-0.11-0.22L95.84,217.53z M96.28,237.98c-0.11,0-0.17,0.06-0.17,0.17l0.06,0.06
			c0.11,0,0.17-0.06,0.17-0.17L96.28,237.98z M97.22,240.25c0.44-0.28,0.77-1.05,0.94-1.49l-0.55,0.55l-0.55-0.39l0.17-0.33
			l-0.55-0.55l-0.33,0.33L97.22,240.25z M96.39,239.86l0.11,0.17l0.11-0.17l-0.11-0.17L96.39,239.86z M96.39,240.64l0.11,0.17
			l0.22-0.17l-0.22-0.22L96.39,240.64z M96.83,218.97l0.22-0.17l-0.17-0.22l-0.39,0.22L96.83,218.97z M96.56,215.32l0.11,0.17
			l0.17-0.17l-0.17-0.22L96.56,215.32z M97,212.61c-0.17,0.17-0.33,0.17-0.33,0.39l0.39-0.28L97,212.61z M96.67,220.24l0.22-0.17
			h-0.22V220.24z M96.72,211.17l0.17,0.17l0.11-0.17l-0.11-0.17L96.72,211.17z M96.72,214.88l0.17,0.22l0.11-0.22l-0.11-0.22
			L96.72,214.88z M96.89,214.1c-0.06,0-0.11,0.06-0.11,0.17l0.11,0.11V214.1z M97.22,211.29l-0.17,0.06l0.22,0.11l0.17-0.11
			L97.22,211.29z M97.11,216.98l0.11,0.17l0.17-0.17l-0.17-0.22L97.11,216.98z M97.22,214.66l0.22-0.17h-0.22V214.66z M97.44,210.46
			l0.17-0.22h-0.17V210.46z M97.61,238.59h0.22c0-0.28-0.06-0.55-0.22-0.94V238.59z M97.72,240.52l-0.11,0.05l0.11,0.11l0.06-0.11
			L97.72,240.52z M97.66,218.08l0.11,0.17l0.17-0.17l-0.17-0.22L97.66,218.08z M97.66,219.74l0.11,0.17l0.17-0.17l-0.17-0.22
			L97.66,219.74z M97.66,220.63l0.11,0.17l0.17-0.17l-0.17-0.17L97.66,220.63z M97.66,221.73l0.11,0.17l0.17-0.17l-0.17-0.17
			L97.66,221.73z M97.94,214.66l-0.17,0.28c0.17,0,0.22-0.11,0.22-0.28H97.94z M98.05,213.05l0.11,0.22l0.11-0.22l-0.11-0.22
			L98.05,213.05z M98.05,214.88l0.11,0.22l0.11-0.22l-0.11-0.22L98.05,214.88z M98.05,216.43l0.11,0.17l0.11-0.17l-0.11-0.22
			L98.05,216.43z M98.16,221.23v0.11l0.39-0.33l-0.22-0.22L98.16,221.23z M98.55,240.25l0.33-0.39l-0.55-0.22L98.55,240.25z
			 M98.38,211.78l0.17,0.11l0.11-0.11l-0.11-0.17L98.38,211.78z M98.88,215.43l-0.06-0.05l-0.11,0.11c0.06,0.05,0.06,0.05,0.11,0.05
			l0.06-0.05V215.43z M98.88,218.42c-0.11,0.05-0.17,0.22-0.17,0.39L98.88,218.42z M98.71,220.46h0.17v-0.39L98.71,220.46z
			 M98.77,211.56l0.11,0.17l0.17-0.17l-0.17-0.22L98.77,211.56z M98.82,241.08h0.17l-0.11-0.11L98.82,241.08z M98.99,238.7
			l-0.11,0.06c0.06,0.06,0.11,0.06,0.17,0.06l0.06-0.06L98.99,238.7z M99.26,222.67l0.55-0.22l-0.39-0.33l-0.33,0.33L99.26,222.67z
			 M99.15,208.96l0.11,0.17l0.11-0.17l-0.11-0.17L99.15,208.96z M99.15,211.95l0.11,0.22l0.11-0.22l-0.11-0.22L99.15,211.95z
			 M99.15,216.2l0.11,0.11l0.11-0.11l-0.11-0.17L99.15,216.2z M99.15,219.36l0.11,0.17l0.11-0.17l-0.11-0.22L99.15,219.36z
			 M99.15,239.14l0.11,0.17l-0.11,0.22l0.11,0.17l0.11-0.17l-0.11-0.22l0.11-0.17l-0.11-0.22L99.15,239.14z M99.32,217.53l0.11,0.17
			l0.17-0.17l-0.17-0.22L99.32,217.53z M99.6,213l-0.17,0.28c0.17,0,0.22-0.11,0.22-0.28H99.6z M99.43,223.78l0.39,0.33v-0.72
			L99.43,223.78z M99.76,240.41h0.06c-0.06-0.17-0.17-0.66-0.39-0.66C99.43,239.92,99.54,240.41,99.76,240.41z M99.71,217.31
			l0.11,0.11l0.11-0.11l-0.11-0.17L99.71,217.31z M99.98,208.96v-0.39h-0.17L99.98,208.96z M99.87,208.08l1.22,0.17
			c-0.17-0.05-0.17-0.33,0-0.39L99.87,208.08z M99.87,221.4l0.11,0.17l0.17-0.17l-0.17-0.22L99.87,221.4z M99.87,241.74l0.11,0.17
			l0.99-0.17l-0.99-0.22L99.87,241.74z M99.98,238.76c0,0.39,0.17,0.94,0.66,0.94l-0.44-1.11L99.98,238.76z M99.98,240.97h0.11
			l-0.11-0.17V240.97z M100.31,210.24l-0.17,0.17l0.06,0.06l0.22-0.11l-0.06-0.11H100.31z M100.37,219.08l-0.17,0.06l0.83,0.11
			l-0.66-0.11V219.08z M100.26,219.74l0.11,0.17l0.11-0.17l-0.11-0.22L100.26,219.74z M100.26,220.63l0.11,0.17l0.11-0.17
			l-0.11-0.17L100.26,220.63z M100.26,222.12l0.11,0.17l0.11-0.17l-0.11-0.22L100.26,222.12z M100.53,208.02l-0.06,0.11l-0.11-0.11
			l0.11-0.06L100.53,208.02z M100.81,221.01l0.28-0.39l-0.55,0.17L100.81,221.01z M100.65,240.25l0.11,0.17v-0.39L100.65,240.25z
			 M101.47,209.68c0,0.72-0.72,0.88-0.72,1.49v0.11c0.17-0.61,1.16-0.55,1.16-1.27L101.47,209.68z M100.76,223.56l0.17-0.17
			l-0.17-0.17V223.56z M100.87,240.69l0.06,0.11l0.11-0.11l-0.11-0.11L100.87,240.69z M100.98,208.96l0.11,0.17l0.17-0.17
			l-0.17-0.17L100.98,208.96z M100.98,211.56l0.11,0.17l0.17-0.17l-0.17-0.22L100.98,211.56z M100.98,212.67l0.11,0.17l0.17-0.17
			l-0.17-0.22L100.98,212.67z M101.03,241.08h0.17l-0.11-0.11L101.03,241.08z M101.14,224.16l0.17,0.17h0.11l-0.11-0.39
			L101.14,224.16z M101.64,224.27l-0.06-0.06l-0.11,0.11l0.11,0.11l0.06-0.11V224.27z M101.47,225.44h0.28
			c0-0.17-0.11-0.33-0.28-0.39V225.44z M101.92,239.7l-0.44,0.33c0.06,0,0.39,0,0.22-0.17L101.92,239.7z M101.7,240.8l0.22,0.17
			v-0.39L101.7,240.8z M101.92,208.91c0,0.39,0.66,0.44,0.94,0.44l-0.28-0.77L101.92,208.91z M101.92,220.29l0.11,0.17l0.11-0.17
			l-0.11-0.22L101.92,220.29z M101.92,223.39l0.28-0.17h-0.28V223.39z M102.19,224.66l0.39-0.33l-0.39-0.39l-0.17,0.39
			L102.19,224.66z M102.08,240.25l0.11,0.17l0.22-0.17l-0.22-0.22L102.08,240.25z M102.19,241.35h0.28l-0.28-0.22V241.35z
			 M102.3,242.02l-0.11,0.06l0.17,0.11l0.11-0.11L102.3,242.02z M102.91,222.67l0.11,0.17v-0.39L102.91,222.67z M103.13,208.25
			l-0.11-0.55v0.28L103.13,208.25z M103.02,221.57l0.11,0.17l0.22-0.17l-0.22-0.22L103.02,221.57z M103.13,223.78v-0.22h-0.11
			L103.13,223.78z M103.02,225.05l0.66-0.55l-0.28-0.17L103.02,225.05z M103.02,240.41h0.11v-0.39h-0.11V240.41z M103.13,240.91
			c-0.06,0-0.11,0-0.11,0.06c0,0.05,0,0.05,0.06,0.05s0.06,0,0.06-0.05V240.91z M103.41,210.79c0.22-0.44,0.28-0.88,0.72-1.27
			l-0.17-0.17c-0.39,0-0.83,0.5-0.83,0.88L103.41,210.79z M103.19,208.41l0.22,0.17l0.11-0.17l-0.11-0.17L103.19,208.41z
			 M103.3,241.69h0.28v-0.33L103.3,241.69z M103.52,240.97l-0.17,0.11l0.06,0.06l0.17-0.17H103.52z M103.96,222.28v-0.55h-0.55
			L103.96,222.28z M104.35,240.25c0-0.39-0.33-0.61-0.72-0.61C103.8,239.86,103.96,240.25,104.35,240.25z M103.8,207.75l-0.11,0.11
			l0.11,0.11l0.17-0.11L103.8,207.75z M103.8,242.35l-0.11,0.11h0.28L103.8,242.35z M103.74,226.76l0.22,0.17l0.11-0.17l-0.11-0.22
			L103.74,226.76z M104.02,222.51l0.11,0.17v-0.39L104.02,222.51z M104.02,240.8l0.11,0.33c0-0.17,0.06-0.33,0.22-0.33H104.02z
			 M104.24,208.85l-0.11,0.11l0.99,0.11l-0.88-0.11V208.85z M104.51,225.21l0.17-0.17h-0.44L104.51,225.21z M105.79,225.66
			c-0.61,0-1.38,0.44-1.38,1.11l1.38-0.77V225.66z M104.51,223.61v0.5l0.17-0.17L104.51,223.61z M105.23,208.74
			c-0.17,0-0.5,0-0.55,0.22h0.55V208.74z M104.9,225.32l-0.11,0.11l0.11,0.11l0.17-0.11L104.9,225.32z M105.01,242.07l-0.22,0.17
			c0.17-0.05,0.11,0.17,0.06,0.22l0.22,0.17c0.06-0.11,0.17-0.22,0.39-0.39l-0.39-0.17H105.01z M104.9,242.29
			c0.06-0.06,0.06-0.06,0.11-0.06C104.96,242.29,104.96,242.29,104.9,242.29z M105.12,239.81h-0.11l0.11,0.11l0.11-0.06
			L105.12,239.81z M105.12,240.25l0.11,0.17l0.11-0.17l-0.11-0.22L105.12,240.25z M105.12,241.74l0.11,0.17l0.11-0.17l-0.11-0.22
			L105.12,241.74z M105.4,224.11l0.22-0.55l-0.39,0.39L105.4,224.11z M105.29,243.01l0.11,0.17l0.17-0.17l-0.17-0.22L105.29,243.01z
			 M105.45,208.25l0.33,0.33l0.11-0.17l-0.11-0.17l-0.06,0.06l-0.11-0.28L105.45,208.25z M105.68,240.75l-0.11,0.11l0.06,0.11
			l0.17-0.17L105.68,240.75z M105.68,208.41v-0.06V208.41z M105.68,224.72l0.11,0.17l0.11-0.17l-0.11-0.22L105.68,224.72z
			 M105.68,227.37l0.11,0.11v-0.22L105.68,227.37z M105.79,209.85v0.06l0.39-0.39h-0.17L105.79,209.85z M105.95,242.46h0.22v-0.39
			h-0.22V242.46z M106.01,224.33l0.17,0.17l0.11-0.17l-0.11-0.22L106.01,224.33z M106.34,240.25h0.17v-0.22h-0.17V240.25z
			 M106.89,241.69l0.17-0.33l-0.44-0.39L106.89,241.69z M106.78,209.18l0.11,0.17l0.11-0.17l-0.11-0.22L106.78,209.18z
			 M106.95,241.91l0.11,0.17l0.17-0.17l-0.17-0.22L106.95,241.91z M107.06,209.52h0.44l-0.44-0.17V209.52z M107.06,225.99h0.22
			v-0.22h-0.22V225.99z M107.33,210.46l0.11,0.17l0.11-0.17l-0.11-0.22L107.33,210.46z M107.33,242.62l0.11,0.17
			c0.06-0.06,0.11,0,0.06,0.05l0.11,0.17l0.17-0.17c-0.06-0.11-0.17-0.22-0.33-0.39L107.33,242.62z M107.44,208.96
			c0.17,0,0.28-0.06,0.39-0.17h-0.39V208.96z M107.5,239.97h0.17l-0.06-0.11L107.5,239.97z M107.5,242.79c0-0.06,0-0.11,0.06-0.17
			L107.5,242.79z M107.83,241.13H108l0.39-0.33h-0.55V241.13z M107.89,228.03l0.11,0.17l0.11-0.17l-0.11-0.22L107.89,228.03z
			 M108.16,209.79c0.28,0,0.39-0.28,0.39-0.5C108.27,209.3,108.16,209.57,108.16,209.79z M108.38,228.59l0.33-0.22l-0.39-0.22
			l-0.17,0.06L108.38,228.59z M108.38,239.47v-0.17h-0.22L108.38,239.47z M108.16,242.46h0.22v-0.55L108.16,242.46z M108.38,225.6
			l0.39-0.22l-0.06-0.17L108.38,225.6z M108.66,239.86l-0.06,0.17l0.11,0.22l0.11-0.17l-0.11-0.22H108.66z M108.99,240.75
			l-0.11,0.11l0.06,0.11l0.17-0.17L108.99,240.75z M108.99,208.41l0.11,0.17l0.11-0.17l-0.11-0.17L108.99,208.41z M109.32,225.44
			l0.17,0.17l0.11-0.17l-0.11-0.22L109.32,225.44z M109.32,239.14l0.5,0.55l0.17-0.17l-0.5-0.61L109.32,239.14z M109.43,229.14
			l-0.06,0.05l0.11,0.11v-0.17H109.43z M109.49,228.59h0.33c0-0.17-0.17-0.22-0.33-0.22V228.59z M109.54,239.31v-0.06V239.31z
			 M109.71,211.95l0.11,0.22l0.17-0.22l-0.17-0.22L109.71,211.95z M109.71,239.47c0-0.06,0-0.11,0.06-0.17L109.71,239.47z
			 M109.71,240.25l0.11,0.17l0.11-0.22l-0.11-0.17L109.71,240.25z M110.21,211.45v-0.5l-0.39,0.22L110.21,211.45z M110.1,229.53
			l0.11,0.17l0.11-0.17l-0.11-0.22L110.1,229.53z M110.21,241.91h0.17v-0.22h-0.17V241.91z M110.37,227.81h0.39v-0.17h-0.39V227.81z
			 M110.37,230.58h0.39v-0.17h-0.39V230.58z M110.48,212.11l0.06,0.17l0.11-0.17l-0.06-0.22L110.48,212.11z M110.76,210.46h0.17
			v-0.39L110.76,210.46z M110.76,226.71c0.06,0.17,0.22,0.22,0.39,0.22c-0.06-0.17-0.17-0.28-0.22-0.39L110.76,226.71z
			 M110.82,225.82l0.11,0.17l0.17-0.17l-0.17-0.22L110.82,225.82z M110.82,237.65l0.11,0.17l0.17-0.17l-0.17-0.17L110.82,237.65z
			 M110.82,239.86l0.11,0.17l0.17-0.17l-0.17-0.17L110.82,239.86z M111.04,231.07l0.66-0.22v-0.28l-0.77,0.39L111.04,231.07z
			 M110.93,232.23h0.22l-0.22-0.17V232.23z M110.93,236.93l0.22-0.22h-0.22V236.93z M110.93,240.97c0.17,0,0.22-0.22,0.22-0.39
			C110.98,240.75,110.93,240.86,110.93,240.97z M110.98,234.89l0.17,0.11l0.11-0.11l-0.11-0.17L110.98,234.89z M111.09,228.14
			l0.06,0.06c0.11-0.06,0.17-0.22,0.17-0.39L111.09,228.14z M111.2,241.74l0.11,0.17l0.11-0.17l-0.11-0.22L111.2,241.74z
			 M111.31,211.34l0.17-0.17l-0.17-0.17V211.34z M111.7,229.3c-0.17,0.05-0.33,0.22-0.39,0.39c0.17-0.17,0.39-0.22,0.55-0.39H111.7z
			 M112.14,234.06l-0.83,0.11h0.94L112.14,234.06z M111.37,239.31l0.11,0.17l0.17-0.17l-0.17-0.17L111.37,239.31z M111.53,238.98
			l0.17,0.17l0.11-0.17l-0.11-0.22L111.53,238.98z M111.87,231.85l-0.17,0.11h0.33L111.87,231.85z M111.76,233.84V234l0.11-0.11
			L111.76,233.84z M111.87,232.79l0.06,0.06l0.11-0.22L111.87,232.79z M112.03,233.06h0.39v-0.28L112.03,233.06z M112.25,235.83
			l0.17-0.28l-0.39-0.28v0.17L112.25,235.83z M112.03,238.37h0.77l-0.44-0.44L112.03,238.37z M112.09,228.42l0.17,0.17l0.11-0.17
			l-0.11-0.22L112.09,228.42z M112.31,229.69l0.11,0.17l0.11-0.17l-0.11-0.22L112.31,229.69z M112.31,239.7l0.11,0.17l0.11-0.17
			l-0.11-0.22L112.31,239.7z M112.42,230.41h0.39c-0.17-0.17-0.28-0.05-0.33-0.22l-0.06,0.11V230.41z M112.47,236.32l0.11,0.22
			l0.17-0.22l-0.17-0.22L112.47,236.32z M112.75,239.64l-0.22,0.17l0.11,0.06l0.17-0.17L112.75,239.64z M112.97,236.93v-0.39
			c-0.17,0-0.33,0-0.39,0.17L112.97,236.93z M112.64,212.11l0.33,0.33l0.11-0.11l-0.11-0.17c0,0.06-0.11,0.06-0.11,0l0.06-0.06
			l-0.11-0.22L112.64,212.11z M112.97,228.92l-0.17-0.33l-0.06,0.11L112.97,228.92z M113.36,232.34l0.17-0.11l-0.77-0.55
			L113.36,232.34z M113.14,234.44l0.22-0.11c-0.17-0.22-0.39-0.28-0.61-0.44L113.14,234.44z M112.86,211.51v0.17l0.11-0.05
			L112.86,211.51z M112.86,212.28c0-0.05,0.06-0.05,0.06-0.11C112.86,212.23,112.86,212.23,112.86,212.28z M113.19,228.03l0.17,0.17
			l0.11-0.17l-0.11-0.22L113.19,228.03z M113.19,237.87l0.17,0.17l0.11-0.17l-0.11-0.22L113.19,237.87z M113.41,238.2l0.11,0.17
			c0.06-0.05,0.11,0,0.06,0.06l0.11,0.17l0.17-0.17c-0.06-0.11-0.17-0.22-0.33-0.39L113.41,238.2z M114.41,235.72
			c-0.61,0-0.88,0.72-0.88,1.22l0.94-0.66l0.72,0.66l0.22-0.33L114.41,235.72z M113.58,238.37c0-0.05,0-0.11,0.06-0.17
			L113.58,238.37z M113.69,235h0.39l-0.17-0.28L113.69,235z M113.91,210.24l-0.17,0.22l0.17,0.17l-0.17-0.17L113.91,210.24z
			 M113.75,230.8l0.17,0.17l0.11-0.17l-0.11-0.22L113.75,230.8z M114.24,232.07l0.5-0.61c-0.39,0-0.66,0.17-0.83,0.5L114.24,232.07z
			 M113.91,239.47h0.17l-0.17-0.17V239.47z M113.97,229.86l0.11,0.17l0.11-0.17l-0.11-0.17L113.97,229.86z M114.35,211.23l0.06,0.11
			l0.11-0.17l-0.06-0.17L114.35,211.23z M114.41,237.48l-0.06,0.06l0.11,0.11v-0.17H114.41z M114.52,238.59l0.11,0.17l0.11-0.17
			l-0.11-0.22L114.52,238.59z M114.8,230.24l-0.06-0.06l-0.11,0.11c0.06,0.06,0.06,0.06,0.11,0.06l0.06-0.06V230.24z M114.85,233.78
			l0.17,0.11l0.11-0.11l-0.11-0.17L114.85,233.78z M115.02,231.13h0.17v-0.17L115.02,231.13z M115.07,234.55l0.11,0.17l0.11-0.17
			l-0.11-0.22L115.07,234.55z M115.24,237.87l0.17,0.17c-0.06,0-0.06,0.17,0,0.17l0.17-0.17c-0.17,0.05-0.22-0.11-0.06-0.17
			l-0.17-0.22L115.24,237.87z M115.35,232.62h0.22v-0.39h-0.22V232.62z M115.57,235c-0.22,0.17-0.22,0.44-0.22,0.66l0.39-0.33
			L115.57,235z M115.62,230.8l0.11,0.17l0.11-0.17l-0.11-0.22L115.62,230.8z M115.79,234.44h0.61l-0.33-0.94l-0.33,0.28l0.17,0.39
			L115.79,234.44z M115.79,233.01l0.11,0.17l0.17-0.17l-0.17-0.22L115.79,233.01z'
					/>
					<path
						className='svg-map-fill'
						d='M151.5,205.43c10.94,0,19.95,9.01,19.95,19.95c0,10.94-9.18,19.84-20.01,19.84
			c-10.94,0-19.79-9.18-19.79-20.01C131.65,214.21,140.5,205.43,151.5,205.43z M134.31,221.73l0.11,0.17l0.11-0.17l-0.11-0.17
			L134.31,221.73z M134.58,228.59v-0.22h-0.17L134.58,228.59z M134.64,226.54l0.17,0.17l0.11-0.17l-0.11-0.17L134.64,226.54z
			 M134.8,224.11h0.17v-0.33h-0.17V224.11z M135.13,227.2l-0.17,0.05l0.22,0.11l0.17-0.11L135.13,227.2z M135.13,230.3h0.22
			c0-0.17-0.06-0.33-0.22-0.44V230.3z M135.69,225.66v-0.44l-0.33,0.22L135.69,225.66z M135.41,227.48l0.11,0.17l0.11-0.17
			l-0.11-0.22L135.41,227.48z M135.58,231.18l0.11,0.22l0.99-0.22l-0.99-0.22L135.58,231.18z M135.74,219.19l0.17,0.17l0.11-0.17
			l-0.11-0.22L135.74,219.19z M135.91,223.78h0.17v-0.22h-0.17V223.78z M135.91,228.75h0.17l-0.17-0.17V228.75z M136.07,232.51
			l0.17-0.28l-0.17-0.17l-0.17,0.17L136.07,232.51z M136.46,222.28l0.33-0.17l-0.55-0.39v0.05L136.46,222.28z M136.79,219.96v-0.44
			h-0.33L136.79,219.96z M136.63,224.66v-0.33l-0.17,0.17L136.63,224.66z M136.52,230.47l0.11,0.11l0.11-0.11l-0.11-0.17
			L136.52,230.47z M136.68,225.44l0.11,0.17l0.17-0.17l-0.17-0.22L136.68,225.44z M136.79,216.32c-0.17,0.17,0.17,1.49,0.17,1.71
			L136.79,216.32z M136.9,223.22l0.06,0.17l0.11-0.22l-0.06-0.17L136.9,223.22z M136.96,226.38h0.44l-0.22-0.39L136.96,226.38z
			 M137.57,224.16c-0.06-0.17-0.17-0.39-0.39-0.61v0.22L137.57,224.16z M137.23,216.76l0.11,0.11l0.17-0.11l-0.17-0.17
			L137.23,216.76z M137.35,229.86c0.06,0,0.11-0.06,0.11-0.17c0-0.17-0.17-0.17-0.22-0.22h0.11V229.86z M137.35,216.2h0.22
			l-0.22-0.17V216.2z M137.57,218.8v-0.22l-0.22-0.55v0.28L137.57,218.8z M137.4,221.73l0.17,0.17l0.11-0.17l-0.11-0.17
			L137.4,221.73z M137.73,222.45h0.17v-0.06l-0.17-0.11V222.45z M137.9,234.33v-0.17h-0.17L137.9,234.33z M137.79,228.97l0.11,0.17
			h0.17l-0.17-0.39L137.79,228.97z M138.12,215.1l0.17-0.17l-0.17-0.28V215.1z M138.17,229.53l0.11,0.17l0.11-0.17l-0.11-0.22
			L138.17,229.53z M138.23,226.26l0.06,0.11l0.11-0.11l-0.11-0.11L138.23,226.26z M138.29,215.76l0.33-0.11l-0.22-0.28l-0.11,0.11
			V215.76z M138.78,220.85l0.61-1.05l-0.39-0.55l-0.17,0.11l0.22,0.5c-0.28,0.28-0.44,0.5-0.61,0.77L138.78,220.85z M138.51,213.05
			l0.17,0.22l0.11-0.22l-0.11-0.22L138.51,213.05z M138.51,216.43l0.17,0.17l0.11-0.17l-0.11-0.22L138.51,216.43z M138.84,217.86
			c0,0.28,0.11,0.39,0.39,0.39v-0.39H138.84z M139,222.12c0-0.05,0-0.11-0.06-0.11l-0.11,0.11c0.06,0.06,0.39,0.06,0.55,0.06
			L139,222.12z M139,215.21l0.39-0.28l0.39,0.28c0-0.5-0.28-0.94-0.77-1.27V215.21z M139.89,216.2c-0.17-0.28-0.5-0.5-0.77-0.66
			C139.11,215.98,139.39,216.2,139.89,216.2z M139.28,235.22l0.11,0.22l0.11-0.22l-0.11-0.22L139.28,235.22z M139.39,213.39h0.39
			l-0.22-0.11L139.39,213.39z M139.72,233.56l-0.17,0.17l0.06,0.06l0.22-0.11L139.72,233.56z M139.67,230.3l0.11,0.11v-0.11H139.67z
			 M139.67,236.1l0.06,0.05l0.11-0.11l-0.06-0.05L139.67,236.1z M139.83,212.45c0.39,0.17,0.72,0.39,1.16,0.39l-0.5-0.61l0.33-0.5
			l0.77,0.44c-0.06-0.28-0.17-0.83-0.55-0.83C140.44,211.34,140.11,212.06,139.83,212.45z M140,239.14l0.11,0.17l0.17-0.17
			l-0.17-0.22L140,239.14z M140.11,217.42h0.22l-0.22-0.11V217.42z M140.5,235c-0.17,0.11-0.22,0.28-0.22,0.44h0.22V235z
			 M140.66,214.77v-0.28h-0.33L140.66,214.77z M140.33,234.33h0.17v-0.17h-0.17V234.33z M140.99,215.93c-0.28,0-0.5,0.44-0.5,0.72
			v0.11c0.22-0.17,0.39-0.39,0.55-0.72L140.99,215.93z M151.44,235.99c5.91,0,10.78-4.86,10.78-10.78c0-5.86-4.86-10.72-10.78-10.72
			s-10.78,5.03-10.78,10.89C140.66,231.29,145.64,235.99,151.44,235.99z M141.05,215.27v0.22l0.28-0.39l-0.11-0.17L141.05,215.27z
			 M141.21,236.1l0.28-0.11h-0.28V236.1z M141.49,216.76l0.11,0.11l0.22-0.11l-0.22-0.17L141.49,216.76z M141.6,215.65
			c0.11,0.17,0.28,0.22,0.55,0.22v-0.11L141.6,215.65z M141.88,235.83h0.28v-0.28h-0.28V235.83z M141.93,211.01l0.11,0.17v-0.39
			L141.93,211.01z M142.04,212.72c-0.06,0-0.11,0.06-0.11,0.17l0.11,0.11l0.06-0.11L142.04,212.72z M141.93,213.88
			c0.28,0,0.44-0.39,0.5-0.61C142.1,213.44,141.93,213.61,141.93,213.88z M141.93,214.88l0.11,0.22v-0.44L141.93,214.88z
			 M142.15,238.76c-0.17,0.17-0.22,0.28-0.22,0.39h0.22V238.76z M142.38,214.38l-0.06,0.17l0.06,0.11l0.11-0.11l-0.06-0.17H142.38z
			 M143.15,210.07l-0.44-0.72v0.28L143.15,210.07z M143.09,211.34c0.44,0,1.38-0.83,1.38-1.33l-0.06-0.33
			c-0.39,0.28-1.71,0.94-1.71,1.44L143.09,211.34z M142.71,212.83l0.28-0.28l-0.28-0.28V212.83z M142.98,214.1
			c0.06,0.11,0.17,0.22,0.28,0.28v-0.28H142.98z M143.15,213.28c-0.06,0-0.11,0.05-0.11,0.17l0.11,0.11l0.06-0.11L143.15,213.28z
			 M143.09,238.04l-0.06,0.05l0.11,0.11v-0.17H143.09z M143.31,209.52l0.22,0.17l0.11-0.17l-0.11-0.17L143.31,209.52z
			 M143.54,212.28h0.28l0.61-0.28l-0.5-0.28L143.54,212.28z M143.76,240.52h0.17l-0.11-0.11L143.76,240.52z M143.92,237.76
			l-0.11,0.06l0.11,0.11l0.17-0.11L143.92,237.76z M144.14,238.37h0.5v-0.44L144.14,238.37z M144.14,239.14l0.11,0.17l0.11-0.17
			l-0.11-0.22L144.14,239.14z M144.25,240.97h0.17l-0.17-0.17V240.97z M144.42,209.35l0.55-0.39h-0.17L144.42,209.35z
			 M144.42,211.17h0.39v-0.17h-0.39V211.17z M144.97,240.8c0,0.06,0.06,0.17,0.22,0.33v-0.33H144.97z M145.19,210.79h0.11
			l-0.11-0.33V210.79z M145.91,211.73l0.77-1.16c-0.5,0-0.77,0.61-1.05,0.88L145.91,211.73z M145.75,239.14h0.17v-0.22h-0.17V239.14
			z M146.08,209.68h0.22c0-0.28-0.06-0.44-0.22-0.55V209.68z M146.3,212.83v-0.39l-0.22,0.28L146.3,212.83z M146.41,240.91
			l-0.11,0.06l0.17,0.05l0.17-0.05L146.41,240.91z M146.36,238.2l0.11,0.17l0.11-0.17l-0.11-0.17L146.36,238.2z M146.47,241.69h0.39
			v-0.17h-0.39V241.69z M147.02,209.57v0.11c0.17-0.17,0.22-0.39,0.39-0.55C147.13,209.13,147.02,209.3,147.02,209.57z
			 M147.18,238.04h0.22v-0.22L147.18,238.04z M147.57,241.35c0-0.17-0.11-0.22-0.39-0.22C147.18,241.3,147.41,241.35,147.57,241.35z
			 M147.24,212.34l0.17,0.11l0.11-0.11l-0.11-0.17L147.24,212.34z M147.46,210.35c0.22-0.06,0.39-0.17,0.5-0.28l-0.22-0.17
			L147.46,210.35z M147.57,208.41h0.17v-0.17h-0.17V208.41z M147.74,242.24h0.22l-0.22-0.33V242.24z M147.9,210.62
			c0,0.28,0.11,0.39,0.39,0.39v-0.39H147.9z M148.18,211.95l0.11,0.22l0.17-0.22l-0.17-0.22L148.18,211.95z M148.84,242.24
			l0.22-0.33c-0.22-0.39-0.5-0.61-0.88-0.61C148.18,241.69,148.46,242.07,148.84,242.24z M148.29,208.96c0.44,0,0.99,0,0.99-0.61
			L148.29,208.96z M148.68,210.79h0.22c0-0.05-0.06-0.17-0.22-0.33V210.79z M148.79,238.59l-0.06,0.17l0.11,0.17l0.11-0.17
			l-0.11-0.17H148.79z M148.84,209.9c0.17-0.06,0.22-0.17,0.22-0.39h-0.22V209.9z M148.9,239.14l0.17,0.17l0.11-0.17l-0.11-0.22
			L148.9,239.14z M149.23,211.34l0.55-0.33h-0.55V211.34z M149.45,211.95l0.17,0.22l0.11-0.22l-0.11-0.22L149.45,211.95z
			 M149.45,241.35l0.17,0.17l0.11-0.17l-0.11-0.22L149.45,241.35z M149.95,239.14h0.22l-0.22-0.22V239.14z M150.22,209.18l0.28,0.33
			l0.17-0.17c-0.06-0.11-0.17-0.22-0.33-0.39L150.22,209.18z M151.61,240.25l-1.38-0.22l1.93,0.55l-0.11,0.06L151.61,240.25z
			 M150.39,209.35c0-0.11,0-0.17,0.06-0.22L150.39,209.35z M150.56,240.25l0.17,0.17l0.11-0.17l-0.11-0.22L150.56,240.25z
			 M150.67,209.52l-0.06,0.05l0.11,0.11v-0.17H150.67z M151.44,210.07c-0.22,0.11-0.39,0.22-0.55,0.39h0.55V210.07z M150.94,208.25
			l0.11,0.17l0.17-0.17l-0.17-0.22L150.94,208.25z M150.94,239.31l0.11,0.17l0.17-0.17l-0.17-0.17L150.94,239.31z M150.94,241.74
			l0.11,0.17l0.17-0.17l-0.17-0.22L150.94,241.74z M151.77,211.17h-0.72l0.39,0.17L151.77,211.17z M151.38,208.91
			c0,0.39,0.17,0.61,0.44,0.77l0.17-0.39L151.38,208.91z M152.05,207.53l0.11,0.17l0.17-0.17l-0.17-0.22L152.05,207.53z
			 M152.16,210.46h0.22c0.22-0.44,0.55-0.88,0.55-1.49C152.71,209.46,152.16,209.85,152.16,210.46z M152.16,239.31l0.22-0.17
			l-0.22-0.22V239.31z M152.16,241.69c0.06,0.28,0.22,0.44,0.39,0.55v-0.11L152.16,241.69z M152.21,208.41l0.17,0.17
			c0.06-0.05,0.11,0,0.06,0.06l0.11,0.17l0.11-0.17c-0.06-0.11-0.11-0.22-0.28-0.39L152.21,208.41z M152.44,208.58
			c0-0.05,0-0.11,0.06-0.17L152.44,208.58z M152.55,210.79c0.22-0.17,0.61-0.28,0.61-0.55C152.88,210.24,152.55,210.4,152.55,210.79
			z M152.82,241.08h0.17l-0.06-0.11L152.82,241.08z M153.15,209.74l0.11,0.17l0.17-0.17l-0.17-0.22L153.15,209.74z M153.15,240.64
			l0.11,0.17l0.17-0.17l-0.17-0.22L153.15,240.64z M153.26,208.41c0.28,0,0.39-0.11,0.39-0.39L153.26,208.41z M153.37,211.29
			l-0.11,0.06l0.11,0.11l0.11-0.11L153.37,211.29z M153.76,209.07l-0.11,0.06l0.11,0.11l0.06-0.11L153.76,209.07z M153.93,240.14
			l-0.11,0.11h0.22L153.93,240.14z M153.87,211.17l0.17,0.17l0.11-0.17l-0.11-0.17L153.87,211.17z M154.2,210.07
			c0,0.28,0.17,0.39,0.55,0.39c0-0.28-0.11-0.55-0.39-0.77L154.2,210.07z M154.31,241.41l0.06,0.11l0.11-0.05l-0.11-0.11
			L154.31,241.41z M154.98,242.29c0-0.28-0.17-0.44-0.44-0.44C154.54,242.13,154.7,242.29,154.98,242.29z M154.98,211.56l-0.22,0.44
			v0.17l0.39-0.55L154.98,211.56z M155.25,207.97l-0.11,0.06l0.06,0.11l0.11-0.11L155.25,207.97z M155.86,209.35
			c-0.28,0.06-0.72,0.17-0.72,0.55h0.72V209.35z M155.2,238.42l0.11,0.17l0.11-0.17l-0.11-0.22L155.2,238.42z M155.2,240.64
			l0.11,0.17l0.11-0.17l-0.11-0.22L155.2,240.64z M155.7,211.17h0.33c-0.06-0.11-0.11-0.17-0.22-0.22l-0.11,0.06V211.17z
			 M156.03,212.45l0.22-0.17h-0.22V212.45z M156.41,240.58c0.17,0.17,0.17,0.44,0.39,0.44c0-0.17-0.11-0.44-0.28-0.44H156.41z
			 M157.08,209.79c-0.39,0-0.5,0.83-0.5,1.11c0.44,0,0.5-0.5,0.5-0.88V209.79z M156.64,239.7l0.17,0.17l0.11-0.17l-0.11-0.22
			L156.64,239.7z M157.13,208.91c-0.17,0.17-0.39,0.22-0.39,0.44h0.39V208.91z M157.02,240.08l0.11,0.17l0.17-0.17l-0.17-0.22
			L157.02,240.08z M157.13,211.73h0.22l-0.22-0.11V211.73z M157.35,209.68h0.17v-0.17h-0.17V209.68z M157.46,210.73h1.16
			c-0.06,0-0.06-0.11,0-0.11L157.46,210.73z M157.46,238.59l0.06,0.17l0.11-0.06l-0.11-0.11H157.46z M157.69,210.79h0.22v-0.17
			h-0.22V210.79z M158.13,208.41l0.11,0.17l0.17-0.17l-0.17-0.17L158.13,208.41z M158.51,208.96l0.11,0.17l0.11-0.17l-0.11-0.17
			L158.51,208.96z M158.79,210.07h0.22l0.33-0.17l-0.17-0.06L158.79,210.07z M159.18,211.01h0.17v-0.22h-0.17V211.01z
			 M159.23,213.44l0.11,0.11l0.22-0.11l-0.22-0.17L159.23,213.44z M159.23,239.86l0.11,0.17l0.22-0.17l-0.22-0.17L159.23,239.86z
			 M159.79,215.32l0.11,0.17l0.99-0.17l-0.99-0.22L159.79,215.32z M160.17,213.28l-0.22,0.17l0.22,0.11V213.28z M160.34,237.1
			l0.11,0.17l0.22-0.17l-0.22-0.17L160.34,237.1z M160.39,238.31h0.17l-0.11-0.11L160.39,238.31z M160.39,239.75l0.06,0.11
			l0.11-0.06l-0.11-0.11L160.39,239.75z M160.45,213.55v0.28V213.55z M160.45,235.83h0.28l-0.28-0.28V235.83z M160.95,214.44
			l-0.11,0.06l0.11,0.11l0.06-0.11L160.95,214.44z M160.89,239.14l0.11,0.17l0.22-0.17l-0.22-0.22L160.89,239.14z M161.44,212.67
			l0.17-0.22l-0.22-0.17l-0.06,0.06l-0.06-0.17l-0.22,0.17l0.22,0.11l0.11,0.28l0.28,0.11l0.11-0.17l-0.11-0.22h-0.06h-0.06
			l0.06,0.06l-0.17,0.22V212.67z M161.28,212.45c0-0.06,0-0.06,0.06-0.11C161.33,212.39,161.33,212.45,161.28,212.45z
			 M161.83,216.87v-0.11h-0.44L161.83,216.87z M161.44,215.1l0.22,0.11l0.11-0.11l-0.11-0.17L161.44,215.1z M161.83,211.73h0.11
			v-0.11L161.83,211.73z M161.83,233.39l0.11,0.22l0.22-0.22l-0.22-0.22L161.83,233.39z M162.05,215.6l-0.11,0.05h0.28L162.05,215.6
			z M162.38,238.2l0.11,0.17c0.06-0.05,0.11,0,0.06,0.06l0.22,0.17l0.11-0.17l-0.11-0.22l-0.28-0.17L162.38,238.2z M162.49,213.94
			h0.28c-0.06-0.11-0.11-0.22-0.28-0.39V213.94z M162.72,238.2c0,0.11-0.06,0.17-0.17,0.17L162.72,238.2z M162.77,216.76
			c-0.06,0.17,0.11,0.5,0.17,0.66L162.77,216.76z M162.99,233.01l0.11,0.17l0.17-0.17l-0.17-0.22L162.99,233.01z M163.49,213h0.17
			v-0.17h-0.17V213z M163.49,216.59l0.17-0.28h-0.17V216.59z M163.49,218.03h0.39c-0.06-0.17-0.17-0.33-0.33-0.39l-0.06,0.06V218.03
			z M164.04,214.49v-0.39c-0.17,0.06-0.33,0.11-0.39,0.28L164.04,214.49z M163.93,233.73h-0.11l0.11,0.11l0.11-0.06L163.93,233.73z
			 M164.1,214.88l0.11,0.22l0.17-0.22l-0.17-0.22L164.1,214.88z M164.98,218.03l0.17-0.17c-0.22-0.39-0.61-0.83-1.05-0.83
			C164.1,217.42,164.71,217.86,164.98,218.03z M164.1,218.42l0.11,0.17l0.17-0.17l-0.17-0.17L164.1,218.42z M164.21,235.83
			l0.39-0.28h-0.11L164.21,235.83z M164.48,231.9l0.11,0.17l0.11-0.17l-0.11-0.22L164.48,231.9z M164.65,220.63l0.11,0.17l0.17-0.17
			l-0.17-0.17L164.65,220.63z M164.65,221.95l0.11,0.17l0.17-0.17l-0.17-0.22L164.65,221.95z M164.65,229.14l0.11,0.17l0.17-0.17
			l-0.17-0.22L164.65,229.14z M165.2,216.32c-0.17,0.06-0.28,0.28-0.44,0.55l0.55-0.11L165.2,216.32z M164.87,222.78l-0.11,0.06
			l0.06,0.11l0.17-0.11L164.87,222.78z M164.82,213.77l0.17,0.17l0.11-0.17l-0.11-0.22L164.82,213.77z M165.04,235.22l0.11,0.22
			l0.11-0.22l-0.11-0.22L165.04,235.22z M165.31,217.53c0.22-0.06,0.61-0.22,0.61-0.5C165.53,217.03,165.31,217.2,165.31,217.53z
			 M165.31,219.19v0.17l0.22-0.33v-0.22L165.31,219.19z M165.31,222.45h1.16l-0.22-0.55L165.31,222.45z M165.37,234.11l0.17,0.22
			l0.11-0.22l-0.11-0.22L165.37,234.11z M165.53,231.52c0.22,0,0.33-0.11,0.33-0.28v-0.11L165.53,231.52z M165.87,215.49v-0.28
			h-0.17L165.87,215.49z M165.87,224.88l0.39-0.22h-0.55L165.87,224.88z M165.87,220.63c0.17-0.06,0.55-0.17,0.55-0.39h-0.11
			L165.87,220.63z M165.87,232.62h0.22v-0.11L165.87,232.62z M165.87,233.89h0.39l-0.17-0.11L165.87,233.89z M166.14,216.04
			c0,0.11,0.11,0.22,0.28,0.28v-0.28H166.14z M166.31,217.53l0.11,0.17l0.17-0.17l-0.17-0.22L166.31,217.53z M166.42,218.8h0.22
			v-0.22L166.42,218.8z M166.42,223.56l0.06,0.06c0.17,0,0.28-0.11,0.33-0.39L166.42,223.56z M166.7,232.68
			c0.17,0.17,0.22,0.28,0.28,0.39l0.17-0.22l-0.17-0.22c-0.06,0.06-0.17-0.05-0.17-0.11L166.7,232.68z M166.97,220.96l-0.06-0.06
			l-0.11,0.11h0.17V220.96z M166.92,232.62c-0.06,0.06-0.06,0.11-0.06,0.17L166.92,232.62z M167.41,224.55
			c0-0.28-0.06-0.61-0.44-0.61v0.17L167.41,224.55z M167.14,225.27c0.28-0.17,0.61-0.22,0.61-0.61
			C167.52,224.83,167.3,225.05,167.14,225.27z M167.19,219.36h0.17v-0.39L167.19,219.36z M167.25,221.4l0.11,0.17l0.11-0.17
			l-0.11-0.22L167.25,221.4z M168.24,224c-0.06-0.39-0.22-0.99-0.72-0.99C167.75,223.39,167.64,224,168.24,224z M168.19,222.4
			l-0.11,0.06l0.11,0.11l0.11-0.11L168.19,222.4z M168.35,219.36l0.11,0.17l0.11-0.17l-0.11-0.22L168.35,219.36z'
					/>
					<path
						className='svg-map-fill'
						d='M208.65,230.96v-24.21l1.16-0.94h6.91c0.28,0,1.11,0.55,1.11,0.94v24.93c0,7.96-8.51,13.54-15.81,13.54
			c-7.52,0-15.86-5.58-15.86-13.71v-24.6c0-0.61,0.66-1.11,1.27-1.11h6.63l1.27,0.77v24.26c0,3.32,3.59,5.14,6.52,5.14
			C204.72,235.99,208.65,234.33,208.65,230.96z M188.25,208.41l0.11,0.17l0.11-0.17l-0.11-0.17L188.25,208.41z M188.36,227.48
			l1.27,0.33l-1.11-0.55L188.36,227.48z M188.53,231.9l-0.06-0.06l-0.11,0.11c0.06,0.05,0.06,0.05,0.11,0.05l0.06-0.05V231.9z
			 M189.02,212.11l-0.11,0.17l0.06,0.06l0.17-0.11L189.02,212.11z M188.91,220.46h0.44c-0.17-0.17-0.28-0.22-0.44-0.22V220.46z
			 M189.47,230.02l-0.22-0.55l-0.33,0.33L189.47,230.02z M188.97,228.03l0.11,0.17l0.44-0.17l-0.44-0.22L188.97,228.03z
			 M189.19,210.18l-0.11,0.06l0.11,0.11l0.17-0.11L189.19,210.18z M190.13,211.34l-0.66-0.39l-0.39,0.22L190.13,211.34z
			 M189.14,213.05l0.33,0.33l0.11-0.17l-0.11-0.22l-0.06,0.06l-0.06-0.22L189.14,213.05z M189.36,208.25h0.11v-0.22h-0.11V208.25z
			 M189.41,213.05c0,0.11,0,0.17-0.06,0.22C189.36,213.16,189.36,213.11,189.41,213.05z M189.52,209.18l0.11,0.17l0.22-0.17
			l-0.22-0.22L189.52,209.18z M189.63,208.58h0.28v-0.17h-0.28V208.58z M189.74,212.78l-0.11,0.06l0.11,0.11l0.17-0.11
			L189.74,212.78z M189.91,211.62l-0.22,0.17l0.22,0.11V211.62z M189.91,216.87h0.11v-0.11L189.91,216.87z M190.02,209.52l0.22-0.72
			h-0.22V209.52z M190.08,217.09l0.11,0.22l0.22-0.22l-0.22-0.22L190.08,217.09z M190.24,220.85l0.22,0.17v-0.39L190.24,220.85z
			 M190.41,225.44l-0.06,0.06l0.11,0.11v-0.17H190.41z M190.57,215.49v-0.28h-0.11L190.57,215.49z M190.46,229.3l0.11,0.17
			l0.11-0.17l-0.11-0.17L190.46,229.3z M190.57,208.25c0.17,0,0.33-0.05,0.44-0.22h-0.44V208.25z M190.63,210.29l0.11,0.17
			l0.22-0.17l-0.22-0.22L190.63,210.29z M190.63,215.65l0.11,0.11l0.22-0.11l-0.22-0.17L190.63,215.65z M190.63,233.78l0.11,0.11
			l0.22-0.11l-0.22-0.17L190.63,233.78z M190.68,217.7l0.06,0.17l0.11-0.05l-0.11-0.11H190.68z M190.9,209.79
			c0.39,0,0.66-0.11,0.66-0.5C191.18,209.3,190.9,209.41,190.9,209.79z M191.57,217.14v-0.28h-0.44L191.57,217.14z M191.29,236.38
			l0.28-0.39h-0.44L191.29,236.38z M191.18,214.71l0.11,0.22l0.22-0.22l-0.22-0.22L191.18,214.71z M191.4,212.23l-0.11,0.05
			l0.17,0.11l0.11-0.11L191.4,212.23z M191.51,217.42l-0.22,0.28c0.17,0,0.28-0.11,0.28-0.28H191.51z M191.29,222.84h0.28v-0.17
			h-0.28V222.84z M191.57,229.3v-0.39l-0.28,0.22L191.57,229.3z M191.57,207.69l0.11,0.17l0.17-0.17l-0.17-0.22L191.57,207.69z
			 M191.79,208.8l-0.11,0.17l0.06,0.06l0.17-0.11l-0.06-0.11H191.79z M191.68,212.83l0.17,0.61v-0.17L191.68,212.83z M191.84,237.82
			v-0.72l-0.17,0.17L191.84,237.82z M191.73,213.77l0.33,0.55l-0.22-0.77L191.73,213.77z M191.73,214.88l0.11,0.22l0.22-0.22
			l-0.22-0.22L191.73,214.88z M191.73,228.2l0.11,0.17l0.22-0.17l-0.22-0.17L191.73,228.2z M191.79,216.04l0.06,0.17l0.11-0.06
			l-0.11-0.11H191.79z M192.51,236.38h0.17c-0.17-0.39-0.39-0.83-0.83-0.83C192.01,235.94,192.07,236.38,192.51,236.38z
			 M192.67,208.58c-0.22,0.22-0.33,0.39-0.39,0.55h0.44l-0.11-0.28L192.67,208.58z M192.29,227.48l0.11,0.17l0.22-0.17l-0.22-0.22
			L192.29,227.48z M192.67,230.96v0.17l0.55-0.28l-0.33-0.28L192.67,230.96z M192.73,208.8l0.55,0.17l-0.44-0.39L192.73,208.8z
			 M192.89,235.33l0.06,0.22l0.11-0.22l-0.11-0.33L192.89,235.33z M193.28,240.64l0.11,0.17v-0.39L193.28,240.64z M193.56,236.77
			l0.22,0.17l0.11-0.17l-0.11-0.22L193.56,236.77z M194.05,235.55c0,0.28,0.17,0.44,0.44,0.44v-0.44H194.05z M194.33,237.65
			c-0.17,0.06-0.28,0.22-0.28,0.39h0.28V237.65z M194.33,239.31v-0.17h-0.28L194.33,239.31z M195.11,237.21h0.17l-0.06-0.11
			L195.11,237.21z M195.44,240.8c0.5,0,0.94-0.5,1.11-0.94c-0.39,0.06-1.33,0.22-1.33,0.72L195.44,240.8z M196.16,241.91
			c0.28,0,0.61-0.11,0.61-0.5C196.49,241.41,196.32,241.57,196.16,241.91z M196.71,240.41c-0.17,0.28-0.39,0.39-0.39,0.77l0.61-0.39
			L196.71,240.41z M197.7,240.25l0.11,0.17l0.17-0.17l-0.17-0.22L197.7,240.25z M198.31,240.91l-0.11,0.06h0.17L198.31,240.91z
			 M198.92,241.91l0.39-0.28l-0.44-0.28h-0.28L198.92,241.91z M198.64,239.14l0.11,0.17l0.11-0.17l-0.11-0.22L198.64,239.14z
			 M198.81,239.86l0.11,0.17l0.17-0.17l-0.17-0.17L198.81,239.86z M199.58,241.08h0.17l-0.06-0.11L199.58,241.08z M200.13,238.15
			l-0.11,0.06l0.11,0.05c0.06,0,0.06,0,0.11-0.05L200.13,238.15z M200.08,240.64l0.17,0.17l0.11-0.17l-0.11-0.22L200.08,240.64z
			 M200.41,242.46h0.22c0-0.22-0.06-0.33-0.22-0.39V242.46z M200.52,240.8l-0.06,0.06l0.11,0.11l0.11-0.06l-0.11-0.11H200.52z
			 M200.8,241.35h0.17v-0.22h-0.17V241.35z M201.9,243.01l-0.22-0.77l0.22-0.72l-0.77,0.94L201.9,243.01z M201.35,240.8h0.17v-0.22
			h-0.17V240.8z M201.52,239.86h0.17v-0.39h-0.17V239.86z M201.9,241.19l0.33-0.22l-0.33-0.17V241.19z M202.46,242.29h0.39
			c0.44,0,0.5-0.39,0.5-0.77h0.55l-0.39-0.17c-0.44,0-0.61,0.61-1.05,0.61V242.29z M203.4,240.25l0.17,0.17l0.11-0.17l-0.11-0.22
			L203.4,240.25z M203.89,239.86c0.17-0.11,0.22-0.22,0.22-0.39h-0.22V239.86z M204.11,240.58h0.17v-0.33L204.11,240.58z
			 M204.89,242.85l0.11,0.17l0.17-0.17l-0.17-0.22L204.89,242.85z M205,241.69h0.22l-0.22-0.17V241.69z M205.05,240.64l0.17,0.17
			l0.11-0.17l-0.11-0.22L205.05,240.64z M205.33,242.35l0.06,0.11l0.06-0.11c0-0.06,0-0.06-0.06-0.11L205.33,242.35z M206.1,240.25
			l0.22-0.22l-0.39-0.33v0.17L206.1,240.25z M206.1,241.3l-0.06-0.05l-0.11,0.11h0.17V241.3z M206.1,239.14h0.22l-0.22-0.22V239.14z
			 M207.43,237.82l0.99-0.99c-0.5,0-0.83,0.44-1.22,0.83L207.43,237.82z M208.32,239.14h0.28v-0.22h-0.28V239.14z M208.92,238.59
			l0.22,0.17v-0.39L208.92,238.59z M209.14,237.87l0.28,0.33l0.22-0.17l-0.39-0.39L209.14,237.87z M209.31,238.04
			c0-0.11,0-0.17,0.06-0.22L209.31,238.04z M209.81,239.42h-0.17l0.11,0.17l0.06-0.11V239.42z M209.97,237.43l-0.06-0.06l-0.11,0.11
			c0.06,0.06,0.06,0.06,0.11,0.06l0.06-0.06V237.43z M210.25,235.11v0.17l0.11-0.39L210.25,235.11z M210.25,236.1
			c0.06-0.22,0.11-0.39,0.11-0.44L210.25,236.1z M210.25,240.41l0.11,0.17l0.11-0.17l-0.11-0.17L210.25,240.41z M210.58,214.33
			l0.22,0.17l0.11-0.17l-0.11-0.22L210.58,214.33z M210.58,231.57l0.22,0.11l0.11-0.11l-0.11-0.17L210.58,231.57z M210.97,211.89
			h0.11v-0.17L210.97,211.89z M210.97,214.55l0.11,0.11l0.22-0.11l-0.22-0.17L210.97,214.55z M210.97,234.55l0.11,0.17l0.99-0.17
			l-0.99-0.22L210.97,234.55z M211.08,235.99v-0.44l-0.11,0.28L211.08,235.99z M211.63,209.13c-0.28,0.06-0.61,0.11-0.61,0.5
			c0.17-0.06,0.44-0.17,0.72-0.39L211.63,209.13z M211.91,236.93l-0.39-0.39l-0.44,0.17L211.91,236.93z M211.36,214.1
			c0.11-0.06,0.17-0.11,0.17-0.28L211.36,214.1z M211.41,210.24c0,0.5,0.06,1.38,0.72,1.38c0.39,0,0.72-0.28,1.11-0.28
			c0-0.94-1.44-0.11-1.44-1.11H211.41z M211.41,219.08h0.17l-0.06-0.11L211.41,219.08z M211.41,228.75l0.11,0.17v-0.33
			L211.41,228.75z M212.07,235.55c0-0.39-0.39-0.44-0.66-0.44C211.41,235.49,211.69,235.55,212.07,235.55z M211.52,222.12l0.11,0.17
			l0.22-0.17l-0.22-0.22L211.52,222.12z M211.63,230.02l0.28-0.17h-0.39L211.63,230.02z M211.52,234.44
			c0.39,0.17,0.55,0.66,0.94,0.66l0.28-0.22c-0.17-0.39-0.55-0.5-0.94-0.55L211.52,234.44z M211.91,230.96
			c-0.11,0.06-0.22,0.22-0.28,0.44h0.28V230.96z M211.69,214.55l0.22,0.11l0.11,0.99l-0.11-1.27L211.69,214.55z M212.07,217.53
			l0.11,0.17l0.22-0.17l-0.22-0.22L212.07,217.53z M212.24,238.2l0.22,0.17l0.11-0.17l-0.11-0.17L212.24,238.2z M212.41,209.24
			c0,0.61,0.99,0.55,0.99,1.16l-0.11,0.22c0.5-0.22,0.83-0.61,0.99-1.22l-0.55,0.28l-0.72-1.11L212.41,209.24z M212.46,231.13h0.17
			v-0.17L212.46,231.13z M212.46,232.34c0,0.39,0.61,0.39,0.83,0.39h0.11l-0.66-0.77L212.46,232.34z M212.46,235.55
			c0.44,0.22,0.17,0.83,0.55,0.99c0.22-0.28,0.22-0.61,0.22-0.99H212.46z M212.52,210.35l0.11,0.11v-0.22L212.52,210.35z
			 M212.52,236.77l0.11,0.17v-0.39L212.52,236.77z M212.96,209.18l-0.22,0.17l-0.11-0.17l0.11-0.22L212.96,209.18z M212.9,213.94
			l0.28-0.11c-0.06-0.17-0.28-0.28-0.55-0.44v0.17L212.9,213.94z M212.63,230.3l0.11-0.28l-0.11-0.17V230.3z M213.29,234.17
			l0.11-0.17l-0.66-0.22l-0.11,0.11L213.29,234.17z M213.01,222.12v-0.22h-0.28L213.01,222.12z M212.79,229.3l0.22,0.17l0.11-0.17
			l-0.11-0.17L212.79,229.3z M212.9,217.97h0.17l-0.06-0.11L212.9,217.97z M213.18,228.2c-0.17,0.17-0.22,0.28-0.28,0.39h0.28V228.2
			z M213.29,212.45l-0.28,0.17l0.28,0.11l-0.06-0.11L213.29,212.45z M213.18,227.48l0.11-0.22h-0.11V227.48z M213.29,230.41v-0.72
			l-0.11,0.44L213.29,230.41z M213.4,218.53l-0.11,0.05l0.17,0.11l0.11-0.11L213.4,218.53z M213.57,220.46h-0.28l0.44,0.33h-0.17
			V220.46z M213.29,234.89l0.83-0.44l-0.39-0.11L213.29,234.89z M213.95,211.62l-0.22-0.44l-0.17,0.17L213.95,211.62z M213.68,215.6
			l-0.11,0.05h0.17L213.68,215.6z M213.62,213.44l0.11,0.11l0.11-0.11l-0.11-0.17L213.62,213.44z M213.62,217.09l0.11,0.22
			l0.11-0.22l-0.11-0.22L213.62,217.09z M213.62,230.85l0.11,0.11l0.11-0.06l-0.11-0.05H213.62z M213.84,208.91l-0.17,0.22
			l0.17,0.06l0.06-0.22L213.84,208.91z M214.12,208.25c-0.28,0-0.39,0.11-0.39,0.39v0.17l0.55-0.22L214.12,208.25z M213.73,214.66
			l0.39,0.28l-0.39-0.44V214.66z M213.9,231.96v-0.28h-0.17L213.9,231.96z M213.95,223.61l0.17,0.17l0.11-0.17l-0.11-0.22
			L213.95,223.61z M214.17,227.09l0.11,0.17l0.11-0.17l-0.11-0.17L214.17,227.09z M214.45,211.62l0.22-0.28l-0.39-0.33v0.11
			L214.45,211.62z M214.28,232.51l0.17-0.28h-0.17V232.51z M214.34,209.9l0.06,0.17l0.11-0.17l-0.06-0.22L214.34,209.9z
			 M214.34,212.67l0.11,0.17l0.17-0.17l-0.17-0.22L214.34,212.67z M214.34,213.28l0.11,0.11v-0.11H214.34z M214.34,222.51l0.11,0.17
			l0.17-0.17l-0.17-0.22L214.34,222.51z M214.45,224.11l-0.11,0.22l0.11,0.17l-0.11-0.17L214.45,224.11z M214.45,208.74
			c0,0.28,0.17,0.39,0.44,0.39H215L214.45,208.74z M214.45,227.81h0.22l-0.22-0.33V227.81z M214.89,234l-0.22-0.39l-0.22,0.17
			L214.89,234z M214.56,212c0.33,0,0.5-0.17,0.5-0.5C214.73,211.51,214.56,211.67,214.56,212z M215.56,208.96
			c-0.39,0.39-0.83,0.66-0.83,1.27h0.17c0-0.5,0.66-0.5,0.66-1.11V208.96z M214.73,229.58l0.11,0.11l0.11-0.11l-0.11-0.11
			L214.73,229.58z M215,231.52c0,0.17,0.06,0.33,0.22,0.44v-0.44H215z M215.06,228.75l0.17,0.17l0.11-0.17l-0.11-0.17L215.06,228.75
			z M215.17,210.46l-0.06,0.06l0.11,0.11v-0.17H215.17z M215.11,217.97h0.17l-0.06-0.11L215.11,217.97z M215.11,222.4h0.17
			l-0.06-0.11L215.11,222.4z M215.56,224.27l-0.06-0.06l-0.11,0.11l0.11,0.11l0.06-0.11V224.27z'
					/>
					<path
						className='svg-map-fill'
						d='M232.86,214.16c-0.28-0.17-0.72-0.44-0.72-0.83v-6.63c0-0.5,0.66-0.94,1.11-0.94h24.38l1.11,0.77v6.96
			l-0.55,0.66h-7.9l-0.33,0.28v29.41l-0.94,0.77h-6.91l-1.16-0.94v-29.52H232.86z M234.96,211.45l0.11,0.11l0.11-0.17l-0.11-0.11
			L234.96,211.45z M235.29,209.3l0.17-0.22h-0.17V209.3z M235.56,211.06l-0.11,0.06l0.11,0.11l0.06-0.11L235.56,211.06z
			 M235.51,209.3l0.11,0.17l0.17-0.17l-0.17-0.22L235.51,209.3z M235.62,210.01l0.22-0.11l-0.22-0.06V210.01z M237.22,211.62
			l-0.11,0.05l0.11,0.11l0.06-0.11L237.22,211.62z M240.59,211.56h0.22v-0.28L240.59,211.56z M240.76,208.91l-0.06,0.06l0.11,0.11
			v-0.17H240.76z M241.09,209.9l0.06,0.11l0.11-0.11l-0.11-0.06L241.09,209.9z M241.42,210.95l0.11,0.17l0.11-0.17l-0.11-0.22
			L241.42,210.95z M241.48,208.08l0.06,0.11l0.11-0.11l-0.11-0.11L241.48,208.08z M241.7,211.84l0.28,0.28v-0.44L241.7,211.84z
			 M242.31,209.85l-0.06,0.06l0.11,0.11v-0.17H242.31z M242.64,208.91c0.17,0,0.28-0.11,0.28-0.39
			C242.75,208.52,242.64,208.63,242.64,208.91z M242.97,237.71h0.17l-0.06-0.11L242.97,237.71z M242.97,240.03l0.11,0.17v-0.39
			L242.97,240.03z M243.08,211.84l0.11,0.22c0-0.17-0.06-0.33-0.11-0.39V211.84z M243.25,225.55l0.22,0.17l0.11-0.17l-0.11-0.17
			L243.25,225.55z M243.52,241.69l0.22,0.33l0.22-0.17l-0.22-0.22l-0.06,0.06l-0.06-0.22L243.52,241.69z M243.63,241.85
			c0-0.06,0-0.11,0.06-0.17L243.63,241.85z M243.74,239.42h0.28v-0.17h-0.28V239.42z M243.8,219.69c0.11,0.06,0.22,0.17,0.39,0.33
			v-0.39l-0.17-0.17L243.8,219.69z M244.08,219.85c0-0.11,0-0.17,0.06-0.22L244.08,219.85z M244.08,237.26l0.11,0.17v-0.39
			L244.08,237.26z M244.13,223.67l0.06,0.05l0.17-0.11l-0.06-0.11L244.13,223.67z M244.19,211.89l0.11,0.22l0.22-0.22l-0.22-0.22
			L244.19,211.89z M244.19,215.71l0.11-0.11h-0.11V215.71z M244.19,224.44l0.11-0.17l-0.11-0.22V224.44z M244.19,240.36h0.11v-0.17
			h-0.11V240.36z M244.24,209.35l0.06,0.11l0.11-0.11l-0.11-0.06L244.24,209.35z M244.3,235.38h0.28v-0.17h-0.28V235.38z
			 M244.35,210.24l0.22,0.17l0.11-0.17l-0.11-0.22L244.35,210.24z M244.35,219.47l0.22,0.17l0.11-0.17l-0.11-0.17L244.35,219.47z
			 M244.74,231.35v-0.28h-0.17L244.74,231.35z M244.63,232.62l0.11,0.11l0.11-0.11l-0.11-0.17L244.63,232.62z M244.63,236.27
			l0.11,0.22l0.11-0.22l-0.11-0.22L244.63,236.27z M244.9,239.31l-0.28-0.06l0.5,0.39L244.9,239.31z M245.13,227.87v-0.5l-0.39,0.22
			L245.13,227.87z M244.79,241.3l0.11,0.17l0.17-0.17l-0.17-0.22L244.79,241.3z M244.9,220.18l0.55-0.55h-0.11L244.9,220.18z
			 M244.9,233.56l0.22-0.28h-0.22V233.56z M245.02,229.42h0.66l-0.33-0.39L245.02,229.42z M245.02,234.39v0.05l0.17-0.11l-0.06-0.06
			L245.02,234.39z M245.18,231.35c0,0.05,0.06,0.11,0.11,0.11v-0.11H245.18z M245.35,214.1l0.11,0.22l0.72-0.22l-0.72-0.22
			L245.35,214.1z M245.35,217.92h0.17l-0.06-0.11L245.35,217.92z M245.35,219.3l0.11,0.17l0.17-0.17l-0.17-0.22L245.35,219.3z
			 M245.35,221.12l0.11,0.17l0.17-0.17l-0.17-0.17L245.35,221.12z M245.35,223.33l0.11,0.17l0.17-0.17l-0.17-0.17L245.35,223.33z
			 M245.46,240.91h0.39v-0.39h-0.39V240.91z M245.51,237.98l0.17,0.17l0.11-0.17l-0.11-0.22L245.51,237.98z M246.07,216.54
			c0-0.28-0.28-0.39-0.5-0.39C245.57,216.43,245.84,216.54,246.07,216.54z M245.57,218.64l0.11,0.11v-0.22L245.57,218.64z
			 M246.01,233.12l0.39-0.11c-0.44-0.05-0.28-0.39-0.55-0.55l-0.28,0.44L246.01,233.12z M245.84,235.49
			c-0.22,0.17-0.28,0.44-0.28,0.66l0.44-0.39L245.84,235.49z M245.73,209.57l-0.11,0.06l0.11,0.11l0.11-0.11L245.73,209.57z
			 M245.68,215.71h0.17v-0.11L245.68,215.71z M245.68,224.61h0.17l-0.17-0.17V224.61z M246.51,238.09l-0.83,0.06h0.88L246.51,238.09
			z M245.73,213.22c0,0.06,0.06,0.11,0.11,0.11v-0.11H245.73z M246.23,227.87l-0.22-0.44l-0.17,0.17L246.23,227.87z M246.18,238.98
			l-0.33,0.06l0.33,0.11l0.39-0.06L246.18,238.98z M245.9,222.51l0.11,0.11v-0.22L245.9,222.51z M247.34,207.97l-1.27,0.22
			l1.27,0.17l-0.17-0.17L247.34,207.97z M246.23,219.85l-0.17,0.17l0.17,0.17l-0.17-0.17L246.23,219.85z M246.07,224.99l0.17,0.17
			l0.11-0.17l-0.11-0.17L246.07,224.99z M246.07,239.64l0.17,0.17l0.11-0.17l-0.11-0.22L246.07,239.64z M246.23,216.81l0.17-0.11
			h-0.17V216.81z M246.29,211.34l0.11,0.22l0.11-0.22l-0.11-0.22L246.29,211.34z M246.34,231.63l0.11,0.06l0.11-0.22L246.34,231.63z
			 M246.51,208.47l-0.11,0.06l0.06,0.11l0.11-0.11L246.51,208.47z M246.56,223.5V223l-0.17,0.22L246.56,223.5z M246.56,235.38
			l0.39-0.22l-0.39-0.33l-0.17,0.22L246.56,235.38z M246.45,229.64l0.11,0.17l0.17-0.17l-0.17-0.22L246.45,229.64z M246.56,221.9
			v0.17l0.39-0.39h-0.11L246.56,221.9z M246.62,210.95l0.17,0.17l0.11-0.17l-0.11-0.22L246.62,210.95z M246.67,230.52l0.11,0.28
			c0.17-0.17,0.17-0.39,0.17-0.55L246.67,230.52z M246.78,212.78h0.17v-0.11L246.78,212.78z M246.78,224.05h0.17l-0.17-0.33V224.05z
			 M246.78,228.86h0.17v-0.17h-0.17V228.86z M246.95,236.88c0.22-0.17,0.22-0.44,0.22-0.66l-0.39,0.28L246.95,236.88z
			 M247.34,239.25c-0.22,0-0.5,0.17-0.5,0.39h0.5V239.25z M247.12,228.14h0.22v-0.17h-0.22V228.14z M247.12,242.18h0.22l1.33-0.33
			h-1.55V242.18z M247.23,234.11l0.11,0.17c0.17-0.17,0.39-0.44,0.39-0.66C247.39,233.62,247.23,233.78,247.23,234.11z
			 M247.39,220.02l0.11,0.17l0.44-0.17l-0.44-0.17L247.39,220.02z M247.89,226.65c-0.22,0-0.5,0.17-0.5,0.39h0.5V226.65z
			 M247.39,240.36l0.11,0.17l0.11-0.17l-0.11-0.17L247.39,240.36z M247.56,220.41l0.11,0.17l0.17-0.17l-0.17-0.22L247.56,220.41z
			 M247.56,230.96l0.11,0.11l0.17-0.11l-0.17-0.17L247.56,230.96z M247.78,232.51l-0.11,0.06l0.11,0.11l0.11-0.11L247.78,232.51z
			 M247.94,241.46l0.11,0.17l0.11-0.17l-0.11-0.17L247.94,241.46z M248.17,230.74l-0.17,0.11l0.11,0.11l0.11-0.17L248.17,230.74z
			 M248.22,208.19l0.22-0.22h-0.22V208.19z M248.28,210.79l0.17,0.17l0.11-0.17l-0.11-0.22L248.28,210.79z M248.44,212.11l0.17-0.28
			h-0.17V212.11z M248.66,208.91l0.11,0.17l0.17-0.17l-0.17-0.17L248.66,208.91z M249.6,208.08v0.22l0.11-0.11L249.6,208.08z
			 M249.71,211.84l0.17-0.22l-0.17,0.05V211.84z M250.16,210.24l0.11,0.17l0.11-0.17l-0.11-0.22L250.16,210.24z M250.98,211.56
			l0.06-0.28l-0.22-0.55l-0.22,0.33L250.98,211.56z M251.81,207.91v0.17l0.11-0.11L251.81,207.91z M251.92,208.85
			c0,0.17,0.06,0.33,0.17,0.44l0.22-0.22L251.92,208.85z M251.92,211.56h0.17l-0.17-0.28V211.56z M252.7,211.12l0.17,0.17l0.11-0.17
			l-0.11-0.17L252.7,211.12z M253.08,211.51l0.11,0.17l0.17-0.17l-0.17-0.22L253.08,211.51z M253.53,210.07l0.06,0.11v-0.17
			L253.53,210.07z M253.64,210.4l0.11,0.17l0.17-0.17l-0.17-0.22L253.64,210.4z M253.91,207.64l-0.06,0.06l0.11,0.11v-0.17H253.91z
			 M253.91,210.95l-0.06,0.06l0.06,0.11l0.06-0.17H253.91z M254.3,209.85h0.22c-0.06-0.17-0.17-0.28-0.22-0.39V209.85z
			 M254.69,210.18v-0.17l-0.06,0.28L254.69,210.18z M255.41,209.46c0.17,0.28,0.39,0.44,0.61,0.66l-0.28-0.88L255.41,209.46z
			 M256.9,210.01l-0.11-0.44l-0.06,0.44l0.11-0.11l0.06,0.05V210.01z'
					/>
					<path
						className='svg-map-fill'
						d='M274.2,205.92h7.19l1.05,0.94v13.87h15.37V206.7c0.17-0.28,0.44-0.77,0.83-0.77h7.24l0.72,0.77v37.36
			l-0.55,0.55h-7.63l-0.61-0.55v-14.81h-15.37v14.43l-0.83,0.94h-7.41l-0.72-0.77V206.7L274.2,205.92z M279.56,239.25
			c-0.17-0.44-0.77-0.66-1.16-0.83l-0.33,0.11l0.22,0.17l-0.22,0.55l0.39,0.39l0.55-0.55l-0.72,1.11c-0.44,0-0.61-1.82-0.61-2.16
			c-0.39,0-0.5,0.5-0.88,0.5v0.28l-0.22,0.05l-0.17-0.17l0.39-0.99l-0.77,0.83l0.17,0.22l-0.11,0.06l-0.22-0.17v0.05l0.22,0.55
			l-0.22,0.39l0.39-0.22l0.17,0.17c0.06-0.44,0.5-0.61,0.88-0.61l0.22,0.11l-0.55,0.17l-1.11,1.11l0.22,0.39l1.22-0.77
			c-0.06,0.06,0,0.39,0.06,0.39l0.11-0.17l-0.11-0.22l0.39,0.17l-0.39,0.77l0.17,0.17c-0.44,0.17-0.66,0.61-1.11,0.77l0.39-0.55
			c-0.39,0-0.72,0.17-1.11,0.17v-0.66l-0.55,1.38l0.55-0.33c0.06,0.17,0.22,0.55,0.17,0.72l0.55-0.39v0.39l0.72-0.72l0.22,0.17
			l-0.17,0.17c0.66,0,1.6-0.06,1.82-0.88l-0.33,0.17l-0.61-0.11l0.06-0.11l-0.22-0.17c0.5-0.5,0.94-1.55,1.77-1.55L279.56,239.25
			l0.11-0.22l-0.11-0.22l-0.22,0.11L279.56,239.25z M275.36,208.36l0.17,0.17l0.11-0.17l-0.11-0.17L275.36,208.36z M275.36,209.13
			l0.17,0.17l0.11-0.17l-0.11-0.22L275.36,209.13z M275.36,219.24l0.17,0.22l0.94-0.22l-0.94-0.22L275.36,219.24z M275.36,234.06
			l0.17,0.17l0.11-0.17l-0.11-0.22L275.36,234.06z M275.36,238.7l0.17,0.11l0.11-0.11l-0.11-0.17L275.36,238.7z M275.52,242.4
			l-0.17,0.17l0.17,0.17l-0.17-0.17L275.52,242.4z M275.58,232.95l0.11,0.17l0.11-0.17l-0.11-0.22L275.58,232.95z M275.58,236.82
			l0.11,0.22l0.11-0.22l-0.11-0.22L275.58,236.82z M275.58,238.2v0.17l0.11-0.11L275.58,238.2z M276.08,238.15l0.22-0.44h-0.22
			v-0.11h-0.44L276.08,238.15z M275.69,236.16c0,0.11,0.06,0.22,0.17,0.33l0.06-0.11c-0.06-0.06-0.17-0.17-0.22-0.33V236.16z
			 M275.75,233.34l0.11,0.22l0.17-0.22l-0.17-0.22L275.75,233.34z M275.86,214.99c0.39-0.06,0.61-0.22,0.77-0.39h-0.22
			L275.86,214.99z M275.86,217.09l1.11-0.44l-0.33-0.39L275.86,217.09z M275.86,220.13l0.22-0.11h-0.22V220.13z M276.08,236.6
			c-0.06,0-0.11,0.06-0.11,0.17l0.11,0.11V236.6z M276.24,230.19v-0.22h-0.17L276.24,230.19z M276.13,209.3l0.11,0.17l0.11-0.17
			l-0.11-0.22L276.13,209.3z M276.35,241.69l-0.11,0.17l-0.11-0.17l0.11-0.22L276.35,241.69z M276.19,223.06v0.11l0.06-0.22
			L276.19,223.06z M276.19,225.27l0.06,0.11v-0.22L276.19,225.27z M276.63,226.26c-0.17,0.11-0.28,0.33-0.44,0.61
			c0.17-0.05,0.22-0.17,0.22-0.22c0-0.11-0.06-0.17-0.17-0.17L276.63,226.26z M276.19,241.02l0.06,0.06v-0.17L276.19,241.02z
			 M276.24,208.36c0.17,0.17,0.44,0.39,0.66,0.39c0-0.22-0.33-0.39-0.5-0.39H276.24z M276.24,209.85h0.94l-0.77-0.39L276.24,209.85z
			 M276.24,233.67l0.17-0.11h-0.17V233.67z M276.63,235.44v-0.88l-0.39,0.39L276.63,235.44z M276.3,213l0.11,0.17l0.17-0.17
			l-0.17-0.22L276.3,213z M276.3,216.15l0.11,0.11l0.17-0.11l-0.17-0.17L276.3,216.15z M276.3,222.45l0.11,0.17l0.17-0.17
			l-0.17-0.22L276.3,222.45z M276.35,223l0.99,0.17c-0.17-0.06-0.17-0.33,0-0.39L276.35,223z M276.52,217.31l-0.11,0.05l0.11,0.11
			l0.11-0.11L276.52,217.31z M276.63,221.12v-0.17h-0.22L276.63,221.12z M276.41,224.27h0.5l-0.5-0.39V224.27z M276.46,230.74
			l0.17,0.17l0.11-0.17l-0.11-0.22L276.46,230.74z M276.46,236.27l0.17,0.22l0.11-0.22l-0.11-0.22L276.46,236.27z M276.46,240.91
			l0.17,0.17l0.11-0.17l-0.11-0.17L276.46,240.91z M276.63,213.72h0.17v-0.22h-0.17V213.72z M276.63,237.6l0.17-0.17h-0.17V237.6z
			 M276.8,214.88c-0.17,0.05-0.17,0.28,0,0.28l0.11,0.77L276.8,214.88z M276.91,232.23l-0.11-0.22l-0.11,0.22l0.11,0.17l-0.11,0.17
			l0.11,0.17l0.22-0.17l-0.22-0.17L276.91,232.23z M276.74,233.17l0.06,0.11v-0.17L276.74,233.17z M276.74,236.77l0.06,0.11v-0.28
			L276.74,236.77z M276.96,219.85l0.22-0.28l-0.39-0.11v0.06L276.96,219.85z M276.8,222.62c0.11,0.17,0.22,0.22,0.39,0.22v-0.22
			H276.8z M276.96,234.22v-0.39l-0.17,0.28L276.96,234.22z M276.96,242.74v-0.17h-0.17L276.96,242.74z M276.85,213l0.11,0.17
			l0.17-0.17l-0.17-0.22L276.85,213z M276.85,221.51l0.28,0.39l-0.17-0.61L276.85,221.51z M276.85,230.91v0.39l0.33,0.33l0.11-0.17
			c0-0.17-0.22-0.22-0.28-0.39l0.06-0.11l-0.11-0.22L276.85,230.91z M276.91,225.44l0.06,0.11l0.11-0.06l-0.11-0.11L276.91,225.44z
			 M277.63,208.25l-0.11,0.11l-0.55,0.11l0.77-0.11L277.63,208.25z M276.96,209.07h0.22v-0.17h-0.22V209.07z M277.74,213.16h-0.77
			l0.94,0.17L277.74,213.16z M276.96,239.81v0.17V239.81z M277.02,220.02l0.22,0.11l0.11,0.28l0.11-0.17
			c-0.06-0.11-0.11-0.22-0.28-0.39L277.02,220.02z M277.02,231.46l0.11-0.17L277.02,231.46z M277.9,217.81
			c-0.17-0.39-0.17-0.88-0.55-1.16l-0.17,0.72L277.9,217.81z M277.35,212.39l-0.11,0.22l0.11,0.17l-0.11-0.17L277.35,212.39z
			 M277.24,220.13c0-0.06,0-0.06,0.06-0.11C277.29,220.07,277.24,220.07,277.24,220.13z M277.35,241.3
			c0.06,0.17,0.17,0.22,0.33,0.39l-0.17,0.17l-0.11-0.17c0.06-0.06,0-0.11-0.06-0.06l-0.11-0.17L277.35,241.3z M277.35,211.51h0.17
			v-0.22h-0.17V211.51z M277.68,239.47l-0.17,0.17l-0.11-0.17l0.11-0.22L277.68,239.47z M277.51,214.44l0.22,0.17l-0.22-0.33V214.44
			z M277.57,227.98l0.17,0.17l0.11-0.17l-0.11-0.22L277.57,227.98z M277.57,231.85l0.17,0.17l0.11-0.17l-0.11-0.22L277.57,231.85z
			 M277.57,234.44l0.17,0.22l0.11-0.22l-0.11-0.22L277.57,234.44z M277.74,221.12l0.17-0.17h-0.17V221.12z M277.9,229.75l-0.06-0.06
			l-0.11,0.11l0.06,0.11l0.11-0.11V229.75z M277.85,232.12l-0.11,0.06l0.06,0.11l0.11-0.11L277.85,232.12z M277.9,240.75h-0.17
			v-0.22L277.9,240.75z M277.9,242.51l-0.17,0.06h0.17V242.51z M277.79,233.51l0.11,0.17l0.11-0.17l-0.11-0.22L277.79,233.51z
			 M279.01,208.3c0-0.17-0.17,0-0.17,0.05l-0.94,0.11l1.11-0.11V208.3z M278.29,213.5l-0.39,0.22h0.39V213.5z M277.9,222.84
			l0.94-0.77l-0.94,0.55V222.84z M277.96,225.55l0.11,0.17l0.17-0.17l-0.17-0.17L277.96,225.55z M277.96,230.02l0.11,0.17l0.17-0.17
			l-0.17-0.22L277.96,230.02z M277.96,238.15l0.11,0.11l0.17-0.11l-0.17-0.17L277.96,238.15z M278.07,215.15l0.22-0.17h-0.22V215.15
			z M278.12,208.91l0.17,0.17l0.11-0.17l-0.11-0.17L278.12,208.91z M278.12,212.45l0.17,0.17l0.11,0.39l-0.11-0.77L278.12,212.45z
			 M278.62,217.81l0.88-0.72l-1.22-0.44L278.62,217.81z M278.4,233.78l-0.11,0.06l0.06,0.11l0.11-0.11L278.4,233.78z M278.34,225.77
			l0.11,0.17l0.11-0.17l-0.11-0.22L278.34,225.77z M278.34,232.57l0.11,0.17l0.11-0.17l-0.11-0.17L278.34,232.57z M278.45,242.4
			l-0.11,0.17l0.11,0.17l-0.11-0.17L278.45,242.4z M278.51,238.98l-0.06,0.11l-0.06-0.11l0.06-0.17L278.51,238.98z M278.45,232.95
			h0.11c0.5,0,0.44-0.61,0.83-0.77h-0.06L278.45,232.95z M279.39,239.97c-0.61,0-0.55,0.66-0.94,0.94h0.17l0.94-0.94H279.39z
			 M278.51,234.06l0.11,0.17l0.17-0.17l-0.17-0.22L278.51,234.06z M278.51,234.83c0.17,0.17,0.28,0.28,0.33,0.39l0.11-0.22
			l-0.11-0.22l-0.22-0.11L278.51,234.83z M278.62,218.47h0.22v-0.11L278.62,218.47z M278.84,223.89c-0.17,0-0.22,0.22-0.22,0.39
			l0.39-0.39H278.84z M278.84,237.98l0.33-0.28l-0.33-0.11l-0.22,0.11L278.84,237.98z M278.68,224.99l0.17,0.17l0.11-0.17
			l-0.11-0.17L278.68,224.99z M278.68,232.01l0.17,0.17l0.11-0.17l-0.11-0.17L278.68,232.01z M278.68,234.94l0.11-0.17
			L278.68,234.94z M278.9,234.61l0.11,0.17l0.11-0.17l-0.11-0.22L278.9,234.61z M278.95,214.88h0.44l-0.22-0.61L278.95,214.88z
			 M279.01,210.73l0.39-0.17h-0.39V210.73z M279.56,240.52c0.11,0.11,0.17,0.22,0.11,0.28l0.55-0.17l-0.28-0.66l-0.94,0.94
			l0.17,0.17l0.33-0.17l-0.06-0.11L279.56,240.52z M279.06,234.06l0.11,0.17l0.17-0.17l-0.17-0.22L279.06,234.06z M279.06,238.37
			l0.11,0.17l0.17-0.17l-0.17-0.22L279.06,238.37z M279.39,213.72l0.17-0.22l-0.39-0.33v0.17L279.39,213.72z M279.39,233.28
			c-0.11,0.11-0.17,0.22-0.22,0.39h0.22V233.28z M279.17,237.43l0.22,0.17v-0.55L279.17,237.43z M279.23,218.91l0.17,0.11l0.11-0.11
			l-0.11-0.17L279.23,218.91z M279.23,220.79l0.17,0.17l0.11,0.55l-0.11-0.94L279.23,220.79z M279.39,209.46h0.28
			c0-0.17-0.11-0.33-0.28-0.39V209.46z M279.56,211.12h0.17v-0.17h-0.17V211.12z M279.56,238.15l0.17-0.17h-0.17V238.15z
			 M279.56,242.96l0.55-0.55l-0.17-0.39L279.56,242.96z M279.62,221.35l0.11,0.17h0.17l-0.17-0.39L279.62,221.35z M279.62,235.16
			l0.11,0.17l0.17-0.17l-0.17-0.22L279.62,235.16z M279.67,225.27l0.06,0.11l0.11-0.11l-0.11-0.11L279.67,225.27z M279.95,211.78
			l-0.22,0.28h0.17c0.17,0,0.22-0.06,0.22-0.22h-0.06L279.95,211.78z M279.89,221.68l-0.17,0.17h0.06c0.11,0,0.17-0.06,0.17-0.17
			H279.89z M279.78,226.1l0.17,0.17l0.11-0.17l-0.11-0.17L279.78,226.1z M279.95,215.71c0.22-0.17,0.33-0.33,0.33-0.55h-0.06
			L279.95,215.71z M280.11,241.02l-0.17,0.06l0.06,0.11l0.11-0.11V241.02z M280,215.93l0.11,0.17l0.11-0.17l-0.11-0.22L280,215.93z
			 M280,221.9l0.11,0.17l0.11-0.17l-0.11-0.22L280,221.9z M280,238.37l0.11,0.17l0.11-0.17l-0.11-0.22L280,238.37z M280,241.46
			l0.11,0.17l0.11-0.17l-0.11-0.17L280,241.46z M280.5,220.41v-0.83l-0.39,0.33L280.5,220.41z M280.17,224.11
			c0.06,0.17,0.17,0.28,0.33,0.33l0.11-0.17l-0.11-0.22l-0.22-0.17L280.17,224.11z M280.28,234.39l0.22-0.17h-0.22V234.39z
			 M280.33,224.27l0.11-0.22C280.39,224.11,280.33,224.22,280.33,224.27z M280.33,234.61l0.17,0.17l0.11-0.17l-0.11-0.22
			L280.33,234.61z M280.89,224.99l0.17,0.17l0.11-0.17l-0.11-0.17L280.89,224.99z M281.16,225.38l0.06,0.17l0.11-0.22l-0.11-0.17
			L281.16,225.38z M281.55,223.67l-0.17,0.17l0.06,0.06l0.22-0.11L281.55,223.67z M281.83,223.17l0.11,0.17l0.17-0.17l-0.17-0.22
			L281.83,223.17z M282.77,223.72l-0.22,0.17l0.22,0.17V223.72z M282.93,224.11l0.11,0.17l0.22-0.17l-0.22-0.22L282.93,224.11z
			 M283.32,223.17l-0.22,0.17l0.22,0.17V223.17z M283.87,225.16l0.61-0.72l-0.88,0.39L283.87,225.16z M283.82,222.78l-0.17,0.11
			l0.06,0.06l0.17-0.17H283.82z M284.26,225.93v-0.5c-0.17,0.06-0.39,0.11-0.55,0.28L284.26,225.93z M284.31,223.61l-0.11,0.06
			l0.11,0.11l0.11-0.06L284.31,223.61z M284.53,224.99c0,0.28,0.17,0.39,0.5,0.39c-0.17-0.28-0.33-0.44-0.5-0.55V224.99z
			 M284.81,224.27h0.17l-0.17-0.39V224.27z M285.36,225.71h0.17l-0.17-0.17V225.71z M285.47,224.83l-0.06,0.06l0.11,0.11v-0.17
			H285.47z M285.53,225.93l0.11,0.17l0.22-0.17l-0.22-0.22L285.53,225.93z M286.69,225.55l0.11,0.17l0.17-0.17l-0.17-0.17
			L286.69,225.55z M287.41,225.55l0.17,0.17l0.11-0.17l-0.11-0.17L287.41,225.55z M287.74,224.99h0.17l-0.17-0.17V224.99z
			 M287.8,223.72l0.11,0.17l0.17-0.17l-0.17-0.22L287.8,223.72z M287.96,223l0.17,0.17l0.11-0.17l-0.11-0.22L287.96,223z
			 M288.18,226.76h-0.11l0.11,0.17l0.11-0.11L288.18,226.76z M288.46,224.44l0.22-0.39h-0.5L288.46,224.44z M288.51,225.38
			l0.17,0.17l0.11-0.17l-0.11-0.22L288.51,225.38z M288.74,222.78l0.11,0.17l0.11-0.17l-0.11-0.17L288.74,222.78z M289.07,226.32
			l0.17,0.17l0.11-0.17l-0.11-0.22L289.07,226.32z M289.4,224.61c-0.17,0.06-0.22,0.17-0.22,0.39h0.22V224.61z M289.23,223.89h0.33
			l-0.11-0.55L289.23,223.89z M289.45,224.27l0.11,0.17l0.17-0.17l-0.17-0.22L289.45,224.27z M290.17,225.55
			c-0.17,0-0.33,0.11-0.5,0.17l0.06,0.28c0.22-0.11,0.44-0.28,0.61-0.44H290.17z M289.84,222.78l0.11,0.17l0.11-0.17l-0.11-0.17
			L289.84,222.78z M289.9,226.49l-0.06,0.05l0.11,0.11v-0.17H289.9z M290.17,223.17l0.17,0.17l0.11-0.17l-0.11-0.22L290.17,223.17z
			 M290.34,224.16l0.17-0.44h-0.17V224.16z M290.67,226.26l0.06-0.11l-0.22-0.22v0.06L290.67,226.26z M291.28,224.66l-0.22-0.61
			l-0.17,0.22L291.28,224.66z M290.89,225.71l0.11-0.06l-0.11-0.28V225.71z M291.44,223.72h0.22l-0.22-0.55V223.72z M292.16,223.17
			v-0.22H292L292.16,223.17z M292.16,224.99h0.22c0-0.22-0.06-0.33-0.22-0.39V224.99z M292.55,224.05l0.17-0.17h-0.39L292.55,224.05
			z M292.38,222.62l0.17,0.17l0.11-0.17l-0.11-0.22L292.38,222.62z M293.1,225.32c-0.22,0-0.33,0.06-0.39,0.22h0.39V225.32z
			 M292.77,226.87l0.11,0.17l0.17-0.17l-0.17-0.22L292.77,226.87z M293.1,223.33h0.17v-0.39L293.1,223.33z M293.43,224.27
			c0.28,0.39,0.22,0.72,0.83,0.72h0.11c-0.17-0.28-0.33-0.77-0.72-0.77L293.43,224.27z M294.32,225.66l0.06,0.06v-0.17
			L294.32,225.66z M294.37,223.5h0.55v-0.28L294.37,223.5z M294.37,224.27l0.11,0.06l0.28-0.17l-0.06-0.11L294.37,224.27z
			 M294.82,224.39v0.17l0.11-0.11L294.82,224.39z M294.93,225.16l0.17,0.22v-0.77L294.93,225.16z M295.64,225.55h0.17
			c0.28,0,0.44-0.11,0.44-0.39l-0.06-0.17L295.64,225.55z M295.7,226.1l0.17,0.17l0.11-0.17l-0.11-0.17L295.7,226.1z M296.64,226.32
			l0.11,0.17l0.17-0.17l-0.17-0.22L296.64,226.32z M297.14,224.61v-0.17h-0.17L297.14,224.61z M297.3,223.5h0.22v-0.17L297.3,223.5z
			 M297.3,225.71l0.22-0.17h-0.22V225.71z M297.91,224.27l0.17,0.17l0.11-0.17l-0.11-0.22L297.91,224.27z M298.85,221.9l0.11,0.17
			l0.17-0.17l-0.17-0.22L298.85,221.9z M299.51,216.26h0.22v-0.17h-0.22V216.26z M299.57,213l0.17,0.17l0.11-0.17l-0.11-0.22
			L299.57,213z M299.57,221.51l0.17,0.17l0.11-0.17l-0.11-0.22L299.57,221.51z M299.9,210.79c0,0.22,0.11,0.33,0.39,0.33l-0.39-0.39
			V210.79z M300.01,213.5l0.06,0.22l0.11-0.17l-0.11-0.22L300.01,213.5z M300.12,223l0.17,0.17l0.11-0.17l-0.11-0.22L300.12,223z
			 M300.29,228.14h0.17v-0.17h-0.17V228.14z M300.45,218.47h0.17v-0.11L300.45,218.47z M300.84,221.84v-0.55l-0.22,0.22
			L300.84,221.84z M300.73,215.93l0.06,0.17l0.11-0.17l-0.06-0.22L300.73,215.93z M300.73,240.47h0.17l-0.06-0.11L300.73,240.47z
			 M301.5,213.61l-0.33-0.66l-0.33,0.33L301.5,213.61z M301.17,217.59l0.11-0.22l-0.44-0.55v0.11L301.17,217.59z M301.01,227.54
			l-0.06-0.06l-0.11,0.11l0.06,0.11l0.11-0.11V227.54z M301.06,228.92l0.11,0.17l0.22-0.17l-0.22-0.22L301.06,228.92z
			 M301.17,214.99l0.39-0.28l-0.39-0.28V214.99z M301.56,209.35l-0.11,0.11l0.06,0.11l0.06-0.11V209.35z M301.45,236.27l0.11,0.22
			l0.11-0.22l-0.11-0.22L301.45,236.27z M301.56,216.26c0,0.5,0.22,0.72,0.72,0.72c0-0.61-0.44-0.5-0.72-0.88V216.26z
			 M301.72,227.92l-0.06-0.06l-0.11,0.11l0.11,0.11l0.06-0.11V227.92z M302,210.18v-0.17h-0.28L302,210.18z M302,208.74l-0.22,0.17
			l0.22,0.17V208.74z M301.78,215.38l0.22,0.17v-0.39L301.78,215.38z M301.78,221.35l0.22,0.17v-0.39L301.78,221.35z M301.78,229.08
			l0.22,0.17v-0.39L301.78,229.08z M301.78,241.13l0.22,0.17v-0.39L301.78,241.13z M302.11,217.37h0.17l-0.17-0.17V217.37z
			 M302.17,222.23l0.11,0.17l0.22-0.17l-0.22-0.17L302.17,222.23z M302.22,215.98l0.06,0.11l0.11-0.06l-0.11-0.06H302.22z
			 M302.22,219.3l0.06,0.17l0.11-0.05l-0.11-0.11H302.22z M302.55,229.8l0.11,0.17l0.11-0.17l-0.11-0.17L302.55,229.8z
			 M302.66,210.57l-0.06,0.11l0.06,0.06l-0.06-0.11L302.66,210.57z M302.61,212.11l0.06,0.11l0.11-0.11l-0.11-0.05L302.61,212.11z
			 M302.66,215.54h0.17l-0.22-0.11h0.06V215.54z M302.61,224.88l0.06,0.11l0.11-0.06l-0.11-0.11L302.61,224.88z M302.83,220.41
			c0-0.05,0-0.11-0.06-0.11l-0.11,0.11c0.06,0.06,0.33,0.06,0.39,0.06L302.83,220.41z M302.83,218.19l0.5-1.11l-0.22,0.28
			L302.83,218.19z M303.22,227.2c-0.28,0-0.39,0.11-0.39,0.39h0.39V227.2z M303.11,216.54l-0.22,0.17l0.22,0.11V216.54z M303,219.02
			v0.05l0.17-0.11l-0.06-0.05L303,219.02z M303.05,232.4l-0.06,0.06c0,0.39,0.11,0.88,0.11,1.33v-1.38H303.05z M303.11,214.6h0.11
			v-0.33h-0.11V214.6z M303.11,215.93l0.11,0.17l0.22-0.17l-0.22-0.22L303.11,215.93z M303.27,211.67l0.22,0.17l0.11-0.17
			l-0.11-0.17L303.27,211.67z M303.27,229.8l0.22,0.17l0.11-0.17l-0.11-0.17L303.27,229.8z M303.27,231.29l0.22,0.17l0.11-0.17
			l-0.11-0.22L303.27,231.29z M303.49,210.57l0.17,0.55v-0.39h-0.17V210.57z M303.66,211.34l0.11,0.17l0.22-0.17l-0.22-0.22
			L303.66,211.34z M303.66,212.06l0.11,0.17l0.22-0.17l-0.22-0.22L303.66,212.06z M303.66,223.89h0.39v-0.17h-0.39V223.89z
			 M303.71,209.9l0.06,0.11l0.11-0.11l-0.11-0.06L303.71,209.9z M303.71,228.2l0.06,0.11l0.11-0.06l-0.11-0.11L303.71,228.2z
			 M303.82,232.79l0.22,0.17l0.11-0.17l-0.11-0.22L303.82,232.79z M304.1,221.9l0.11,0.17v-0.39L304.1,221.9z M304.32,215.43
			l-0.11,0.11L304.32,215.43z M304.21,234.78l0.11-0.11h-0.11V234.78z M304.65,210.95l0.11,0.17l0.11-0.17l-0.11-0.22L304.65,210.95
			z M304.82,229.25l0.11,0.17l0.17-0.17l-0.17-0.17L304.82,229.25z'
					/>
					<path
						className='svg-map-fill'
						d='M349.09,234.06c2.38,0,4.97,3.04,8.73,3.04c1.99,0,3.7-0.94,3.7-3.1c0-5.75-16.14-6.36-16.14-17.69
			c0-7.02,6.25-10.89,12.66-10.89c2.49,0,12.49,1.99,12.49,5.14c0,1.6-2.71,5.86-4.48,5.86c-2.21,0-5.03-3.26-8.4-3.26
			c-1.71,0-3.1,0.94-3.1,2.71c0,5.47,16.97,6.02,16.97,17.74c0,7.02-6.63,11.61-13.15,11.61c-2.82,0-12.82-1.6-12.82-5.36
			C345.55,238.92,348.15,234.06,349.09,234.06z M347.82,216.43l0.17,0.17l0.11-0.17l-0.11-0.22L347.82,216.43z M348.82,213.88
			c-0.5,0-0.72,0.22-0.72,0.72L348.82,213.88z M348.21,238.48l0.11,0.11l0.11-0.11l-0.11-0.11L348.21,238.48z M348.32,213h0.22
			v-0.17h-0.22V213z M348.71,239.7l0.22-0.39l-0.44-0.66L348.71,239.7z M348.54,216.76h0.17v-0.17L348.54,216.76z M348.71,219.47
			l-0.17,0.06h0.17V219.47z M348.59,217.53l0.11,0.17l0.11-0.17l-0.11-0.22L348.59,217.53z M349.04,237.98
			c-0.11,0-0.17,0.06-0.17,0.17l0.06,0.06c0.11,0,0.17-0.06,0.17-0.17L349.04,237.98z M349.98,240.25c0.44-0.28,0.77-1.05,0.94-1.49
			l-0.55,0.55l-0.55-0.39l0.17-0.33l-0.55-0.55l-0.33,0.33L349.98,240.25z M349.15,239.86l0.11,0.17l0.11-0.17l-0.11-0.17
			L349.15,239.86z M349.15,240.64l0.11,0.17l0.22-0.17l-0.22-0.22L349.15,240.64z M349.59,218.97l0.22-0.17l-0.17-0.22l-0.39,0.22
			L349.59,218.97z M349.31,215.32l0.11,0.17l0.17-0.17l-0.17-0.22L349.31,215.32z M349.76,212.61c-0.17,0.17-0.33,0.17-0.33,0.39
			l0.39-0.28L349.76,212.61z M349.42,220.24l0.22-0.17h-0.22V220.24z M349.48,211.17l0.17,0.17l0.11-0.17l-0.11-0.17L349.48,211.17z
			 M349.48,214.88l0.17,0.22l0.11-0.22l-0.11-0.22L349.48,214.88z M349.64,214.1c-0.06,0-0.11,0.06-0.11,0.17l0.11,0.11V214.1z
			 M349.98,211.29l-0.17,0.06l0.22,0.11l0.17-0.11L349.98,211.29z M349.87,216.98l0.11,0.17l0.17-0.17l-0.17-0.22L349.87,216.98z
			 M349.98,214.66l0.22-0.17h-0.22V214.66z M350.2,210.46l0.17-0.22h-0.17V210.46z M350.36,238.59h0.22c0-0.28-0.06-0.55-0.22-0.94
			V238.59z M350.47,240.52l-0.11,0.05l0.11,0.11l0.06-0.11L350.47,240.52z M350.42,218.08l0.11,0.17l0.17-0.17l-0.17-0.22
			L350.42,218.08z M350.42,219.74l0.11,0.17l0.17-0.17l-0.17-0.22L350.42,219.74z M350.42,220.63l0.11,0.17l0.17-0.17l-0.17-0.17
			L350.42,220.63z M350.42,221.73l0.11,0.17l0.17-0.17l-0.17-0.17L350.42,221.73z M350.7,214.66l-0.17,0.28
			c0.17,0,0.22-0.11,0.22-0.28H350.7z M350.81,213.05l0.11,0.22l0.11-0.22l-0.11-0.22L350.81,213.05z M350.81,214.88l0.11,0.22
			l0.11-0.22l-0.11-0.22L350.81,214.88z M350.81,216.43l0.11,0.17l0.11-0.17l-0.11-0.22L350.81,216.43z M350.92,221.23v0.11
			l0.39-0.33l-0.22-0.22L350.92,221.23z M351.3,240.25l0.33-0.39l-0.55-0.22L351.3,240.25z M351.14,211.78l0.17,0.11l0.11-0.11
			l-0.11-0.17L351.14,211.78z M351.63,215.43l-0.06-0.05l-0.11,0.11c0.06,0.05,0.06,0.05,0.11,0.05l0.06-0.05V215.43z
			 M351.63,218.42c-0.11,0.05-0.17,0.22-0.17,0.39L351.63,218.42z M351.47,220.46h0.17v-0.39L351.47,220.46z M351.52,211.56
			l0.11,0.17l0.17-0.17l-0.17-0.22L351.52,211.56z M351.58,241.08h0.17l-0.11-0.11L351.58,241.08z M351.75,238.7l-0.11,0.06
			c0.06,0.06,0.11,0.06,0.17,0.06l0.06-0.06L351.75,238.7z M352.02,222.67l0.55-0.22l-0.39-0.33l-0.33,0.33L352.02,222.67z
			 M351.91,208.96l0.11,0.17l0.11-0.17l-0.11-0.17L351.91,208.96z M351.91,211.95l0.11,0.22l0.11-0.22l-0.11-0.22L351.91,211.95z
			 M351.91,216.2l0.11,0.11l0.11-0.11l-0.11-0.17L351.91,216.2z M351.91,219.36l0.11,0.17l0.11-0.17l-0.11-0.22L351.91,219.36z
			 M351.91,239.14l0.11,0.17l-0.11,0.22l0.11,0.17l0.11-0.17l-0.11-0.22l0.11-0.17l-0.11-0.22L351.91,239.14z M352.08,217.53
			l0.11,0.17l0.17-0.17l-0.17-0.22L352.08,217.53z M352.35,213l-0.17,0.28c0.17,0,0.22-0.11,0.22-0.28H352.35z M352.19,223.78
			l0.39,0.33v-0.72L352.19,223.78z M352.52,240.41h0.06c-0.06-0.17-0.17-0.66-0.39-0.66C352.19,239.92,352.3,240.41,352.52,240.41z
			 M352.46,217.31l0.11,0.11l0.11-0.11l-0.11-0.17L352.46,217.31z M352.74,208.96v-0.39h-0.17L352.74,208.96z M352.63,208.08
			l1.22,0.17c-0.17-0.05-0.17-0.33,0-0.39L352.63,208.08z M352.63,221.4l0.11,0.17l0.17-0.17l-0.17-0.22L352.63,221.4z
			 M352.63,241.74l0.11,0.17l0.99-0.17l-0.99-0.22L352.63,241.74z M352.74,238.76c0,0.39,0.17,0.94,0.66,0.94l-0.44-1.11
			L352.74,238.76z M352.74,240.97h0.11l-0.11-0.17V240.97z M353.07,210.24l-0.17,0.17l0.06,0.06l0.22-0.11l-0.06-0.11H353.07z
			 M353.13,219.08l-0.17,0.06l0.83,0.11l-0.66-0.11V219.08z M353.02,219.74l0.11,0.17l0.11-0.17l-0.11-0.22L353.02,219.74z
			 M353.02,220.63l0.11,0.17l0.11-0.17l-0.11-0.17L353.02,220.63z M353.02,222.12l0.11,0.17l0.11-0.17l-0.11-0.22L353.02,222.12z
			 M353.29,208.02l-0.06,0.11l-0.11-0.11l0.11-0.06L353.29,208.02z M353.57,221.01l0.28-0.39l-0.55,0.17L353.57,221.01z
			 M353.4,240.25l0.11,0.17v-0.39L353.4,240.25z M354.23,209.68c0,0.72-0.72,0.88-0.72,1.49v0.11c0.17-0.61,1.16-0.55,1.16-1.27
			L354.23,209.68z M353.51,223.56l0.17-0.17l-0.17-0.17V223.56z M353.62,240.69l0.06,0.11l0.11-0.11l-0.11-0.11L353.62,240.69z
			 M353.74,208.96l0.11,0.17l0.17-0.17l-0.17-0.17L353.74,208.96z M353.74,211.56l0.11,0.17l0.17-0.17l-0.17-0.22L353.74,211.56z
			 M353.74,212.67l0.11,0.17l0.17-0.17l-0.17-0.22L353.74,212.67z M353.79,241.08h0.17l-0.11-0.11L353.79,241.08z M353.9,224.16
			l0.17,0.17h0.11l-0.11-0.39L353.9,224.16z M354.4,224.27l-0.06-0.06l-0.11,0.11l0.11,0.11l0.06-0.11V224.27z M354.23,225.44h0.28
			c0-0.17-0.11-0.33-0.28-0.39V225.44z M354.67,239.7l-0.44,0.33c0.06,0,0.39,0,0.22-0.17L354.67,239.7z M354.45,240.8l0.22,0.17
			v-0.39L354.45,240.8z M354.67,208.91c0,0.39,0.66,0.44,0.94,0.44l-0.28-0.77L354.67,208.91z M354.67,220.29l0.11,0.17l0.11-0.17
			l-0.11-0.22L354.67,220.29z M354.67,223.39l0.28-0.17h-0.28V223.39z M354.95,224.66l0.39-0.33l-0.39-0.39l-0.17,0.39
			L354.95,224.66z M354.84,240.25l0.11,0.17l0.22-0.17l-0.22-0.22L354.84,240.25z M354.95,241.35h0.28l-0.28-0.22V241.35z
			 M355.06,242.02l-0.11,0.06l0.17,0.11l0.11-0.11L355.06,242.02z M355.67,222.67l0.11,0.17v-0.39L355.67,222.67z M355.89,208.25
			l-0.11-0.55v0.28L355.89,208.25z M355.78,221.57l0.11,0.17l0.22-0.17l-0.22-0.22L355.78,221.57z M355.89,223.78v-0.22h-0.11
			L355.89,223.78z M355.78,225.05l0.66-0.55l-0.28-0.17L355.78,225.05z M355.78,240.41h0.11v-0.39h-0.11V240.41z M355.89,240.91
			c-0.06,0-0.11,0-0.11,0.06c0,0.05,0,0.05,0.06,0.05s0.06,0,0.06-0.05V240.91z M356.17,210.79c0.22-0.44,0.28-0.88,0.72-1.27
			l-0.17-0.17c-0.39,0-0.83,0.5-0.83,0.88L356.17,210.79z M355.95,208.41l0.22,0.17l0.11-0.17l-0.11-0.17L355.95,208.41z
			 M356.06,241.69h0.28v-0.33L356.06,241.69z M356.28,240.97l-0.17,0.11l0.06,0.06l0.17-0.17H356.28z M356.72,222.28v-0.55h-0.55
			L356.72,222.28z M357.11,240.25c0-0.39-0.33-0.61-0.72-0.61C356.55,239.86,356.72,240.25,357.11,240.25z M356.55,207.75
			l-0.11,0.11l0.11,0.11l0.17-0.11L356.55,207.75z M356.55,242.35l-0.11,0.11h0.28L356.55,242.35z M356.5,226.76l0.22,0.17
			l0.11-0.17l-0.11-0.22L356.5,226.76z M356.78,222.51l0.11,0.17v-0.39L356.78,222.51z M356.78,240.8l0.11,0.33
			c0-0.17,0.06-0.33,0.22-0.33H356.78z M357,208.85l-0.11,0.11l0.99,0.11l-0.88-0.11V208.85z M357.27,225.21l0.17-0.17H357
			L357.27,225.21z M358.54,225.66c-0.61,0-1.38,0.44-1.38,1.11l1.38-0.77V225.66z M357.27,223.61v0.5l0.17-0.17L357.27,223.61z
			 M357.99,208.74c-0.17,0-0.5,0-0.55,0.22h0.55V208.74z M357.66,225.32l-0.11,0.11l0.11,0.11l0.17-0.11L357.66,225.32z
			 M357.77,242.07l-0.22,0.17c0.17-0.05,0.11,0.17,0.06,0.22l0.22,0.17c0.06-0.11,0.17-0.22,0.39-0.39l-0.39-0.17H357.77z
			 M357.66,242.29c0.06-0.06,0.06-0.06,0.11-0.06C357.71,242.29,357.71,242.29,357.66,242.29z M357.88,239.81h-0.11l0.11,0.11
			l0.11-0.06L357.88,239.81z M357.88,240.25l0.11,0.17l0.11-0.17l-0.11-0.22L357.88,240.25z M357.88,241.74l0.11,0.17l0.11-0.17
			l-0.11-0.22L357.88,241.74z M358.16,224.11l0.22-0.55l-0.39,0.39L358.16,224.11z M358.05,243.01l0.11,0.17l0.17-0.17l-0.17-0.22
			L358.05,243.01z M358.21,208.25l0.33,0.33l0.11-0.17l-0.11-0.17l-0.06,0.06l-0.11-0.28L358.21,208.25z M358.43,240.75l-0.11,0.11
			l0.06,0.11l0.17-0.17L358.43,240.75z M358.43,208.41v-0.06V208.41z M358.43,224.72l0.11,0.17l0.11-0.17l-0.11-0.22L358.43,224.72z
			 M358.43,227.37l0.11,0.11v-0.22L358.43,227.37z M358.54,209.85v0.06l0.39-0.39h-0.17L358.54,209.85z M358.71,242.46h0.22v-0.39
			h-0.22V242.46z M358.77,224.33l0.17,0.17l0.11-0.17l-0.11-0.22L358.77,224.33z M359.1,240.25h0.17v-0.22h-0.17V240.25z
			 M359.65,241.69l0.17-0.33l-0.44-0.39L359.65,241.69z M359.54,209.18l0.11,0.17l0.11-0.17l-0.11-0.22L359.54,209.18z
			 M359.7,241.91l0.11,0.17l0.17-0.17l-0.17-0.22L359.7,241.91z M359.82,209.52h0.44l-0.44-0.17V209.52z M359.82,225.99h0.22v-0.22
			h-0.22V225.99z M360.09,210.46l0.11,0.17l0.11-0.17l-0.11-0.22L360.09,210.46z M360.09,242.62l0.11,0.17
			c0.06-0.06,0.11,0,0.06,0.05l0.11,0.17l0.17-0.17c-0.06-0.11-0.17-0.22-0.33-0.39L360.09,242.62z M360.2,208.96
			c0.17,0,0.28-0.06,0.39-0.17h-0.39V208.96z M360.26,239.97h0.17l-0.06-0.11L360.26,239.97z M360.26,242.79
			c0-0.06,0-0.11,0.06-0.17L360.26,242.79z M360.59,241.13h0.17l0.39-0.33h-0.55V241.13z M360.64,228.03l0.11,0.17l0.11-0.17
			l-0.11-0.22L360.64,228.03z M360.92,209.79c0.28,0,0.39-0.28,0.39-0.5C361.03,209.3,360.92,209.57,360.92,209.79z M361.14,228.59
			l0.33-0.22l-0.39-0.22l-0.17,0.06L361.14,228.59z M361.14,239.47v-0.17h-0.22L361.14,239.47z M360.92,242.46h0.22v-0.55
			L360.92,242.46z M361.14,225.6l0.39-0.22l-0.06-0.17L361.14,225.6z M361.42,239.86l-0.06,0.17l0.11,0.22l0.11-0.17l-0.11-0.22
			H361.42z M361.75,240.75l-0.11,0.11l0.06,0.11l0.17-0.17L361.75,240.75z M361.75,208.41l0.11,0.17l0.11-0.17l-0.11-0.17
			L361.75,208.41z M362.08,225.44l0.17,0.17l0.11-0.17l-0.11-0.22L362.08,225.44z M362.08,239.14l0.5,0.55l0.17-0.17l-0.5-0.61
			L362.08,239.14z M362.19,229.14l-0.06,0.05l0.11,0.11v-0.17H362.19z M362.25,228.59h0.33c0-0.17-0.17-0.22-0.33-0.22V228.59z
			 M362.3,239.31v-0.06V239.31z M362.47,211.95l0.11,0.22l0.17-0.22l-0.17-0.22L362.47,211.95z M362.47,239.47
			c0-0.06,0-0.11,0.06-0.17L362.47,239.47z M362.47,240.25l0.11,0.17l0.11-0.22l-0.11-0.17L362.47,240.25z M362.97,211.45v-0.5
			l-0.39,0.22L362.97,211.45z M362.86,229.53l0.11,0.17l0.11-0.17l-0.11-0.22L362.86,229.53z M362.97,241.91h0.17v-0.22h-0.17
			V241.91z M363.13,227.81h0.39v-0.17h-0.39V227.81z M363.13,230.58h0.39v-0.17h-0.39V230.58z M363.24,212.11l0.06,0.17l0.11-0.17
			l-0.06-0.22L363.24,212.11z M363.52,210.46h0.17v-0.39L363.52,210.46z M363.52,226.71c0.06,0.17,0.22,0.22,0.39,0.22
			c-0.06-0.17-0.17-0.28-0.22-0.39L363.52,226.71z M363.57,225.82l0.11,0.17l0.17-0.17l-0.17-0.22L363.57,225.82z M363.57,237.65
			l0.11,0.17l0.17-0.17l-0.17-0.17L363.57,237.65z M363.57,239.86l0.11,0.17l0.17-0.17l-0.17-0.17L363.57,239.86z M363.79,231.07
			l0.66-0.22v-0.28l-0.77,0.39L363.79,231.07z M363.68,232.23h0.22l-0.22-0.17V232.23z M363.68,236.93l0.22-0.22h-0.22V236.93z
			 M363.68,240.97c0.17,0,0.22-0.22,0.22-0.39C363.74,240.75,363.68,240.86,363.68,240.97z M363.74,234.89l0.17,0.11l0.11-0.11
			l-0.11-0.17L363.74,234.89z M363.85,228.14l0.06,0.06c0.11-0.06,0.17-0.22,0.17-0.39L363.85,228.14z M363.96,241.74l0.11,0.17
			l0.11-0.17l-0.11-0.22L363.96,241.74z M364.07,211.34l0.17-0.17l-0.17-0.17V211.34z M364.46,229.3c-0.17,0.05-0.33,0.22-0.39,0.39
			c0.17-0.17,0.39-0.22,0.55-0.39H364.46z M364.9,234.06l-0.83,0.11h0.94L364.9,234.06z M364.13,239.31l0.11,0.17l0.17-0.17
			l-0.17-0.17L364.13,239.31z M364.29,238.98l0.17,0.17l0.11-0.17l-0.11-0.22L364.29,238.98z M364.62,231.85l-0.17,0.11h0.33
			L364.62,231.85z M364.51,233.84V234l0.11-0.11L364.51,233.84z M364.62,232.79l0.06,0.06l0.11-0.22L364.62,232.79z M364.79,233.06
			h0.39v-0.28L364.79,233.06z M365.01,235.83l0.17-0.28l-0.39-0.28v0.17L365.01,235.83z M364.79,238.37h0.77l-0.44-0.44
			L364.79,238.37z M364.84,228.42l0.17,0.17l0.11-0.17l-0.11-0.22L364.84,228.42z M365.07,229.69l0.11,0.17l0.11-0.17l-0.11-0.22
			L365.07,229.69z M365.07,239.7l0.11,0.17l0.11-0.17l-0.11-0.22L365.07,239.7z M365.18,230.41h0.39c-0.17-0.17-0.28-0.05-0.33-0.22
			l-0.06,0.11V230.41z M365.23,236.32l0.11,0.22l0.17-0.22l-0.17-0.22L365.23,236.32z M365.51,239.64l-0.22,0.17l0.11,0.06
			l0.17-0.17L365.51,239.64z M365.73,236.93v-0.39c-0.17,0-0.33,0-0.39,0.17L365.73,236.93z M365.4,212.11l0.33,0.33l0.11-0.11
			l-0.11-0.17c0,0.06-0.11,0.06-0.11,0l0.05-0.06l-0.11-0.22L365.4,212.11z M365.73,228.92l-0.17-0.33l-0.06,0.11L365.73,228.92z
			 M366.12,232.34l0.17-0.11l-0.77-0.55L366.12,232.34z M365.89,234.44l0.22-0.11c-0.17-0.22-0.39-0.28-0.61-0.44L365.89,234.44z
			 M365.62,211.51v0.17l0.11-0.05L365.62,211.51z M365.62,212.28c0-0.05,0.05-0.05,0.05-0.11
			C365.62,212.23,365.62,212.23,365.62,212.28z M365.95,228.03l0.17,0.17l0.11-0.17l-0.11-0.22L365.95,228.03z M365.95,237.87
			l0.17,0.17l0.11-0.17l-0.11-0.22L365.95,237.87z M366.17,238.2l0.11,0.17c0.06-0.05,0.11,0,0.06,0.06l0.11,0.17l0.17-0.17
			c-0.06-0.11-0.17-0.22-0.33-0.39L366.17,238.2z M367.17,235.72c-0.61,0-0.88,0.72-0.88,1.22l0.94-0.66l0.72,0.66l0.22-0.33
			L367.17,235.72z M366.34,238.37c0-0.05,0-0.11,0.05-0.17L366.34,238.37z M366.45,235h0.39l-0.17-0.28L366.45,235z M366.67,210.24
			l-0.17,0.22l0.17,0.17l-0.17-0.17L366.67,210.24z M366.5,230.8l0.17,0.17l0.11-0.17l-0.11-0.22L366.5,230.8z M367,232.07l0.5-0.61
			c-0.39,0-0.66,0.17-0.83,0.5L367,232.07z M366.67,239.47h0.17l-0.17-0.17V239.47z M366.72,229.86l0.11,0.17l0.11-0.17l-0.11-0.17
			L366.72,229.86z M367.11,211.23l0.06,0.11l0.11-0.17l-0.05-0.17L367.11,211.23z M367.17,237.48l-0.06,0.06l0.11,0.11v-0.17H367.17
			z M367.28,238.59l0.11,0.17l0.11-0.17l-0.11-0.22L367.28,238.59z M367.55,230.24l-0.06-0.06l-0.11,0.11
			c0.06,0.06,0.06,0.06,0.11,0.06l0.06-0.06V230.24z M367.61,233.78l0.17,0.11l0.11-0.11l-0.11-0.17L367.61,233.78z M367.77,231.13
			h0.17v-0.17L367.77,231.13z M367.83,234.55l0.11,0.17l0.11-0.17l-0.11-0.22L367.83,234.55z M368,237.87l0.17,0.17
			c-0.06,0-0.06,0.17,0,0.17l0.17-0.17c-0.17,0.05-0.22-0.11-0.05-0.17l-0.17-0.22L368,237.87z M368.11,232.62h0.22v-0.39h-0.22
			V232.62z M368.33,235c-0.22,0.17-0.22,0.44-0.22,0.66l0.39-0.33L368.33,235z M368.38,230.8l0.11,0.17l0.11-0.17l-0.11-0.22
			L368.38,230.8z M368.55,234.44h0.61l-0.33-0.94l-0.33,0.28l0.17,0.39L368.55,234.44z M368.55,233.01l0.11,0.17l0.17-0.17
			l-0.17-0.22L368.55,233.01z'
					/>
					<path
						className='svg-map-fill'
						d='M408.67,230.96v-24.21l1.16-0.94h6.91c0.28,0,1.11,0.55,1.11,0.94v24.93c0,7.96-8.51,13.54-15.81,13.54
			c-7.52,0-15.86-5.58-15.86-13.71v-24.6c0-0.61,0.66-1.11,1.27-1.11h6.63l1.27,0.77v24.26c0,3.32,3.59,5.14,6.52,5.14
			C404.75,235.99,408.67,234.33,408.67,230.96z M388.28,208.41l0.11,0.17l0.11-0.17l-0.11-0.17L388.28,208.41z M388.39,227.48
			l1.27,0.33l-1.11-0.55L388.39,227.48z M388.56,231.9l-0.06-0.06l-0.11,0.11c0.06,0.05,0.06,0.05,0.11,0.05l0.06-0.05V231.9z
			 M389.05,212.11l-0.11,0.17l0.06,0.06l0.17-0.11L389.05,212.11z M388.94,220.46h0.44c-0.17-0.17-0.28-0.22-0.44-0.22V220.46z
			 M389.5,230.02l-0.22-0.55l-0.33,0.33L389.5,230.02z M389,228.03l0.11,0.17l0.44-0.17l-0.44-0.22L389,228.03z M389.22,210.18
			l-0.11,0.06l0.11,0.11l0.17-0.11L389.22,210.18z M390.16,211.34l-0.66-0.39l-0.39,0.22L390.16,211.34z M389.16,213.05l0.33,0.33
			l0.11-0.17L389.5,213l-0.05,0.06l-0.06-0.22L389.16,213.05z M389.38,208.25h0.11v-0.22h-0.11V208.25z M389.44,213.05
			c0,0.11,0,0.17-0.06,0.22C389.38,213.16,389.38,213.11,389.44,213.05z M389.55,209.18l0.11,0.17l0.22-0.17l-0.22-0.22
			L389.55,209.18z M389.66,208.58h0.28v-0.17h-0.28V208.58z M389.77,212.78l-0.11,0.06l0.11,0.11l0.17-0.11L389.77,212.78z
			 M389.94,211.62l-0.22,0.17l0.22,0.11V211.62z M389.94,216.87h0.11v-0.11L389.94,216.87z M390.05,209.52l0.22-0.72h-0.22V209.52z
			 M390.1,217.09l0.11,0.22l0.22-0.22l-0.22-0.22L390.1,217.09z M390.27,220.85l0.22,0.17v-0.39L390.27,220.85z M390.43,225.44
			l-0.06,0.06l0.11,0.11v-0.17H390.43z M390.6,215.49v-0.28h-0.11L390.6,215.49z M390.49,229.3l0.11,0.17l0.11-0.17l-0.11-0.17
			L390.49,229.3z M390.6,208.25c0.17,0,0.33-0.05,0.44-0.22h-0.44V208.25z M390.66,210.29l0.11,0.17l0.22-0.17l-0.22-0.22
			L390.66,210.29z M390.66,215.65l0.11,0.11l0.22-0.11l-0.22-0.17L390.66,215.65z M390.66,233.78l0.11,0.11l0.22-0.11l-0.22-0.17
			L390.66,233.78z M390.71,217.7l0.06,0.17l0.11-0.05l-0.11-0.11H390.71z M390.93,209.79c0.39,0,0.66-0.11,0.66-0.5
			C391.21,209.3,390.93,209.41,390.93,209.79z M391.6,217.14v-0.28h-0.44L391.6,217.14z M391.32,236.38l0.28-0.39h-0.44
			L391.32,236.38z M391.21,214.71l0.11,0.22l0.22-0.22l-0.22-0.22L391.21,214.71z M391.43,212.23l-0.11,0.05l0.17,0.11l0.11-0.11
			L391.43,212.23z M391.54,217.42l-0.22,0.28c0.17,0,0.28-0.11,0.28-0.28H391.54z M391.32,222.84h0.28v-0.17h-0.28V222.84z
			 M391.6,229.3v-0.39l-0.28,0.22L391.6,229.3z M391.6,207.69l0.11,0.17l0.17-0.17l-0.17-0.22L391.6,207.69z M391.82,208.8
			l-0.11,0.17l0.06,0.06l0.17-0.11l-0.05-0.11H391.82z M391.71,212.83l0.17,0.61v-0.17L391.71,212.83z M391.87,237.82v-0.72
			l-0.17,0.17L391.87,237.82z M391.76,213.77l0.33,0.55l-0.22-0.77L391.76,213.77z M391.76,214.88l0.11,0.22l0.22-0.22l-0.22-0.22
			L391.76,214.88z M391.76,228.2l0.11,0.17l0.22-0.17l-0.22-0.17L391.76,228.2z M391.82,216.04l0.06,0.17l0.11-0.06l-0.11-0.11
			H391.82z M392.54,236.38h0.17c-0.17-0.39-0.39-0.83-0.83-0.83C392.04,235.94,392.09,236.38,392.54,236.38z M392.7,208.58
			c-0.22,0.22-0.33,0.39-0.39,0.55h0.44l-0.11-0.28L392.7,208.58z M392.31,227.48l0.11,0.17l0.22-0.17l-0.22-0.22L392.31,227.48z
			 M392.7,230.96v0.17l0.55-0.28l-0.33-0.28L392.7,230.96z M392.76,208.8l0.55,0.17l-0.44-0.39L392.76,208.8z M392.92,235.33
			l0.06,0.22l0.11-0.22l-0.11-0.33L392.92,235.33z M393.31,240.64l0.11,0.17v-0.39L393.31,240.64z M393.59,236.77l0.22,0.17
			l0.11-0.17l-0.11-0.22L393.59,236.77z M394.08,235.55c0,0.28,0.17,0.44,0.44,0.44v-0.44H394.08z M394.36,237.65
			c-0.17,0.06-0.28,0.22-0.28,0.39h0.28V237.65z M394.36,239.31v-0.17h-0.28L394.36,239.31z M395.13,237.21h0.17l-0.06-0.11
			L395.13,237.21z M395.46,240.8c0.5,0,0.94-0.5,1.11-0.94c-0.39,0.06-1.33,0.22-1.33,0.72L395.46,240.8z M396.18,241.91
			c0.28,0,0.61-0.11,0.61-0.5C396.52,241.41,396.35,241.57,396.18,241.91z M396.74,240.41c-0.17,0.28-0.39,0.39-0.39,0.77l0.61-0.39
			L396.74,240.41z M397.73,240.25l0.11,0.17l0.17-0.17l-0.17-0.22L397.73,240.25z M398.34,240.91l-0.11,0.06h0.17L398.34,240.91z
			 M398.95,241.91l0.39-0.28l-0.44-0.28h-0.28L398.95,241.91z M398.67,239.14l0.11,0.17l0.11-0.17l-0.11-0.22L398.67,239.14z
			 M398.84,239.86l0.11,0.17l0.17-0.17l-0.17-0.17L398.84,239.86z M399.61,241.08h0.17l-0.06-0.11L399.61,241.08z M400.16,238.15
			l-0.11,0.06l0.11,0.05c0.05,0,0.05,0,0.11-0.05L400.16,238.15z M400.11,240.64l0.17,0.17l0.11-0.17l-0.11-0.22L400.11,240.64z
			 M400.44,242.46h0.22c0-0.22-0.06-0.33-0.22-0.39V242.46z M400.55,240.8l-0.05,0.06l0.11,0.11l0.11-0.06l-0.11-0.11H400.55z
			 M400.83,241.35h0.17v-0.22h-0.17V241.35z M401.93,243.01l-0.22-0.77l0.22-0.72l-0.77,0.94L401.93,243.01z M401.38,240.8h0.17
			v-0.22h-0.17V240.8z M401.54,239.86h0.17v-0.39h-0.17V239.86z M401.93,241.19l0.33-0.22l-0.33-0.17V241.19z M402.48,242.29h0.39
			c0.44,0,0.5-0.39,0.5-0.77h0.55l-0.39-0.17c-0.44,0-0.61,0.61-1.05,0.61V242.29z M403.42,240.25l0.17,0.17l0.11-0.17l-0.11-0.22
			L403.42,240.25z M403.92,239.86c0.17-0.11,0.22-0.22,0.22-0.39h-0.22V239.86z M404.14,240.58h0.17v-0.33L404.14,240.58z
			 M404.92,242.85l0.11,0.17l0.17-0.17l-0.17-0.22L404.92,242.85z M405.03,241.69h0.22l-0.22-0.17V241.69z M405.08,240.64l0.17,0.17
			l0.11-0.17l-0.11-0.22L405.08,240.64z M405.36,242.35l0.06,0.11l0.05-0.11c0-0.06,0-0.06-0.05-0.11L405.36,242.35z M406.13,240.25
			l0.22-0.22l-0.39-0.33v0.17L406.13,240.25z M406.13,241.3l-0.06-0.05l-0.11,0.11h0.17V241.3z M406.13,239.14h0.22l-0.22-0.22
			V239.14z M407.46,237.82l0.99-0.99c-0.5,0-0.83,0.44-1.22,0.83L407.46,237.82z M408.34,239.14h0.28v-0.22h-0.28V239.14z
			 M408.95,238.59l0.22,0.17v-0.39L408.95,238.59z M409.17,237.87l0.28,0.33l0.22-0.17l-0.39-0.39L409.17,237.87z M409.34,238.04
			c0-0.11,0-0.17,0.06-0.22L409.34,238.04z M409.84,239.42h-0.17l0.11,0.17l0.05-0.11V239.42z M410,237.43l-0.06-0.06l-0.11,0.11
			c0.06,0.06,0.06,0.06,0.11,0.06l0.06-0.06V237.43z M410.28,235.11v0.17l0.11-0.39L410.28,235.11z M410.28,236.1
			c0.06-0.22,0.11-0.39,0.11-0.44L410.28,236.1z M410.28,240.41l0.11,0.17l0.11-0.17l-0.11-0.17L410.28,240.41z M410.61,214.33
			l0.22,0.17l0.11-0.17l-0.11-0.22L410.61,214.33z M410.61,231.57l0.22,0.11l0.11-0.11l-0.11-0.17L410.61,231.57z M411,211.89h0.11
			v-0.17L411,211.89z M411,214.55l0.11,0.11l0.22-0.11l-0.22-0.17L411,214.55z M411,234.55l0.11,0.17l0.99-0.17l-0.99-0.22
			L411,234.55z M411.11,235.99v-0.44l-0.11,0.28L411.11,235.99z M411.66,209.13c-0.28,0.06-0.61,0.11-0.61,0.5
			c0.17-0.06,0.44-0.17,0.72-0.39L411.66,209.13z M411.94,236.93l-0.39-0.39l-0.44,0.17L411.94,236.93z M411.38,214.1
			c0.11-0.06,0.17-0.11,0.17-0.28L411.38,214.1z M411.44,210.24c0,0.5,0.06,1.38,0.72,1.38c0.39,0,0.72-0.28,1.11-0.28
			c0-0.94-1.44-0.11-1.44-1.11H411.44z M411.44,219.08h0.17l-0.06-0.11L411.44,219.08z M411.44,228.75l0.11,0.17v-0.33
			L411.44,228.75z M412.1,235.55c0-0.39-0.39-0.44-0.66-0.44C411.44,235.49,411.71,235.55,412.1,235.55z M411.55,222.12l0.11,0.17
			l0.22-0.17l-0.22-0.22L411.55,222.12z M411.66,230.02l0.28-0.17h-0.39L411.66,230.02z M411.55,234.44
			c0.39,0.17,0.55,0.66,0.94,0.66l0.28-0.22c-0.17-0.39-0.55-0.5-0.94-0.55L411.55,234.44z M411.94,230.96
			c-0.11,0.06-0.22,0.22-0.28,0.44h0.28V230.96z M411.71,214.55l0.22,0.11l0.11,0.99l-0.11-1.27L411.71,214.55z M412.1,217.53
			l0.11,0.17l0.22-0.17l-0.22-0.22L412.1,217.53z M412.27,238.2l0.22,0.17l0.11-0.17l-0.11-0.17L412.27,238.2z M412.43,209.24
			c0,0.61,0.99,0.55,0.99,1.16l-0.11,0.22c0.5-0.22,0.83-0.61,0.99-1.22l-0.55,0.28l-0.72-1.11L412.43,209.24z M412.49,231.13h0.17
			v-0.17L412.49,231.13z M412.49,232.34c0,0.39,0.61,0.39,0.83,0.39h0.11l-0.66-0.77L412.49,232.34z M412.49,235.55
			c0.44,0.22,0.17,0.83,0.55,0.99c0.22-0.28,0.22-0.61,0.22-0.99H412.49z M412.54,210.35l0.11,0.11v-0.22L412.54,210.35z
			 M412.54,236.77l0.11,0.17v-0.39L412.54,236.77z M412.99,209.18l-0.22,0.17l-0.11-0.17l0.11-0.22L412.99,209.18z M412.93,213.94
			l0.28-0.11c-0.06-0.17-0.28-0.28-0.55-0.44v0.17L412.93,213.94z M412.65,230.3l0.11-0.28l-0.11-0.17V230.3z M413.32,234.17
			l0.11-0.17l-0.66-0.22l-0.11,0.11L413.32,234.17z M413.04,222.12v-0.22h-0.28L413.04,222.12z M412.82,229.3l0.22,0.17l0.11-0.17
			l-0.11-0.17L412.82,229.3z M412.93,217.97h0.17l-0.06-0.11L412.93,217.97z M413.21,228.2c-0.17,0.17-0.22,0.28-0.28,0.39h0.28
			V228.2z M413.32,212.45l-0.28,0.17l0.28,0.11l-0.06-0.11L413.32,212.45z M413.21,227.48l0.11-0.22h-0.11V227.48z M413.32,230.41
			v-0.72l-0.11,0.44L413.32,230.41z M413.43,218.53l-0.11,0.05l0.17,0.11l0.11-0.11L413.43,218.53z M413.59,220.46h-0.28l0.44,0.33
			h-0.17V220.46z M413.32,234.89l0.83-0.44l-0.39-0.11L413.32,234.89z M413.98,211.62l-0.22-0.44l-0.17,0.17L413.98,211.62z
			 M413.7,215.6l-0.11,0.05h0.17L413.7,215.6z M413.65,213.44l0.11,0.11l0.11-0.11l-0.11-0.17L413.65,213.44z M413.65,217.09
			l0.11,0.22l0.11-0.22l-0.11-0.22L413.65,217.09z M413.65,230.85l0.11,0.11l0.11-0.06l-0.11-0.05H413.65z M413.87,208.91
			l-0.17,0.22l0.17,0.06l0.06-0.22L413.87,208.91z M414.15,208.25c-0.28,0-0.39,0.11-0.39,0.39v0.17l0.55-0.22L414.15,208.25z
			 M413.76,214.66l0.39,0.28l-0.39-0.44V214.66z M413.93,231.96v-0.28h-0.17L413.93,231.96z M413.98,223.61l0.17,0.17l0.11-0.17
			l-0.11-0.22L413.98,223.61z M414.2,227.09l0.11,0.17l0.11-0.17l-0.11-0.17L414.2,227.09z M414.48,211.62l0.22-0.28l-0.39-0.33
			v0.11L414.48,211.62z M414.31,232.51l0.17-0.28h-0.17V232.51z M414.37,209.9l0.06,0.17l0.11-0.17l-0.06-0.22L414.37,209.9z
			 M414.37,212.67l0.11,0.17l0.17-0.17l-0.17-0.22L414.37,212.67z M414.37,213.28l0.11,0.11v-0.11H414.37z M414.37,222.51l0.11,0.17
			l0.17-0.17l-0.17-0.22L414.37,222.51z M414.48,224.11l-0.11,0.22l0.11,0.17l-0.11-0.17L414.48,224.11z M414.48,208.74
			c0,0.28,0.17,0.39,0.44,0.39h0.11L414.48,208.74z M414.48,227.81h0.22l-0.22-0.33V227.81z M414.92,234l-0.22-0.39l-0.22,0.17
			L414.92,234z M414.59,212c0.33,0,0.5-0.17,0.5-0.5C414.75,211.51,414.59,211.67,414.59,212z M415.58,208.96
			c-0.39,0.39-0.83,0.66-0.83,1.27h0.17c0-0.5,0.66-0.5,0.66-1.11V208.96z M414.75,229.58l0.11,0.11l0.11-0.11l-0.11-0.11
			L414.75,229.58z M415.03,231.52c0,0.17,0.06,0.33,0.22,0.44v-0.44H415.03z M415.09,228.75l0.17,0.17l0.11-0.17l-0.11-0.17
			L415.09,228.75z M415.2,210.46l-0.05,0.06l0.11,0.11v-0.17H415.2z M415.14,217.97h0.17l-0.06-0.11L415.14,217.97z M415.14,222.4
			h0.17l-0.06-0.11L415.14,222.4z M415.58,224.27l-0.05-0.06l-0.11,0.11l0.11,0.11l0.05-0.11V224.27z'
					/>
					<path
						className='svg-map-fill'
						d='M451.68,244.61h-16.14l-0.72-0.94v-36.81l0.72-0.94h15.59c9.89,0,17.74,10,17.74,19.4
			C468.87,234.11,460.96,244.61,451.68,244.61z M437.14,241.57h0.17l-0.11-0.11L437.14,241.57z M437.25,212.06l0.17,0.17l0.11-0.17
			l-0.11-0.22L437.25,212.06z M437.25,225.77l0.17,0.17l0.11-0.17l-0.11-0.22L437.25,225.77z M437.47,222.06l0.11,0.17l0.11-0.17
			l-0.11-0.22L437.47,222.06z M437.58,241.3l0.99,0.17l-0.28-0.55l-0.72,0.22V241.3z M437.75,216.26h0.28v-0.17L437.75,216.26z
			 M437.75,218.19h0.39c0-0.28-0.11-0.39-0.33-0.39h-0.05V218.19z M437.97,223.72l-0.06,0.06l0.11,0.11v-0.17H437.97z
			 M438.08,209.02l-0.11,0.05l0.11,0.11l0.05-0.11L438.08,209.02z M438.13,211.84c-0.05,0.39-0.17,0.72-0.11,1.11L438.13,211.84z
			 M438.02,220.57l0.77-0.5l-0.5-0.22L438.02,220.57z M438.8,221.9l-0.5-0.61l-0.28,0.22L438.8,221.9z M438.02,222.45l0.11,0.17
			l0.11-0.17l-0.11-0.22L438.02,222.45z M438.02,232.57l0.11,0.17l0.11-0.17l-0.11-0.17L438.02,232.57z M438.3,210.57
			c-0.11,0.11-0.17,0.22-0.17,0.33v0.06l0.28-0.28L438.3,210.57z M438.13,224.61c0.28,0,0.28-0.33,0.28-0.55h-0.28V224.61z
			 M438.3,227.2v-0.17h-0.17L438.3,227.2z M438.19,211.12l0.11,0.17l0.22-0.17l-0.22-0.17L438.19,211.12z M438.58,218.75v-0.28
			h-0.28L438.58,218.75z M438.3,223.17h0.28v-0.22h-0.28V223.17z M438.36,213.55l0.22,0.17v-0.39L438.36,213.55z M438.47,235.99
			l0.05,0.11l0.11-0.11l-0.05-0.11L438.47,235.99z M438.69,211.67v-0.39l-0.11,0.22L438.69,211.67z M438.58,216.7l0.11,0.11
			l0.11-0.11l-0.11-0.17L438.58,216.7z M438.58,219.3h0.11v-0.28h-0.11V219.3z M438.69,236.05c-0.17,0.17,0.11,1.11,0.11,1.33
			L438.69,236.05z M438.69,214.82v0.06l0.99-0.44h-0.66L438.69,214.82z M439.18,224.11l0.06-0.06c-0.06-0.22-0.28-0.72-0.55-0.72
			v0.17L439.18,224.11z M438.74,218.58l0.11,0.17l0.22-0.17l-0.22-0.22L438.74,218.58z M438.85,209.85
			c0.11,0.11,0.22,0.17,0.39,0.17v-0.17H438.85z M438.85,212.23h0.28v-0.17h-0.28V212.23z M438.85,215.54h0.28l-0.28-0.11V215.54z
			 M438.85,220.74c0.17,0.17,0.22,0.17,0.39,0.22v-0.22H438.85z M438.85,237.6h0.39l-0.39-0.17V237.6z M438.91,217.59l1.11,0.22
			l-0.88-0.44L438.91,217.59z M439.41,230.35l-0.5,0.22l0.5,0.17l-0.11-0.17L439.41,230.35z M439.13,222.23l0.11,0.17l0.11-0.17
			l-0.11-0.17L439.13,222.23z M439.24,241.02l-0.11,0.06l0.06,0.11l0.06-0.11V241.02z M439.68,240.75c0,0.05,0.05,0.11,0.05,0.17
			l-0.55,0.55l0.61-0.5L439.68,240.75z M439.24,226.65h0.17l0.28-0.39h-0.44V226.65z M439.41,212.78L439.3,213l0.11,0.17L439.3,213
			L439.41,212.78z M439.3,216.32l0.11,0.22l0.22-0.22l-0.22-0.22L439.3,216.32z M439.3,221.9l0.11,0.17l0.22-0.17l-0.22-0.22
			L439.3,221.9z M439.41,222.78h0.39l-0.39-0.39V222.78z M439.68,208.74l0.11,0.17L439.68,208.74z M439.68,213.33l0.11-0.17h-0.11
			V213.33z M439.68,220.41c0.28,0,0.39-0.11,0.39-0.39h-0.39V220.41z M439.68,221.68l0.11,0.17l0.11-0.17l-0.11-0.17L439.68,221.68z
			 M439.79,225.66l-0.11,0.06l0.11,0.11c0.11,0,0.17-0.05,0.17-0.11L439.79,225.66z M439.68,239.64l0.11,0.17l0.11-0.17l-0.11-0.22
			L439.68,239.64z M439.74,214.77l0.06,0.11v-0.28L439.74,214.77z M440.23,227.04v-0.39l-0.28,0.17L440.23,227.04z M440.01,219.69
			l0.22,0.17v-0.39L440.01,219.69z M440.23,231.29l-0.22,0.17l0.22,0.17V231.29z M440.01,236.27l0.22,0.22v-0.44L440.01,236.27z
			 M440.01,241.3l0.11,0.17l-0.11,0.22l0.22,0.17v-0.77L440.01,241.3z M440.18,209.46l-0.06,0.06l0.11,0.11v-0.17H440.18z
			 M440.35,222.62c0.06-0.17-0.05-0.39-0.11-0.55C440.18,222.23,440.29,222.45,440.35,222.62z M440.35,220.96l0.28-0.39l-0.11-0.17
			l-0.28,0.22L440.35,220.96z M440.23,227.2l0.11,0.17l0.11-0.17l-0.11-0.17L440.23,227.2z M440.35,229.36l-0.11,0.06l0.06,0.11
			l0.05-0.11V229.36z M440.35,238.65c-0.05,0-0.11,0-0.11,0.05c0,0.06,0,0.06,0.06,0.06c0.05,0,0.05,0,0.05-0.06V238.65z
			 M440.35,210.73l0.17-0.5L440.35,210.73z M440.51,216.81v-0.17h-0.17L440.51,216.81z M440.51,239.42l0.28-0.33h-0.44
			L440.51,239.42z M440.4,226.49l0.11,0.17l0.22-0.17l-0.22-0.22L440.4,226.49z M440.4,241.46l0.11,0.17l0.22-0.17l-0.22-0.17
			L440.4,241.46z M440.46,227.59c0,0.39,0.28,0.94,0.72,0.94c0-0.39-0.5-0.55-0.5-0.94H440.46z M440.79,239.97
			c0.17-0.11,0.28-0.28,0.28-0.5L440.79,239.97z M440.95,242.02l0.11-0.17l-0.28-0.22v0.11L440.95,242.02z M440.9,212.23l-0.06,0.05
			l0.11,0.11v-0.17H440.9z M440.95,209.07l0.11,0.22L440.95,209.07z M440.95,217.2h0.11v-0.11h-0.11V217.2z M440.95,219.85
			l0.39-0.28l-0.28-0.11L440.95,219.85z M440.95,220.02l0.11,0.11l0.22-0.11l-0.22-0.17L440.95,220.02z M440.95,240.19l0.11,0.17
			l0.22-0.17l-0.22-0.22L440.95,240.19z M441.06,240.91c0.28-0.17,0.44-0.44,0.44-0.72C441.23,240.25,441.12,240.69,441.06,240.91z
			 M441.06,241.3c0.17,0.17,0.39,0.33,0.66,0.39l-0.39-0.61L441.06,241.3z M441.12,217.81l0.22,0.11l0.11-0.11l-0.11-0.17
			L441.12,217.81z M441.12,220.79l0.22,0.17l0.11-0.17l-0.11-0.22L441.12,220.79z M441.34,210.4c0.17-0.06,0.22-0.17,0.22-0.39
			h-0.22V210.4z M442.45,210.01l0.17-0.33l-0.44-0.39l-0.55,0.33L442.45,210.01z M441.89,211.67c0.17-0.05,0.22-0.17,0.22-0.39
			h-0.22V211.67z M442.06,239.09l0.11-0.28l-0.11-0.11l-0.17,0.11L442.06,239.09z M442.61,239.42c-0.28,0.22-0.66,0.55-0.72,0.94
			l0.55-0.39l0.83,0.22l-0.28-0.39l0.05-0.44l-0.33,0.28L442.61,239.42z M442.61,210.79l0.39-0.22l-0.33-0.22l-0.05,0.06V210.79z
			 M442.78,208.36l0.22,0.17l0.11-0.17l-0.11-0.17L442.78,208.36z M443.55,209.07c-0.17,0.17-0.22,0.39-0.28,0.55h0.28V209.07z
			 M443.55,241.46l0.39-0.5l-0.22-0.44l-0.44,0.66L443.55,241.46z M443.77,210.57l0.11,0.17l0.83-0.17l-0.83-0.17L443.77,210.57z
			 M443.88,236.05h7.02c5.36,0,8.4-5.97,8.4-10.72s-2.98-10.89-8.4-10.89h-7.02V236.05z M444.49,209.13c0-0.22-0.11-0.83-0.39-0.83
			C444.1,208.52,444.21,209.13,444.49,209.13z M444.16,239.64l0.11,0.17l0.11-0.17l-0.11-0.22L444.16,239.64z M444.16,240.36
			l0.11,0.17l0.11-0.17l-0.11-0.17L444.16,240.36z M444.27,211.51h0.17v-0.22h-0.17V211.51z M444.27,239.97h0.17v-0.17
			L444.27,239.97z M445.38,239.64c0-0.22,0-0.66-0.39-0.66l-0.33,0.44l0.39-0.17L445.38,239.64z M444.71,240.91l0.11,0.17l0.17-0.17
			l-0.17-0.17L444.71,240.91z M444.88,241.85l0.17,0.17l0.11-0.17l-0.11-0.22L444.88,241.85z M445.04,211.4l0.33-0.28
			c-0.28-0.17-0.05-0.39-0.33-0.55V211.4z M445.1,209.02v0.17l0.11-0.11L445.1,209.02z M445.21,240.75h0.77
			c0-0.39-0.22-0.66-0.39-0.94L445.21,240.75z M445.65,211.67l0.11,0.17l0.11-0.17l-0.11-0.17L445.65,211.67z M445.76,209.46
			c0.06,0.17,0.22,0.22,0.39,0.22v-0.39L445.76,209.46z M445.82,241.57l0.05,0.05l0.17-0.05l-0.11-0.11L445.82,241.57z
			 M446.31,240.19v-0.22h-0.17L446.31,240.19z M446.31,208.19c0,0.22,0.11,0.5,0.17,0.72l0.22-0.17l-0.39-0.77V208.19z
			 M446.37,239.25l0.11,0.17l0.17-0.17l-0.17-0.17L446.37,239.25z M446.7,211.84v-0.17h-0.22L446.7,211.84z M446.59,240.64
			l0.11,0.11v-0.22L446.59,240.64z M447.09,210.24l0.17,0.17l0.11-0.17l-0.11-0.22L447.09,210.24z M447.53,242.4l-0.05,0.06
			l0.11,0.11l0.11-0.06l-0.11-0.11H447.53z M447.97,241.52l0.55-0.44l-0.33,0.22h-0.39L447.97,241.52z M448.19,209.13l0.17,0.17
			l0.11-0.17l-0.11-0.22L448.19,209.13z M448.42,239.09l0.06,0.17l0.11-0.22l-0.06-0.22L448.42,239.09z M448.69,211.51h0.22v-0.39
			h-0.22V211.51z M448.69,240.19c0.11,0,0.22-0.05,0.39-0.22h-0.39V240.19z M448.69,241.63h0.39l-0.17-0.17L448.69,241.63z
			 M449.24,209.85c-0.17,0-0.22,0.11-0.22,0.33h0.22V209.85z M449.8,241.63l-0.39,0.39L449.8,241.63z M449.47,211.67
			c0.17,0.17,0.39,0.22,0.55,0.28c0-0.17-0.11-0.5-0.33-0.5L449.47,211.67z M449.52,241.46l0.11,0.17l0.11-0.17l-0.11-0.17
			L449.52,241.46z M449.69,242.24l0.11,0.17l0.17-0.17l-0.17-0.22L449.69,242.24z M449.8,240.36h0.39l-0.17-0.17L449.8,240.36z
			 M450.02,239.81l0.17,0.17l-0.17-0.33V239.81z M450.35,241.24l-0.05-0.06l-0.11,0.11h0.17V241.24z M450.24,208.36l0.11,0.17
			l0.17-0.17l-0.17-0.17L450.24,208.36z M450.24,238.48l0.11,0.22l0.17-0.22l-0.17-0.22L450.24,238.48z M450.41,240.91l0.17,0.17
			l0.11-0.17l-0.11-0.17L450.41,240.91z M450.9,242.02c-0.11,0.06-0.17,0.22-0.17,0.39l0.22-0.33L450.9,242.02z M451.07,209.85
			l-0.06,0.06l0.11,0.11v-0.17H451.07z M451.62,241.69l-0.06,0.11l0.28-0.33l-0.39-0.72l-0.39,0.39l0.28,0.5l0.11-0.17
			L451.62,241.69z M451.12,240.36c0.11-0.11,0.17-0.22,0.17-0.39h-0.17V240.36z M451.34,210.24l0.11,0.17l0.17-0.17l-0.17-0.22
			L451.34,210.24z M451.73,210.95l0.11,0.17l1.55-0.17l-1.55-0.22L451.73,210.95z M451.73,241.85l0.11,0.17l0.11-0.17l-0.11-0.22
			L451.73,241.85z M452.12,240.08l-0.11,0.11h0.22L452.12,240.08z M452.01,241.08h0.39l-0.17-0.33L452.01,241.08z M452.12,241.96
			l-0.11,0.05l0.11,0.06c0.05,0,0.05,0,0.11-0.06L452.12,241.96z M452.06,212.06l0.17,0.17l0.11-0.17l-0.11-0.22L452.06,212.06z
			 M452.78,242.18l0.33-0.72h-0.33v-0.17h-0.28L452.78,242.18z M452.73,212.39c-0.11,0.11-0.17,0.17-0.17,0.22
			c0.17,0,0.22-0.05,0.22-0.22H452.73z M452.78,211.29h0.17v-0.17L452.78,211.29z M453.06,241.85l0.06,0.17l0.11-0.05l-0.11-0.11
			H453.06z M453.39,240.19l0.11,0.17l0.11-0.17l-0.11-0.22L453.39,240.19z M453.61,240.97l0.17-0.17l-0.28,0.11L453.61,240.97z
			 M453.5,241.85l0.17,0.17l0.39-0.55h-0.17L453.5,241.85z M453.56,238.15l0.17,0.17v0.05l0.17,0.17l0.11-0.17l-0.11-0.22
			l-0.22-0.17L453.56,238.15z M453.72,238.26c0.05,0,0.11-0.05,0.11-0.11L453.72,238.26z M454.22,209.79l-0.17-0.05l-0.17,0.11
			l0.17,0.11l0.17-0.11V209.79z M454.05,238.81h0.17v-0.11h-0.17V238.81z M454.27,240.75l0.22,0.17l0.66-0.55l-0.83,0.39H454.27z
			 M454.33,209.18l0.11,0.11v-0.22L454.33,209.18z M454.99,210.73l0.33-0.17c-0.22-0.39-0.5-0.33-0.88-0.39L454.99,210.73z
			 M454.44,211.51h0.17l-0.17-0.39V211.51z M454.77,237.71l-0.33,0.44h0.33V237.71z M454.49,241.85l0.11,0.17l0.11-0.17l-0.11-0.22
			L454.49,241.85z M454.77,242.02h0.22v-0.17L454.77,242.02z M455.05,238.48l0.11,0.22l0.11-0.22l-0.11-0.22L455.05,238.48z
			 M455.16,239.42h0.28c0-0.17-0.11-0.28-0.28-0.33V239.42z M455.21,237.37l0.11,0.22l0.17-0.22l-0.17-0.22L455.21,237.37z
			 M455.32,240.75h0.22v-0.22L455.32,240.75z M455.38,238.15l0.17,0.11l0.11-0.11l-0.11-0.17L455.38,238.15z M455.55,212.5
			l0.44-0.44h-0.44V212.5z M455.55,212.94h0.33c-0.11-0.11-0.22-0.17-0.33-0.17V212.94z M455.77,238.48l0.11,0.22l0.17-0.22
			l-0.17-0.22L455.77,238.48z M455.88,210.01h0.22v-0.17h-0.22V210.01z M455.99,210.84l0.11,0.11v-0.22L455.99,210.84z
			 M456.48,210.95l0.22,0.17v-0.39L456.48,210.95z M456.87,239.7c0-0.17-0.22-0.17-0.22,0l1.22,0.11L456.87,239.7z M456.71,209.85
			l0.11,0.17l0.11-0.17l-0.11-0.22L456.71,209.85z M456.76,212.83l0.05,0.11v-0.17L456.76,212.83z M456.87,213.88l0.11,0.17
			l0.22-0.17l-0.22-0.17L456.87,213.88z M456.98,210.73h0.28v-0.17h-0.28V210.73z M457.31,213.99l-0.11,0.06l0.11,0.11l0.06-0.11
			L457.31,213.99z M457.26,238.81c0.05-0.06,0.11-0.11,0.11-0.28L457.26,238.81z M457.53,236.32l0.39-0.44l-0.11-0.11l-0.44,0.11
			L457.53,236.32z M457.42,237.04l0.11,0.11l0.22-0.11l-0.22-0.17L457.42,237.04z M457.48,214.33l0.05,0.11l0.11-0.11l-0.11-0.05
			L457.48,214.33z M457.87,212.78l-0.11,0.11l0.11,0.11l0.11-0.11l-0.06-0.11H457.87z M457.87,214.44l-0.11,0.11l0.11,0.11
			l0.11-0.11l-0.06-0.11H457.87z M457.92,211.84v-0.33l-0.11,0.17L457.92,211.84z M458.09,239.81l0.28-0.17h-0.28V239.81z
			 M458.25,213.99l0.05,0.11l0.11-0.11l-0.05-0.11L458.25,213.99z M458.31,238.53l-0.05,0.06l0.11,0.11v-0.17H458.31z M458.47,213.5
			l0.5-0.39c-0.17,0-0.55,0-0.61,0.22L458.47,213.5z M458.36,237.43l0.39-0.72l-0.11-0.22L458.36,237.43z M458.36,239.03l0.11,0.22
			l0.11-0.22l-0.11-0.22L458.36,239.03z M458.64,211.12c0.17-0.11,0.33-0.28,0.39-0.55L458.64,211.12z M458.64,214.6
			c0,0.44,0.22,0.94,0.72,0.94c0-0.39-0.05-1.11-0.44-1.27L458.64,214.6z M459.03,211.84v-0.17h-0.11L459.03,211.84z M458.92,237.37
			l0.11,0.22l0.11-0.22l-0.11-0.22L458.92,237.37z M459.19,212.61h0.28v-0.22L459.19,212.61z M459.47,213.88l0.17-0.17l-0.17-0.22
			l-0.28,0.22L459.47,213.88z M459.25,238.15l0.22,0.11l0.11-0.11l-0.11-0.17L459.25,238.15z M459.8,217.48l-0.17,0.33l0.39-0.17
			l0.72,0.17l0.39-0.72c-1.49,0.28-0.88-0.28-1.77-0.28L459.8,217.48z M459.36,232.84l0.11,0.11v-0.22L459.36,232.84z
			 M459.58,215.65l-0.11,0.06l0.06,0.11l0.11-0.11L459.58,215.65z M459.58,236.88l-0.06,0.06l0.17,0.11l-0.06-0.17H459.58z
			 M460.19,215.43c0.22-0.39,0.61-1.27,1.11-1.27v-0.33c-0.66,0-1.38,0.44-1.66,1.05l0.39-0.28l0.28,0.28l-0.28,0.22L460.19,215.43z
			 M459.69,233.17l0.05,0.11l0.11-0.06l-0.11-0.11L459.69,233.17z M459.75,215.98c0.06,0.06,0.22,0.11,0.44,0.11v-0.11H459.75z
			 M460.19,213.88l0.22-0.39l-0.22-0.33l-0.17,0.33L460.19,213.88z M460.19,234.67c-0.06,0-0.11,0.06-0.11,0.17l0.11,0.11l0.05-0.11
			L460.19,234.67z M460.35,238.92l0.22,0.17l0.11-0.17l-0.11-0.22L460.35,238.92z M460.74,211.89l0.11,0.17l0.99-0.17l-0.99-0.22
			L460.74,211.89z M460.85,233.28v-0.33h-0.11L460.85,233.28z M460.8,219.58v0.06l0.05-0.06v-0.06v-0.06L460.8,219.58z
			 M461.02,215.27l0.66-0.39l0.44,0.33l0.33-0.33l-0.77-0.44L461.02,215.27z M461.29,220.13l0.11-0.11h-0.11V220.13z M461.9,218.36
			c0-0.17-0.06-0.22-0.17-0.22l-0.06,0.06h-0.28L461.9,218.36z M462.4,226.49c-0.17,0.44-0.83,0.72-0.83,1.22
			c0.5,0,0.99-0.39,0.99-0.99L462.4,226.49z M461.9,233.51l-0.11,0.11l0.11,0.11l0.11-0.11L461.9,233.51z M461.85,228.7l0.11,0.17
			l0.22-0.17l-0.22-0.17L461.85,228.7z M461.85,234.06l0.11,0.17l0.22-0.17l-0.22-0.22L461.85,234.06z M462.18,219.58l-0.22,0.28
			c0.17,0,0.28-0.11,0.28-0.28H462.18z M462.01,217.03l0.22,0.17l0.11-0.17l-0.11-0.22L462.01,217.03z M462.01,217.81l0.22,0.11
			l0.11-0.11l-0.11-0.17L462.01,217.81z M462.23,215.98h0.33l-0.33-0.28V215.98z M462.29,214.1l0.11,0.17l0.11-0.17l-0.11-0.22
			L462.29,214.1z M462.29,234.83l0.11,0.11l0.11-0.11l-0.11-0.17L462.29,234.83z M462.51,217.86l-0.11,0.06l0.11,0.11l0.05-0.11
			L462.51,217.86z M462.79,219.85v-0.39l-0.22,0.11L462.79,219.85z M462.62,218.36l0.17,0.11l0.11-0.11l-0.11-0.17L462.62,218.36z
			 M462.79,216.26c0,0.22,0.11,0.66,0.39,0.66l-0.39-0.83V216.26z M462.79,228.31l0.17-0.33h-0.17V228.31z M462.95,233.67l0.39-0.11
			l-0.22-0.44l-0.33,0.28L462.95,233.67z M462.84,232.01l0.11,0.17l0.11-0.17l-0.11-0.17L462.84,232.01z M463.67,219.47
			c-0.39,0.22-0.33,0.66-0.72,0.94c0.39-0.17,0.66-0.44,0.94-0.83L463.67,219.47z M463.06,218.75l-0.05,0.06l0.11,0.11v-0.17H463.06
			z M463.06,228.7l-0.05,0.06l0.11,0.11v-0.17H463.06z M463.12,225.93h0.22v-0.39h-0.22V225.93z M463.17,232.4l0.17,0.17l0.11-0.17
			l-0.11-0.22L463.17,232.4z M463.5,217.92h0.17v-0.28h-0.17V217.92z M464.06,219.02l0.17-0.11l-0.33-0.55l-0.22,0.11L464.06,219.02
			z M464,227.15l-0.11,0.06l0.06,0.11l0.11-0.11L464,227.15z M464.44,228.14l-0.39-1.11v0.94L464.44,228.14z M464.44,226.1
			l0.17-0.17l-0.17-0.39l-0.22,0.17L464.44,226.1z M464.28,232.01l0.17,0.17l0.11-0.17l-0.11-0.17L464.28,232.01z M464.5,219.8
			l-0.11,0.11l0.06,0.11l0.17-0.17L464.5,219.8z M464.5,228.36l0.11,0.17l0.17-0.17l-0.17-0.22L464.5,228.36z M465,221.51
			c0-0.28-0.11-0.39-0.39-0.39L465,221.51z M464.89,228.31c0,0.17,0.11,0.33,0.28,0.39v-0.39H464.89z M465.05,226.49l0.11,0.17
			l-0.11,0.22l0.11,0.17l0.17-0.17l-0.11-0.22l0.11-0.17l-0.17-0.22L465.05,226.49z M465.33,227.59l-0.06,0.06l0.11,0.11v-0.17
			H465.33z M465.6,220.35l0.11,0.22l0.22,0.17l0.11-0.17l-0.11-0.17c0,0.06-0.11,0.06-0.11,0l0.06-0.05l-0.17-0.22L465.6,220.35z
			 M465.88,220.41l-0.11,0.17L465.88,220.41z M465.99,221.68l0.11,0.17l0.11-0.17l-0.11-0.17L465.99,221.68z M466.54,224.94v0.17
			l0.11-0.11L466.54,224.94z'
					/>
					<path
						className='svg-map-fill'
						d='M496.56,206.7c0.39-0.83,0.72-1.33,1.66-1.33c1.16,0,1.6,1.16,2.04,2.04c1.99,4.31,16.64,34.77,16.64,36.15
			c0,0.39-0.11,1.05-0.72,1.05h-7.46c-1.33,0-2.71-5.53-3.32-5.53h-14.26c-1.33,0-1.55,5.53-3.7,5.53h-7.19
			c-0.39,0-0.66-0.77-0.72-1.05C479.53,242.79,495.12,209.63,496.56,206.7z M483.13,240.3l-0.11,0.06l0.11,0.05
			c0.06,0,0.06,0,0.11-0.05L483.13,240.3z M483.57,240.19h0.39v-0.22h-0.39V240.19z M483.95,241.3h0.39c0-0.17-0.05-0.33-0.22-0.39
			L483.95,241.3z M484.51,239.58l-0.05-0.05l-0.11,0.11h0.17V239.58z M484.89,239.25h0.33l-0.17-0.17L484.89,239.25z M485.45,237.15
			h0.17v-0.11h-0.17V237.15z M485.5,238.92l0.11,0.17l0.11-0.17l-0.11-0.22L485.5,238.92z M485.67,234.83l0.11,0.11l0.17-0.11
			l-0.17-0.17L485.67,234.83z M485.78,240.91h0.39v-0.5L485.78,240.91z M485.89,235.99l0.05,0.11l0.11-0.11l-0.05-0.11
			L485.89,235.99z M485.94,237.71c0.17,0.22,0.28,0.5,0.39,0.88l0.11-0.33L485.94,237.71z M486.55,233.95
			c0.22,0,0.33-0.11,0.33-0.28h-0.33V233.95z M487.11,232.51c0,0.61,0.61,0.88,1.11,0.88v-0.28L487.11,232.51z M487.16,234.28
			c0.06,0,0.17-0.55,0.17-0.61l-0.22,0.55L487.16,234.28z M487.27,238.15c0.06-0.17-0.11-0.77-0.17-0.99L487.27,238.15z
			 M487.27,237.04l0.28-0.05l-0.28-0.39V237.04z M487.77,237.98c0.72,0,1.11-0.33,1.11-1.05L487.77,237.98z M487.99,238.48
			l-0.06-0.05l-0.11,0.11c0.05,0.06,0.05,0.06,0.11,0.06l0.06-0.06V238.48z M488.49,230.91c0,0.61,0.11,1.66,0.94,1.66
			c0-0.66-0.72-0.94-0.72-1.66H488.49z M488.93,235.22h0.17v-0.28h-0.17V235.22z M489.15,236.71l0.22,0.17v-0.39L489.15,236.71z
			 M489.54,226.1l0.22,0.17l0.11-0.17l-0.11-0.17L489.54,226.1z M489.54,227.2l0.22,0.17l0.11-0.17l-0.11-0.17L489.54,227.2z
			 M489.54,232.95c0.11,0.05,0.22,0.17,0.39,0.33v-0.17l-0.17-0.39L489.54,232.95z M490.03,235.55l-0.39,0.77l0.11,0.17
			L490.03,235.55z M489.81,233.12v-0.06V233.12z M489.81,234.44l0.11,0.39v-0.61L489.81,234.44z M489.98,233.28l-0.11,0.17
			l0.06,0.11l0.17-0.17l-0.05-0.11H489.98z M490.09,224.83l0.22,0.17l0.11-0.17l-0.11-0.22L490.09,224.83z M490.37,234.44l0.11,0.22
			v-0.44L490.37,234.44z M491.03,230.52l0.11-0.33l-0.55-0.55l-0.11,0.17L491.03,230.52z M490.97,229.08l-0.05,0.06l0.11,0.11v-0.17
			H490.97z M491.14,237.43h0.28v-0.28L491.14,237.43z M491.2,227.98l0.22,0.17l0.11-0.17l-0.11-0.22L491.2,227.98z M491.2,228.7
			l0.22,0.17l0.11-0.17l-0.11-0.17L491.2,228.7z M492.25,235.22c-0.61,0-0.94,0.33-0.94,0.94l1.22-0.72L492.25,235.22z
			 M492.14,228.31h0.11v-0.17h-0.11V228.31z M492.14,233.45v0.05c0.05-0.05,0.11-0.22,0.11-0.39
			C492.19,233.17,492.14,233.28,492.14,233.45z M492.25,224.61h0.28v-0.17h-0.28V224.61z M492.25,225.38h0.28l-0.28-0.22V225.38z
			 M492.25,226.82h0.28v-0.17h-0.28V226.82z M492.25,229.64h0.28l-0.28-0.22V229.64z M492.74,235.94l0.11,0.11l0.17-0.11l-0.17-0.17
			L492.74,235.94z M493.07,225.38h0.55v-0.22L493.07,225.38z M493.13,220.35l0.11,0.22l0.11-0.22l-0.11-0.22L493.13,220.35z
			 M493.24,234.22l0.17-0.55l-0.17,0.44V234.22z M493.68,226.38l-0.11,0.06l0.11,0.11l0.11-0.05L493.68,226.38z M493.63,218.91h0.17
			c0-0.17-0.11-0.28-0.17-0.44V218.91z M493.79,233.84l0.05,0.11l0.28-0.28h-0.11L493.79,233.84z M493.96,220.13
			c0.17-0.06,0.22-0.17,0.22-0.28h-0.22V220.13z M494.35,219.47h0.39v-0.17h-0.39V219.47z M494.35,221.84h0.17v-0.17L494.35,221.84z
			 M494.35,225.93l0.17-0.22l-0.17-0.17V225.93z M494.73,231.07h7.19v-0.33h-0.05c-1.22-1.49-2.43-6.85-3.65-7.79
			c-1.33,2.6-2.43,5.31-3.7,7.96L494.73,231.07z M494.9,235.49h0.39l-0.22-0.17L494.9,235.49z M494.95,220.79l0.11,0.17l0.17-0.17
			l-0.17-0.22L494.95,220.79z M495.29,234.78h0.88l-0.55-0.22L495.29,234.78z M495.45,216.65c0.61-0.5,1.49,0.11,1.49-1.11
			C496.5,215.71,495.45,216.04,495.45,216.65z M495.45,218.36h0.39v-0.17h-0.39V218.36z M495.84,220.96H496l-0.17-0.22V220.96z
			 M496,222.06c0.17-0.22,0.39-0.28,0.39-0.55L496,222.06z M496.06,218.91l0.11,0.11l0.99-0.11l-0.99-0.17L496.06,218.91z
			 M496.06,219.69l0.11,0.17l0.17-0.17l-0.17-0.22L496.06,219.69z M496.23,213.88l0.17,0.17l0.11-0.17l-0.11-0.17L496.23,213.88z
			 M496.72,235.27c-0.17,0-0.28,0.05-0.33,0.22h0.33V235.27z M496.45,236.71l0.11,0.17l0.11-0.17l-0.11-0.22L496.45,236.71z
			 M496.83,216.54c0,0.06,0.06,0.11,0.11,0.11v-0.11H496.83z M497.16,233.73l0.11,0.11l0.17-0.11l-0.17-0.17L497.16,233.73z
			 M497.55,218.91l0.11,0.11l0.72-0.11l-0.72-0.17L497.55,218.91z M497.72,218.14l0.11,0.22l0.17-0.22l-0.17-0.22L497.72,218.14z
			 M498.33,216.2l-0.11,0.05l0.05,0.11l0.11-0.11L498.33,216.2z M498.38,234l-0.17,0.06l0.17,0.11l0.22-0.06L498.38,234z
			 M498.38,217.81c0.22,0,0.22-0.28,0.22-0.44C498.44,217.59,498.38,217.75,498.38,217.81z M498.38,221.68l0.22-0.17h-0.22V221.68z
			 M498.93,236.32c0.39,0,0.39-0.33,0.55-0.55C499.26,235.94,498.93,236.05,498.93,236.32z M498.99,234.06l0.17,0.17l0.11-0.17
			l-0.11-0.22L498.99,234.06z M499.15,214.44h0.06l0.28-0.17l-0.11-0.06L499.15,214.44z M499.21,220.07v0.17l0.11-0.11
			L499.21,220.07z M499.71,215.98h0.17c-0.11-0.17-0.28-0.33-0.55-0.5C499.32,215.82,499.43,215.98,499.71,215.98z M499.6,216.98
			l-0.11,0.11h0.22L499.6,216.98z M500.81,215.32c-0.28,0-0.44,0.06-0.55,0.22h0.55V215.32z M500.31,222.78l0.11,0.17l0.11-0.17
			l-0.11-0.17L500.31,222.78z M500.76,220.02l-0.22,0.11l0.17,0.05l0.11-0.17H500.76z M500.65,221.9l0.17,0.17l0.11-0.17l-0.11-0.22
			L500.65,221.9z M500.98,218.75l0.17-0.28h-0.33L500.98,218.75z M500.98,234.39c0,0.22,0.06,0.33,0.17,0.39v-0.39H500.98z
			 M501.37,217.64h0.17v-0.28h-0.17V217.64z M501.42,219.69l0.11,0.17l0.11-0.17l-0.11-0.22L501.42,219.69z M501.75,217.03
			l0.17,0.17l0.11-0.17l-0.11-0.22L501.75,217.03z M501.75,233.34l0.17,0.22l0.11-0.22l-0.11-0.22L501.75,233.34z M502.08,236.05
			c-0.06,0.06-0.06,0.11-0.06,0.17h0.06V236.05z M502.08,221.51c0.28,0,0.39-0.11,0.39-0.39
			C502.19,221.12,502.08,221.23,502.08,221.51z M502.08,234.78h0.17v-0.11h-0.17V234.78z M502.25,218.47
			c0.05,0.17,0.22,0.28,0.39,0.28v-0.28H502.25z M502.3,227.2l0.17,0.17l0.11-0.17l-0.11-0.17L502.3,227.2z M502.47,235.49h0.33
			l-0.17-0.17L502.47,235.49z M502.53,220.35l0.11,0.22l0.11-0.22l-0.11-0.22L502.53,220.35z M502.58,223.45l0.05,0.05v-0.17
			L502.58,223.45z M502.8,233.67h0.22v-0.11L502.8,233.67z M502.8,236.88h0.22v-0.28h-0.22V236.88z M502.86,221.9l0.17,0.17
			l0.11-0.17l-0.11-0.22L502.86,221.9z M502.86,227.59l0.17,0.17l0.11-0.17l-0.11-0.22L502.86,227.59z M503.19,234.33l-0.17,0.06
			l0.17,0.11l0.17-0.11L503.19,234.33z M503.3,226.04l-0.11,0.06l0.11,0.11l0.06-0.11L503.3,226.04z M503.24,225.38l0.11,0.17
			l0.17-0.17l-0.17-0.22L503.24,225.38z M503.24,228.36l0.11,0.17l0.17-0.17l-0.17-0.22L503.24,228.36z M503.24,236.27l0.11,0.22
			l0.17-0.22l-0.17-0.22L503.24,236.27z M503.3,233.01l0.06,0.11l0.11-0.11l-0.11-0.05L503.3,233.01z M503.74,224.99v-0.39h-0.17
			L503.74,224.99z M503.74,233.67v-0.11h-0.17L503.74,233.67z M503.63,226.87l0.11,0.17l0.11-0.17l-0.11-0.22L503.63,226.87z
			 M503.91,222.78c0,0.17,0.06,0.28,0.22,0.39v-0.39H503.91z M503.96,234.44l0.17,0.22l0.11-0.22l-0.11-0.22L503.96,234.44z
			 M504.29,225.38c0.28-0.06,0.5-0.22,0.66-0.61C504.57,224.77,504.57,225.21,504.29,225.38z M504.35,226.1l0.11,0.17l0.17-0.17
			l-0.17-0.17L504.35,226.1z M504.35,227.98l0.11,0.17l0.17-0.17l-0.17-0.22L504.35,227.98z M504.41,232.84l0.05,0.11l0.11-0.11
			l-0.11-0.11L504.41,232.84z M504.52,235.55l0.17,0.22l0.11-0.22l-0.11-0.22L504.52,235.55z M504.85,222.34l-0.17,0.06l0.05,0.11
			l0.11-0.11V222.34z M504.85,229.64h0.17l-0.33-0.22L504.85,229.64z M504.74,230.58l0.11,0.17l0.11-0.17l-0.11-0.22L504.74,230.58z
			 M504.74,233.34l0.11,0.22l0.11-0.22l-0.11-0.22L504.74,233.34z M504.85,227.37c0.17,0,0.28-0.11,0.28-0.33h-0.28V227.37z
			 M504.85,228.7h0.17v-0.39h-0.17V228.7z M505.12,236.21l-0.11,0.11h0.22L505.12,236.21z M505.18,225.93l-0.06,0.06l0.11,0.11
			v-0.17H505.18z M505.23,234.39h0.44l-0.44-0.55V234.39z M505.4,229.25h0.17v-0.17h-0.17V229.25z M505.46,237.37l0.11,0.22
			l0.17-0.22l-0.17-0.22L505.46,237.37z M505.51,229.86l0.05,0.11l0.11-0.17l-0.11-0.17L505.51,229.86z M505.57,227.37
			c0.17,0,0.22-0.11,0.22-0.33h-0.22V227.37z M505.79,231.24l-0.22,0.05l0.22,0.11l0.17-0.11L505.79,231.24z M505.62,233.12
			l0.17,0.17c0.05-0.06,0.11,0,0.05,0.05l0.11,0.22l0.11-0.22c-0.06-0.11-0.11-0.22-0.28-0.39L505.62,233.12z M505.84,233.28
			c0-0.06,0-0.11,0.06-0.17L505.84,233.28z M505.9,228.53c0.22,0,0.39-0.11,0.44-0.39C506.06,228.14,505.9,228.25,505.9,228.53z
			 M505.9,235.99v0.11l0.17-0.11l-0.11-0.11L505.9,235.99z M505.95,232.18c0.44-0.06,0.94-0.28,0.94-0.77v-0.11L505.95,232.18z
			 M506.34,232.95l0.17-0.22h-0.17V232.95z M506.62,228.97l0.05,0.11l0.11-0.11l-0.11-0.11L506.62,228.97z M506.62,229.64l0.05,0.17
			l0.11-0.17l-0.11-0.22L506.62,229.64z M507.22,230.3l-0.06-0.06l-0.11,0.11l0.11,0.11l0.06-0.11V230.3z M507.06,233.56l0.5-0.44
			h-0.5V233.56z M507.06,237.04c0.11-0.05,0.22-0.22,0.28-0.44h-0.28V237.04z M507.33,238.04l0.11,0.5c0.06-0.28,0.06-0.61,0-0.94
			L507.33,238.04z M508.33,233.12l-0.17-0.55l-0.39,0.28L508.33,233.12z M507.78,237.15h0.28l-0.28-0.11V237.15z M508.05,234.44
			l0.11,0.22l0.11-0.22l-0.11-0.22L508.05,234.44z M508.05,236.05l0.28-0.17h-0.28V236.05z M508.61,238.42l0.28-0.72
			c0.39,0.17,0.61,0.66,1.05,0.66c0-0.44-0.94-0.99-1.33-1.22V238.42z M508.88,233.56c0.17,0.05,0.28,0.11,0.39,0.11L508.88,233.56z
			 M508.99,238.42l-0.11,0.11h0.28L508.99,238.42z M509.16,232.57l0.11,0.17l0.22-0.17l-0.22-0.17L509.16,232.57z M509.16,235.55
			l0.11,0.22l0.22-0.22l-0.22-0.22L509.16,235.55z M509.71,236.88v-0.05c0-0.22-0.17-0.33-0.44-0.33v0.05L509.71,236.88z
			 M509.38,239.53l-0.11,0.11h0.28L509.38,239.53z M509.82,234.94c0-0.17-0.06-0.22-0.11-0.28v0.06L509.82,234.94z M509.99,239.64
			c0,0.11,0.06,0.17,0.11,0.22L509.99,239.64z M510.26,233.56v-0.28h-0.17L510.26,233.56z M510.1,242.02h0.28
			c-0.06-0.11-0.17-0.17-0.22-0.17h-0.06V242.02z M510.15,241.3l0.11,0.17v-0.39L510.15,241.3z M511.04,235.72l-0.77,0.61h0.11
			c0.39,0,0.61-0.33,0.83-0.55L511.04,235.72z M510.43,238.48l0.22,0.22l0.11-0.22l-0.11-0.22L510.43,238.48z M510.71,240.75
			l0.11,0.17v-0.39L510.71,240.75z M511.31,241.3l-0.06,0.06l0.11,0.11v-0.17H511.31z M511.98,236.27l0.11,0.22l0.17-0.22
			l-0.17-0.22L511.98,236.27z M512.09,239.97l0.06,0.06c0.17,0,0.28-0.11,0.33-0.39L512.09,239.97z M512.09,240.91l0.22-0.17h-0.22
			V240.91z M512.31,242.02h0.17v-0.17h-0.17V242.02z M512.64,240.91h0.22v-0.17h-0.22V240.91z M513.53,241.3l-0.05,0.06l0.11,0.11
			v-0.17H513.53z M513.47,242.85l0.11,0.11v-0.22L513.47,242.85z'
					/>
					<path
						className='svg-map-fill'
						d='M538.84,243.84l-1.05,0.77h-6.8c-0.61,0-1.11-0.5-1.11-1.11v-37.59l0.55-0.55h2.16l21.61,20.78V206.7
			l1.16-0.94h6.74c0.39,0.17,1.1,0.55,1.1,0.94v37.7c0,0.61-1.05,0.77-1.49,0.77c-0.28,0-0.83-0.17-1.11-0.22l-21.78-21.45V243.84z
			 M531.99,218.91c0.17,0.17,0.17,0.39,0.22,0.55l-0.11-0.72L531.99,218.91z M531.99,235.16l0.11,0.22l0.11-0.22l-0.11-0.22
			L531.99,235.16z M532.21,212.17l-0.11,0.06l0.11,0.11l0.05-0.11L532.21,212.17z M532.1,213.33h0.17v-0.11h-0.17V213.33z
			 M532.15,217.59l0.11,0.22l0.17-0.22l-0.17-0.22L532.15,217.59z M532.15,224.27l0.11,0.17l0.17-0.17l-0.17-0.22L532.15,224.27z
			 M532.26,220.74c0.17,0,0.22-0.17,0.22-0.33C532.32,220.41,532.26,220.57,532.26,220.74z M532.65,216.81l-0.33,0.22l0.33,0.22
			l-0.11-0.22L532.65,216.81z M532.32,226.32l0.17,0.17l0.11-0.17l-0.11-0.22L532.32,226.32z M532.32,228.53l0.17,0.17l0.11-0.17
			l-0.11-0.22L532.32,228.53z M532.43,217.81c0,0.17,0.05,0.28,0.22,0.39v-0.39H532.43z M532.65,215.71l0.72-0.11h-0.88
			L532.65,215.71z M532.7,227.09h0.05l0.61-0.33v-0.11c-0.28,0.06-0.61,0.06-0.88,0.22L532.7,227.09z M532.54,213.94l0.11,0.11
			l0.11-0.11l-0.11-0.17L532.54,213.94z M532.54,219.47l0.11,0.17l0.11-0.17l-0.11-0.17L532.54,219.47z M532.76,209.85l-0.05,0.06
			l0.11,0.11l0.11-0.11l-0.11-0.06H532.76z M533.04,215.04l0.28-0.44h-0.5L533.04,215.04z M532.93,219.58l-0.11,0.06l0.11,0.11
			l0.11-0.11L532.93,219.58z M533.59,224.77c0-0.39-0.33-0.99-0.77-0.99C532.81,224.16,533.15,224.77,533.59,224.77z M533.31,236.21
			c-0.22,0.11-0.39,0.28-0.5,0.44l0.17,0.06l0.39-0.39L533.31,236.21z M532.98,217.25c0.06,0.17,0.11,0.33,0.22,0.39l0.17-0.28
			L532.98,217.25z M532.98,230.35l0.44-0.28l-0.22-0.28L532.98,230.35z M533.2,213.77l0.72-0.83c-0.11-0.06-0.17-0.17-0.17-0.33
			l-0.72,0.88L533.2,213.77z M533.2,210.73h0.17v-0.17h-0.17V210.73z M533.2,219.08h0.17v-0.33h-0.17V219.08z M533.37,235.49v-0.55
			l-0.17,0.44L533.37,235.49z M533.26,212.61l0.11,0.17l0.17-0.17l-0.17-0.22L533.26,212.61z M533.31,216.43l0.06,0.11l0.11-0.11
			l-0.11-0.17L533.31,216.43z M533.37,220.57h0.22v-0.39L533.37,220.57z M533.37,221.29h0.55c-0.17-0.17-0.33-0.28-0.55-0.33V221.29
			z M533.37,229.53l0.55-0.28l-0.55-0.39V229.53z M533.53,222.95c0,0.28,0.11,0.39,0.39,0.39l0.05-0.11L533.53,222.95z
			 M533.59,214.44l0.72-0.55l-0.72,0.28V214.44z M533.59,215.43h0.17v-0.28h-0.17V215.43z M534.14,219.63
			c-0.17,0-0.28,0.05-0.39,0.22h0.39V219.63z M534.14,216.15v-0.17h-0.22L534.14,216.15z M534.09,218.14
			c-0.11,0-0.17,0.06-0.17,0.17l0.05,0.05c0.11,0,0.17-0.05,0.17-0.17L534.09,218.14z M534.42,223.67c-0.33,0-0.5,0.17-0.5,0.44
			L534.42,223.67z M533.98,217.03l0.17,0.22l0.11-0.22l-0.11-0.22L533.98,217.03z M533.98,235.61l0.17,0.17l0.11-0.17l-0.11-0.22
			L533.98,235.61z M534.09,225.93l-0.05,0.06l0.11,0.11v-0.17H534.09z M534.31,235.22c0.22,0,0.33-0.39,0.39-0.55h-0.39V235.22z
			 M534.36,214.82l0.11,0.22l0.17-0.22l-0.17-0.22L534.36,214.82z M534.47,212.67h0.22v-0.28L534.47,212.67z M534.58,219.02
			l-0.11,0.05l0.11,0.11l0.11-0.11L534.58,219.02z M534.97,221.01l-0.28-0.61l-0.22,0.17L534.97,221.01z M535.03,222.95l0.05-0.11
			l-0.39-0.22l-0.22,0.17L535.03,222.95z M534.58,223.61l0.11,0.11v-0.22L534.58,223.61z M535.25,232.12c0-0.33-0.22-0.5-0.61-0.5
			C534.64,232.01,534.97,232.12,535.25,232.12z M534.69,215.82l0.33-0.22l-0.33-0.44V215.82z M534.86,224.55l-0.17,0.06l0.06,0.11
			l0.11-0.11V224.55z M534.69,225.93h0.17v-0.22L534.69,225.93z M534.86,233.28h0.17l-0.17-0.17V233.28z M535.03,216.81
			c0,0.28,0.17,0.44,0.5,0.44L535.03,216.81z M535.25,219.85v-0.22h-0.22L535.25,219.85z M535.03,235.38
			c0.17-0.11,0.22-0.28,0.22-0.44h-0.22V235.38z M535.08,214.27l0.17,0.17l0.11-0.17l-0.11-0.22L535.08,214.27z M535.08,230.96
			l0.17,0.11l0.11-0.11l-0.11-0.17L535.08,230.96z M535.25,211.84l0.17,0.39l-0.17-0.55V211.84z M535.41,226.65v-0.17h-0.17
			L535.41,226.65z M535.41,223.72h0.17v-0.22L535.41,223.72z M535.41,230.35v0.17l0.39-0.17l-0.22-0.39L535.41,230.35z
			 M535.41,233.12v0.17l0.17-0.55L535.41,233.12z M535.41,234.67h0.17v-0.39L535.41,234.67z M535.47,220.57l0.11,0.17l0.17-0.17
			l-0.17-0.17L535.47,220.57z M535.47,224.99l0.11,0.17c0.06-0.06,0.11,0,0.06,0.05l0.17,0.17l0.11-0.17l-0.11-0.22l-0.22-0.17
			L535.47,224.99z M535.47,228.53l0.11,0.17l0.17-0.17l-0.17-0.22L535.47,228.53z M535.58,222.06h0.22l0.33-0.22h-0.55V222.06z
			 M535.63,225.16l0.11-0.17L535.63,225.16z M535.74,216.7l0.94-0.44l-0.22-0.11L535.74,216.7z M535.8,226.1h0.33l-0.17-0.17
			L535.8,226.1z M535.85,235.61l0.11,0.17l0.11-0.17l-0.11-0.22L535.85,235.61z M535.85,237.04l0.11,0.17l0.11-0.17l-0.11-0.17
			L535.85,237.04z M535.97,225.38l-0.06,0.11l0.06,0.06l-0.06-0.11L535.97,225.38z M535.91,231.85l0.06,0.17l0.11-0.17l-0.11-0.22
			L535.91,231.85z M535.91,236.32l0.06,0.17l0.11-0.06l-0.11-0.11H535.91z M536.13,221.68h0.22v-0.17L536.13,221.68z M536.13,229.64
			h0.22v-0.22h-0.22V229.64z M536.24,234l-0.11,0.11h0.22L536.24,234z M536.19,218.36l0.17,0.17l0.11-0.17l-0.11-0.17L536.19,218.36
			z M536.41,227.04l0.11,0.17l0.11-0.17l-0.11-0.17L536.41,227.04z M536.52,222.4l0.17-0.17h-0.17V222.4z M537.07,215.43l0.17-0.28
			l-0.55-0.28v0.05L537.07,215.43z M537.07,218.75c0-0.17-0.22-0.22-0.39-0.22C536.85,218.69,536.96,218.75,537.07,218.75z
			 M536.68,225.16c0,0.28,0.17,0.44,0.5,0.44L536.68,225.16z M536.9,217.64h0.17v-0.28h-0.17V217.64z M537.13,215.93l0.11,0.22
			l0.17-0.22l-0.17-0.22L537.13,215.93z M538.29,220.41c-0.5,0-0.66,0.5-0.83,0.88l0.11,0.06c0.5,0,0.55-0.72,0.99-0.77
			L538.29,220.41z M537.68,219.13l0.11,0.17l0.17-0.17l-0.17-0.22L537.68,219.13z M538.73,218.36l-0.77,1.16
			c0.39,0,1.22-0.33,1.22-0.77l-0.33-0.39H538.73z M538.18,214.6h0.17v-0.17h-0.17V214.6z M538.56,217.64l0.17-0.28h-0.39
			L538.56,217.64z M539.12,220.24c-0.06,0.28-0.33,0.88-0.33,1.11l0.11,0.17L539.12,220.24z M538.89,220.02
			c0.17,0.17,0.39,0.22,0.55,0.39v-0.39H538.89z M539.23,217.64l0.06,0.17l0.11-0.22l-0.11-0.22L539.23,217.64z M539.28,216.81h0.17
			v-0.11h-0.17V216.81z M539.72,219.3l-0.22,0.17l0.22,0.17V219.3z M540.05,215.98l-0.05,0.17l0.05,0.11l0.11-0.11l-0.05-0.17
			H540.05z M541.38,219.3l-0.66-0.39l-0.33,0.17L541.38,219.3z M540.88,222.23h0.55l-0.22-0.55L540.88,222.23z M540.94,218.03
			c0.11-0.06,0.28-0.17,0.5-0.22h-0.5V218.03z M541.22,220.96h0.17v-0.39h-0.17V220.96z M541.55,221.35l0.22,0.17l0.11-0.17
			l-0.11-0.22L541.55,221.35z M541.82,220.13h-0.11l0.11,0.17l0.11-0.11L541.82,220.13z M541.82,222.45l0.11,0.17v-0.39
			L541.82,222.45z M541.93,221.68h0.11l-0.11-0.17V221.68z M542.04,219.08h0.28v-0.17h-0.28V219.08z M542.65,220.74
			c-0.22,0-0.33,0.06-0.33,0.22h0.33V220.74z M542.38,222.06l0.11,0.17l0.11-0.17l-0.11-0.22L542.38,222.06z M542.71,223.72
			l0.17,0.17l0.11-0.17l-0.11-0.22L542.71,223.72z M542.93,220.41c0.5,0,0.5-0.39,0.5-0.77
			C543.09,219.74,542.93,220.02,542.93,220.41z M543.04,221.51l0.33,0.06l-0.33-0.28V221.51z M543.76,224.05l0.22,0.39l0.39-0.39
			l-0.22-0.55l0.17-0.33l-0.33-0.94l-0.22,0.39l0.22,0.55L543.76,224.05z M544.26,221.07l-0.11,0.06h0.17L544.26,221.07z
			 M545.31,224.11c0,0.5-0.44,0.77-0.44,1.27h0.17c0.17-0.06,0.66-0.28,0.83-0.22l-0.22-0.55h0.11l0.28-0.39
			c0-0.39-0.44-0.33-0.61-0.72c0.39-0.28,0.94-0.44,0.94-1.05l-0.66,0.55l-0.83-0.22l0.39,0.39c-0.28,0.39-0.39,0.77-0.39,1.11
			l0.39-0.22L545.31,224.11z M544.86,226.65h0.22v-0.17L544.86,226.65z M545.03,220.96l-0.06,0.05l0.11,0.11v-0.17H545.03z
			 M545.58,227.43l0.22,0.33v-0.72L545.58,227.43z M545.64,226.1c0.39,0,0.55-0.33,0.55-0.61
			C545.91,225.49,545.8,225.88,545.64,226.1z M546.19,226.49l0.55-0.17l-0.22-0.39L546.19,226.49z M548.4,226.65
			c-0.44-1.33,0.39-0.22,0.39-1.16c0-0.61-1.77-0.61-2.05-1.05l-0.22,0.17l0.55,0.94l0.39-0.39l0.77,0.44l-0.39,0.33L548.4,226.65z
			 M546.74,227.04h0.11l0.5-0.77c-0.39,0-0.61,0.33-0.83,0.61L546.74,227.04z M546.69,223.17c0,0.28,0.39,0.39,0.61,0.39l-0.39-0.77
			L546.69,223.17z M547.07,228.86h0.22v-0.17h-0.22V228.86z M547.3,225.93h0.17l-0.17-0.22V225.93z M547.35,227.92v0.17l0.11-0.11
			L547.35,227.92z M547.46,222.78h0.17v-0.17h-0.17V222.78z M547.85,227.98l0.55-0.77h-0.17L547.85,227.98z M548.12,223.28
			l-0.11,0.05l0.06,0.11l0.11-0.11L548.12,223.28z M549.01,227.43l-0.28,0.44v0.11c0.39,0,0.39-0.17,0.39-0.55H549.01z
			 M549.12,226.32h0.17v-0.22h-0.17V226.32z M549.12,230.19v0.06c0.11-0.06,0.17-0.11,0.17-0.22v-0.06L549.12,230.19z
			 M549.34,226.87l0.17,0.17l0.11-0.17l-0.11-0.22L549.34,226.87z M549.4,224.99l0.05,0.17l0.11-0.11l-0.05-0.22L549.4,224.99z
			 M549.67,226.49h0.17v-0.17L549.67,226.49z M549.84,229.97v-0.33l-0.17,0.17L549.84,229.97z M550.17,229.25
			c0.28,0,0.44-0.17,0.44-0.55C550.39,228.92,550.23,229.08,550.17,229.25z M550.56,226.87l-0.05,0.06l0.11,0.11v-0.17H550.56z
			 M550.61,231.07h0.17v-0.17L550.61,231.07z M551.33,229.42l0.17-0.17h-0.17V229.42z M551.88,227.43h0.39l-0.39-0.22V227.43z
			 M551.94,230.74l0.11,0.17l0.17-0.17l-0.17-0.22L551.94,230.74z M552.05,229.64h0.33c0-0.39,0.28-0.61,0.28-0.99
			C552.22,228.64,552.05,229.25,552.05,229.64z M552.82,233.12l0.33-0.55l-0.55,0.28L552.82,233.12z M552.88,228.53l0.11,0.17
			l0.11-0.17l-0.11-0.22L552.88,228.53z M553.15,231.9h0.22l-0.22-0.28V231.9z M553.54,234.94v-0.11h-0.17L553.54,234.94z
			 M554.32,234.28l-0.22-0.44l-0.17,0.28L554.32,234.28z M554.26,233.12v0.17l0.22-0.39v-0.17L554.26,233.12z M554.32,234.83
			l0.17,0.11l0.11-0.11l-0.11-0.17L554.32,234.83z M554.65,229.97l0.39-0.72l-0.55,0.55L554.65,229.97z M554.7,235.61l0.11,0.17
			l0.17-0.17l-0.17-0.22L554.7,235.61z M554.98,236.54c0.28,0,0.33-0.44,0.39-0.61C555.09,236.1,554.98,236.27,554.98,236.54z
			 M555.37,230.74l-0.06-0.06l-0.11,0.11c0.06,0.06,0.06,0.06,0.11,0.06l0.06-0.06V230.74z M555.59,234.83l0.17-0.44l-0.39-0.39
			L555.59,234.83z M555.92,227.76l0.22-0.17h-0.22V227.76z M556.19,208.58l0.11,0.17l0.99-0.17l-0.99-0.22L556.19,208.58z
			 M556.19,219.47l0.11,0.17l0.11-0.17l-0.11-0.17L556.19,219.47z M556.19,229.47l0.11,0.17l0.99-0.17l-0.99-0.22L556.19,229.47z
			 M556.47,211.29c-0.11,0.06-0.17,0.22-0.17,0.39v0.17l0.28-0.28L556.47,211.29z M556.31,212.67l0.5,0.39l0.06-0.11l-0.39-0.39
			L556.31,212.67z M556.31,237.43h0.28c0-0.17-0.11-0.33-0.28-0.39V237.43z M556.36,234.83l0.11,0.11l0.17-0.11l-0.17-0.17
			L556.36,234.83z M556.64,223.72l-0.05,0.06l0.11,0.11v-0.17H556.64z M556.58,225.1h0.17l-0.05-0.11L556.58,225.1z M556.75,216.48
			c0.17,0.17,0.17,0.39,0.22,0.55l-0.11-0.77L556.75,216.48z M557.58,208.36c-0.44,0-0.77,0.39-0.77,0.83
			c0.28-0.22,0.66-0.44,1.05-0.44L557.58,208.36z M557.24,213.5c-0.22,0.22-0.44,0.33-0.44,0.72l0.61-0.44L557.24,213.5z
			 M557.02,210.18l-0.11,0.22l0.11,0.17l-0.11-0.17L557.02,210.18z M556.91,224.27l0.11,0.17l0.17-0.17l-0.17-0.22L556.91,224.27z
			 M557.41,226.32c-0.28,0-0.44,0.17-0.44,0.55c0.28,0,0.44-0.11,0.44-0.39V226.32z M557.02,212.23h0.22v-0.11h-0.22V212.23z
			 M557.02,219.08h0.39c-0.06-0.11-0.22-0.17-0.39-0.17V219.08z M557.02,236.05l0.22-0.11v-0.11l-0.22-0.33V236.05z M557.19,217.25
			l0.22,1.11v-1.55L557.19,217.25z M557.3,212.61l0.11,0.17l0.11-0.17l-0.11-0.22L557.3,212.61z M557.3,237.26l0.11,0.17l0.11-0.17
			l-0.11-0.22L557.3,237.26z M557.47,220.02l0.11,0.17l0.22-0.17l-0.22-0.17L557.47,220.02z M557.58,209.85h0.39
			c0-0.17-0.06-0.22-0.22-0.22h-0.17V209.85z M557.85,211.56c-0.17,0-0.28,0.11-0.28,0.28c0.17,0,0.28-0.06,0.28-0.22V211.56z
			 M557.85,234.67l-0.22,0.17l0.22,0.11V234.67z M557.85,233.84c-0.05,0-0.11,0.06-0.11,0.17l0.11,0.11V233.84z M557.85,215.15
			c0.06-0.06,0.11-0.11,0.11-0.28C557.91,214.93,557.85,215.04,557.85,215.15z M557.85,216.54h0.22c0-0.11-0.06-0.22-0.22-0.39
			V216.54z M557.96,222.73l-0.11,0.06l0.06,0.11l0.06-0.11V222.73z M557.85,230.8h0.11l-0.11-0.28V230.8z M557.96,225.16
			c-0.06,0.22,0.11,0.55,0.17,0.77L557.96,225.16z M558.52,211.56v-0.22c0-0.28-0.17-0.44-0.44-0.44L558.52,211.56z M558.24,210.12
			l-0.11,0.05l0.17,0.11l0.11-0.11L558.24,210.12z M558.41,212.67l0.55-0.28l-0.44-0.17h-0.11V212.67z M558.41,213.88
			c0.05,0.11,0.17,0.22,0.28,0.28v-0.28H558.41z M558.41,216.81c0.05,0.28,0.22,0.99,0.61,0.99h0.05l-0.55-1.11L558.41,216.81z
			 M558.52,219.47l0.17-0.17l-0.17-0.22l-0.11,0.22L558.52,219.47z M558.41,221.68l0.11,0.17l0.11-0.17l-0.11-0.17L558.41,221.68z
			 M558.9,215.43c-0.17,0.17-0.28,0.33-0.33,0.5l0.11,0.22L558.9,215.43z M558.57,239.64l0.11,0.17l0.05-0.17l-0.05-0.22
			L558.57,239.64z M558.68,237.21h0.39v-0.17h-0.39V237.21z M558.74,213.55l0.22,0.22v-0.44L558.74,213.55z M558.74,214.82
			l0.39,0.22l-0.17-0.44L558.74,214.82z M558.74,224.66l0.22,0.17v-0.39L558.74,224.66z M558.74,225.77l0.22,0.17v-0.39
			L558.74,225.77z M559.07,237.98l-0.11,0.72h0.77l-0.55-0.83L559.07,237.98z M558.96,240.36h0.11v-0.39h-0.11V240.36z
			 M559.12,215.93l0.11,0.22l0.22-0.22l-0.22-0.22L559.12,215.93z M559.12,220.96c0.06,0.17,0.17,0.22,0.33,0.39l-0.22-0.61
			L559.12,220.96z M559.12,235.94l0.11,0.11l0.22-0.11l-0.22-0.17L559.12,235.94z M559.18,218.64l0.05,0.11l0.11-0.11l-0.11-0.11
			L559.18,218.64z M559.23,210.57c0,0.17,0.11,0.33,0.28,0.39v-0.39H559.23z M559.23,224.05l0.39-0.17h-0.39V224.05z M559.51,210.01
			h0.39c-0.22-0.17,0.55-0.22,0.61-0.22l-0.99,0.06V210.01z M559.57,240.25l0.06,0.11l0.11-0.06l-0.11-0.11L559.57,240.25z
			 M560.06,225.16l0.22-0.33h-0.5L560.06,225.16z M559.9,221.68h0.17l-0.17-0.17V221.68z M560.01,226.04l-0.11,0.06l0.11,0.11
			l0.17-0.11L560.01,226.04z M559.9,226.87c0,0.11,0.11,0.17,0.28,0.17v-0.17H559.9z M560.62,241.85c0-0.39-0.17-0.77-0.61-0.77
			h-0.11L560.62,241.85z M559.95,237.6l0.11,0.17v-0.33L559.95,237.6z M560.17,238.98l-0.11,0.11h0.11V238.98z M560.62,216.92
			l-0.06-0.17l-0.11,0.06l-0.28-0.11L560.62,216.92z M560.17,217.64v-0.11V217.64z M560.17,231.9v-0.11V231.9z M560.23,220.24
			l0.22,0.17l0.11,0.94l-0.11-1.33L560.23,220.24z M560.23,224.44l0.22,0.17l0.11-0.17l-0.11-0.17L560.23,224.44z M560.34,215.1
			l0.28,0.33c0-0.17-0.06-0.28-0.17-0.39L560.34,215.1z M560.51,239.81l0.11,0.17v-0.33L560.51,239.81z M561.06,240.75l0.11,0.17
			l0.11-0.17l-0.11-0.22L561.06,240.75z'
					/>
				</g>
			</g>
			<path
				className={`svg-map-fill marker ${
					props.rumbek ? 'active-marker' : null
				}`}
				id='rumbek'
				d='M470.66,560.28c-5.09,0-9.21,4.13-9.21,9.22c0,5.09,4.12,9.21,9.21,9.21c5.08,0,9.21-4.13,9.21-9.21
	C479.87,564.41,475.75,560.28,470.66,560.28 M484.07,556.09l-3.3,3.31c2.51,2.6,4.07,6.15,4.07,10.1c0,3.94-1.56,7.5-4.07,10.1
	l3.3,3.31c3.35-3.46,5.43-8.19,5.44-13.41C489.5,564.27,487.42,559.54,484.07,556.09 M489.82,550.33l-3.3,3.3
	c4.02,4.08,6.51,9.66,6.51,15.86c0,6.2-2.49,11.78-6.51,15.86l3.3,3.3c4.87-4.91,7.87-11.68,7.87-19.16
	C497.69,562.02,494.69,555.24,489.82,550.33 M457.26,556.09c-3.35,3.45-5.43,8.19-5.43,13.41c0.01,5.21,2.08,9.95,5.43,13.41
	l3.31-3.31c-2.51-2.59-4.07-6.15-4.07-10.1c0-3.95,1.55-7.5,4.07-10.1L457.26,556.09z M454.81,553.63l-3.31-3.3
	c-4.86,4.91-7.87,11.68-7.87,19.16s3.01,14.25,7.87,19.17l3.31-3.31c-4.02-4.07-6.51-9.66-6.51-15.86
	C448.3,563.29,450.78,557.71,454.81,553.63'
			/>
			<path
				className={`svg-map-fill marker ${
					props.adjumani ? 'active-marker' : null
				}`}
				id='adjumani'
				d='M573.94,886.93c-5.09,0-9.21,4.13-9.21,9.22c0,5.09,4.12,9.21,9.21,9.21c5.08,0,9.21-4.13,9.21-9.21
	C583.15,891.06,579.02,886.93,573.94,886.93 M587.35,882.74l-3.3,3.31c2.51,2.6,4.07,6.15,4.07,10.1c0,3.94-1.56,7.5-4.07,10.1
	l3.3,3.31c3.35-3.46,5.43-8.19,5.44-13.41C592.77,890.93,590.7,886.19,587.35,882.74 M593.1,876.99l-3.3,3.3
	c4.02,4.08,6.51,9.66,6.51,15.86c0,6.2-2.49,11.78-6.51,15.86l3.3,3.3c4.87-4.91,7.87-11.68,7.87-19.16
	C600.97,888.67,597.97,881.9,593.1,876.99 M560.53,882.74c-3.35,3.45-5.43,8.19-5.43,13.41c0.01,5.21,2.08,9.95,5.43,13.41
	l3.31-3.31c-2.51-2.59-4.07-6.15-4.07-10.1c0-3.95,1.55-7.5,4.07-10.1L560.53,882.74z M558.08,880.29l-3.31-3.3
	c-4.86,4.91-7.87,11.68-7.87,19.16c0,7.48,3.01,14.25,7.87,19.17l3.31-3.31c-4.02-4.07-6.51-9.66-6.51-15.86
	C551.58,889.95,554.06,884.36,558.08,880.29'
			/>
			<g
				className={`svg-map-fill marker ${
					props.juba ? 'active-marker' : null
				}`}
				id='juba'
			>
				<path
					d='M638.79,757.28c-0.75,0-1.5-0.18-2.19-0.54l-9.88-5.2l-9.88,5.2c-1.6,0.84-3.49,0.7-4.95-0.36
	c-1.46-1.06-2.17-2.82-1.87-4.6l1.89-11.01l-8-7.79c-1.29-1.26-1.75-3.1-1.19-4.82c0.56-1.71,2.01-2.94,3.79-3.2l11.05-1.61
	l4.94-10.01c0.8-1.62,2.41-2.62,4.21-2.62c1.8,0,3.42,1,4.21,2.62l4.94,10.01l11.05,1.6c1.78,0.26,3.24,1.49,3.79,3.2
	c0.56,1.71,0.1,3.56-1.19,4.81l-8,7.79l1.89,11c0.3,1.78-0.41,3.54-1.87,4.6C640.72,756.97,639.76,757.28,638.79,757.28z
	 M627.18,751.29L627.18,751.29C627.17,751.29,627.17,751.29,627.18,751.29z M609.3,730.3l6.94,6.76c1.11,1.08,1.61,2.63,1.35,4.16
	l-1.64,9.55l8.58-4.51c1.37-0.72,3-0.72,4.38,0l8.57,4.51l-1.64-9.55c-0.26-1.52,0.24-3.08,1.35-4.16l6.94-6.76l-9.59-1.39
	c-1.53-0.22-2.85-1.19-3.54-2.57l-4.29-8.69l-4.29,8.69c-0.68,1.39-2.01,2.35-3.54,2.57L609.3,730.3z M625.83,715.86
	C625.83,715.86,625.83,715.86,625.83,715.86L625.83,715.86z'
				/>
				<ellipse cx='626.71' cy='735.81' rx='4.78' ry='6.75' />
				<g className='label-juba'>
					<path
						d='M653.96,746.54c0.5,0.04,1.09,0.04,1.59,0.04c1.47,0,2.64-0.55,2.64-2.81v-22.4h4.61v22.07
		c0,5.87-3.15,7.38-6.63,7.38c-0.84,0-1.47,0-2.22-0.08V746.54z'
					/>
					<path
						d='M670.37,721.37v22.65c0,2.1,0.92,2.85,2.39,2.85c1.47,0,2.39-0.75,2.39-2.85v-22.65h4.36v22.36
		c0,4.7-2.35,7.38-6.88,7.38s-6.88-2.68-6.88-7.38v-22.36H670.37z'
					/>
					<path
						d='M696.46,728.13v1.05c0,3.02-0.92,4.95-2.98,5.91c2.47,0.96,3.44,3.19,3.44,6.29v2.39
		c0,4.53-2.39,6.96-7.01,6.96h-7.26v-29.36h6.96C694.4,721.37,696.46,723.6,696.46,728.13z M687.27,725.57v7.76h1.8
		c1.72,0,2.77-0.76,2.77-3.1v-1.64c0-2.1-0.71-3.02-2.35-3.02H687.27z M687.27,737.52v9.02h2.64c1.55,0,2.39-0.71,2.39-2.89v-2.56
		c0-2.73-0.88-3.57-2.98-3.57H687.27z'
					/>
					<path
						d='M714.62,750.74h-4.66l-0.8-5.33h-5.66l-0.8,5.33h-4.24l4.7-29.36h6.75L714.62,750.74z M704.09,741.43h4.45
		l-2.22-14.85L704.09,741.43z'
					/>
					<g>
						<path
							d='M719.8,743.46l-2.22-6.84h2.45l0.93,3.3l0.08,0.39h0.05l0.08-0.39l0.93-3.3h2.45l-2.22,6.84l2.43,7.28h-2.53
		l-1.06-3.56l-0.08-0.39h-0.05l-0.08,0.39l-1.06,3.56h-2.53L719.8,743.46z'
						/>
						<path
							d='M725.74,737.78v-1.32c0-1.26,0.18-2.5,0.72-3.41c0.54-0.9,1.47-1.5,3-1.5c1.42,0,2.38,0.54,2.94,1.37
		c0.57,0.85,0.77,2.04,0.77,3.36c0,1.94-0.72,4.23-1.63,6.38c-0.93,2.17-2.01,4.11-2.74,5.32h4.42v2.76h-7.56v-2.76
		c0.85-1.32,1.63-2.66,2.32-4c0.59-1.14,1.16-2.43,1.6-3.74c0.44-1.32,0.77-2.61,0.8-3.77c0-0.52-0.03-1.11-0.16-1.55
		c-0.13-0.44-0.36-0.77-0.77-0.77c-0.39,0-0.62,0.28-0.75,0.7c-0.13,0.44-0.18,1.01-0.18,1.63v1.32H725.74z'
						/>
					</g>
				</g>
			</g>
			<path
				className={`svg-map-fill marker ${
					props.lietnhom ? 'active-marker' : null
				}`}
				id='lietnhom'
				d='M372.57,422.21c-5.06,0-9.16,4.1-9.16,9.16c0,5.05,4.1,9.15,9.16,9.15c5.05,0,9.15-4.1,9.15-9.15
	C381.72,426.31,377.62,422.21,372.57,422.21 M385.89,418.04l-3.28,3.29c2.49,2.58,4.04,6.11,4.04,10.04c0,3.92-1.55,7.46-4.04,10.03
	l3.28,3.29c3.33-3.43,5.39-8.14,5.41-13.32C391.28,426.18,389.22,421.47,385.89,418.04 M391.61,412.32l-3.28,3.28
	c3.99,4.05,6.46,9.6,6.46,15.76c0,6.16-2.47,11.71-6.46,15.76l3.28,3.28c4.84-4.88,7.82-11.61,7.82-19.04
	C399.43,423.94,396.45,417.2,391.61,412.32 M359.25,418.04c-3.33,3.43-5.39,8.14-5.4,13.33c0.01,5.18,2.07,9.89,5.4,13.32l3.29-3.29
	c-2.5-2.58-4.04-6.12-4.04-10.03c0-3.93,1.54-7.46,4.04-10.04L359.25,418.04z M356.81,415.6l-3.29-3.28
	c-4.83,4.88-7.82,11.61-7.82,19.04c0,7.43,2.99,14.16,7.82,19.05l3.29-3.29c-4-4.05-6.46-9.6-6.46-15.76
	C350.35,425.2,352.81,419.65,356.81,415.6'
			/>
			<g
				className={`svg-map-fill marker ${
					props.bor ? 'active-marker' : null
				}`}
				id='bor'
			>
				<path
					d='M627.99,608.21c-5.06,0-9.16,4.1-9.16,9.16c0,5.05,4.1,9.15,9.16,9.15c5.05,0,9.15-4.1,9.15-9.15
	C637.14,612.31,633.04,608.21,627.99,608.21 M641.31,604.05l-3.28,3.29c2.49,2.58,4.04,6.11,4.04,10.04c0,3.92-1.55,7.46-4.04,10.03
	l3.28,3.29c3.33-3.43,5.39-8.14,5.41-13.32C646.7,612.18,644.64,607.48,641.31,604.05 M647.03,598.33l-3.28,3.28
	c3.99,4.05,6.46,9.6,6.46,15.76c0,6.16-2.47,11.71-6.46,15.76l3.28,3.28c4.84-4.88,7.82-11.61,7.82-19.04
	C654.85,609.94,651.87,603.21,647.03,598.33 M614.66,604.05c-3.33,3.43-5.39,8.14-5.4,13.33c0.01,5.18,2.07,9.89,5.4,13.32
	l3.29-3.29c-2.5-2.58-4.04-6.12-4.04-10.03c0-3.93,1.54-7.46,4.04-10.04L614.66,604.05z M612.23,601.61l-3.29-3.28
	c-4.83,4.88-7.82,11.61-7.82,19.04c0,7.43,2.99,14.16,7.82,19.05l3.29-3.29c-4-4.05-6.46-9.6-6.46-15.76
	C605.76,611.21,608.23,605.66,612.23,601.61'
				/>
				<g>
					<path
						d='M658.51,629.13l-2.22-6.84h2.45l0.93,3.3l0.08,0.39h0.05l0.08-0.39l0.93-3.3h2.45l-2.22,6.84l2.43,7.28h-2.53
		l-1.06-3.56l-0.08-0.39h-0.05l-0.08,0.39l-1.06,3.56h-2.53L658.51,629.13z'
					/>
					<path
						d='M664.45,623.45v-1.32c0-1.26,0.18-2.5,0.72-3.41c0.54-0.9,1.47-1.5,3-1.5c1.42,0,2.38,0.54,2.94,1.37
		c0.57,0.85,0.77,2.04,0.77,3.36c0,1.94-0.72,4.23-1.63,6.38c-0.93,2.17-2.01,4.11-2.74,5.32h4.42v2.76h-7.56v-2.76
		c0.85-1.32,1.63-2.66,2.32-4c0.59-1.14,1.16-2.43,1.6-3.74c0.44-1.32,0.77-2.61,0.8-3.77c0-0.52-0.03-1.11-0.16-1.55
		c-0.13-0.44-0.36-0.77-0.77-0.77c-0.39,0-0.62,0.28-0.75,0.7c-0.13,0.44-0.18,1.01-0.18,1.63v1.32H664.45z'
					/>
				</g>
			</g>
			<path
				className={`svg-map-fill marker ${
					props.boma ? 'active-marker' : null
				}`}
				id='boma'
				d='M819.5,608.21c-5.06,0-9.16,4.1-9.16,9.16c0,5.05,4.1,9.15,9.16,9.15c5.05,0,9.15-4.1,9.15-9.15
	C828.65,612.31,824.55,608.21,819.5,608.21 M832.82,604.05l-3.28,3.29c2.49,2.58,4.04,6.11,4.04,10.04c0,3.92-1.55,7.46-4.04,10.03
	l3.28,3.29c3.33-3.43,5.39-8.14,5.41-13.32C838.21,612.18,836.15,607.48,832.82,604.05 M838.54,598.33l-3.28,3.28
	c3.99,4.05,6.46,9.6,6.46,15.76c0,6.16-2.47,11.71-6.46,15.76l3.28,3.28c4.84-4.88,7.82-11.61,7.82-19.04
	C846.36,609.94,843.38,603.21,838.54,598.33 M806.17,604.05c-3.33,3.43-5.39,8.14-5.4,13.33c0.01,5.18,2.07,9.89,5.4,13.32
	l3.29-3.29c-2.5-2.58-4.04-6.12-4.04-10.03c0-3.93,1.54-7.46,4.04-10.04L806.17,604.05z M803.74,601.61l-3.29-3.28
	c-4.83,4.88-7.82,11.61-7.82,19.04c0,7.43,2.99,14.16,7.82,19.05l3.29-3.29c-4-4.05-6.46-9.6-6.46-15.76
	C797.27,611.21,799.74,605.66,803.74,601.61'
			/>
			<path
				className={`svg-map-fill marker ${
					props.yei ? 'active-marker' : null
				}`}
				id='yei'
				d='M561.9,781.33c-5.06,0-9.16,4.1-9.16,9.16c0,5.05,4.1,9.15,9.16,9.15c5.05,0,9.15-4.1,9.15-9.15
	C571.06,785.44,566.96,781.33,561.9,781.33 M575.23,777.17l-3.28,3.29c2.49,2.58,4.04,6.11,4.04,10.04c0,3.92-1.55,7.46-4.04,10.03
	l3.28,3.29c3.33-3.43,5.39-8.14,5.41-13.32C580.62,785.3,578.56,780.6,575.23,777.17 M580.95,771.45l-3.28,3.28
	c3.99,4.05,6.46,9.6,6.46,15.76c0,6.16-2.47,11.71-6.46,15.76l3.28,3.28c4.84-4.88,7.82-11.61,7.82-19.04
	C588.77,783.06,585.78,776.33,580.95,771.45 M548.58,777.17c-3.33,3.43-5.39,8.14-5.4,13.33c0.01,5.18,2.07,9.89,5.4,13.32
	l3.29-3.29c-2.5-2.58-4.04-6.12-4.04-10.03c0-3.93,1.54-7.46,4.04-10.04L548.58,777.17z M546.14,774.73l-3.29-3.28
	c-4.83,4.88-7.82,11.61-7.82,19.04c0,7.43,2.99,14.16,7.82,19.05l3.29-3.29c-4-4.05-6.46-9.6-6.46-15.76
	C539.68,784.33,542.15,778.78,546.14,774.73'
			/>
			<path
				className={`svg-map-fill marker ${
					props.lainya ? 'active-marker' : null
				}`}
				id='lainya'
				d='M582.9,750.41c-5.06,0-9.16,4.1-9.16,9.16c0,5.05,4.1,9.15,9.16,9.15c5.05,0,9.15-4.1,9.15-9.15
	C592.05,754.51,587.95,750.41,582.9,750.41 M596.22,746.24l-3.28,3.29c2.49,2.58,4.04,6.11,4.04,10.04c0,3.92-1.55,7.46-4.04,10.03
	l3.28,3.29c3.33-3.43,5.39-8.14,5.41-13.32C601.61,754.38,599.55,749.68,596.22,746.24 M601.94,740.53l-3.28,3.28
	c3.99,4.05,6.46,9.6,6.46,15.76c0,6.16-2.47,11.71-6.46,15.76l3.28,3.28c4.84-4.88,7.82-11.61,7.82-19.04
	C609.76,752.14,606.78,745.4,601.94,740.53 M569.57,746.24c-3.33,3.43-5.39,8.14-5.4,13.33c0.01,5.18,2.07,9.89,5.4,13.32l3.29-3.29
	c-2.5-2.58-4.04-6.12-4.04-10.03c0-3.93,1.54-7.46,4.04-10.04L569.57,746.24z M567.14,743.81l-3.29-3.28
	c-4.83,4.88-7.82,11.61-7.82,19.04s2.99,14.16,7.82,19.05l3.29-3.29c-4-4.05-6.46-9.6-6.46-15.76
	C560.67,753.41,563.14,747.86,567.14,743.81'
			/>
			<path
				className={`svg-map-fill marker ${
					props.kajo ? 'active-marker' : null
				}`}
				id='kajo-keji'
				d='M624.23,799.66c-5.09,0-9.21,4.13-9.21,9.22c0,5.09,4.12,9.21,9.21,9.21c5.08,0,9.21-4.13,9.21-9.21
	C633.44,803.79,629.31,799.66,624.23,799.66 M637.63,795.47l-3.3,3.31c2.51,2.6,4.07,6.15,4.07,10.1c0,3.94-1.56,7.5-4.07,10.1
	l3.3,3.31c3.35-3.46,5.43-8.19,5.44-13.41C643.06,803.65,640.99,798.92,637.63,795.47 M643.39,789.71l-3.3,3.3
	c4.02,4.08,6.51,9.66,6.51,15.86c0,6.2-2.49,11.78-6.51,15.86l3.3,3.3c4.87-4.91,7.87-11.68,7.87-19.16
	C651.26,801.4,648.26,794.62,643.39,789.71 M610.82,795.47c-3.35,3.45-5.43,8.19-5.43,13.41c0.01,5.21,2.08,9.95,5.43,13.41
	l3.31-3.31c-2.51-2.59-4.07-6.15-4.07-10.1c0-3.95,1.55-7.5,4.07-10.1L610.82,795.47z M608.37,793.01l-3.31-3.3
	c-4.86,4.91-7.87,11.68-7.87,19.16s3.01,14.25,7.87,19.17l3.31-3.31c-4.02-4.07-6.51-9.66-6.51-15.86
	C601.87,802.67,604.35,797.09,608.37,793.01'
			/>
		</svg>
	);
};

export default SvgMap;
