import DonateButton from '../components/DonateButton';

const Donate = () => {
	return (
		<section className='section section-container'>
			<div className='page-title'>Donate</div>
			<div className='d-flex flex-column align-items-center align-items-md-center'>
				<div className='paypal-but-container d-flex flex-column align-items-center justify-content-center'>
					<h4>Donate with PayPal</h4>
					<p>Click to the donate</p>
					<DonateButton />
				</div>
			</div>
		</section>
	);
};

export default Donate;
