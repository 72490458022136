import { Link } from "react-router-dom";

const NewsSubNav = () => {
    return (
        <div className='news-sub-nav float-end row'>
							<div>
								<Link
									to='/needs'
									className='text-decoration-none text-reset flex-fill'
								>
									Current Needs
								</Link>
							</div>
							<div>
								<Link
									to='/news'
									className='text-decoration-none text-reset'
								>
									Other News
								</Link>
							</div>
						</div>
    )
}
export default NewsSubNav;