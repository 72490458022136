import wibLogo from '../assets/WiB_Logo-web-address_color-1.png';

const Partners = () => {
	return (
		<section className='section section-container'>
			<div className='page-title'>Partners</div>
			<p>
				Radio and Television South Sudan is a joint initiative of the
				government of Lainya County, South Sudan; the Sudanese
				Evangelical Presbyterian Church based in Yei, South Sudan; and
				Water is Basic, an organization that drills clean water wells
				throughout South Sudan.
			</p>
			<div className='partners d-flex flex-row justify-content-center'>
				<div className='partners-charity m-30'>
					<a href='http://waterisbasic.org/'>
						<img src={wibLogo} alt='Water is Basic Logo'/>
					</a>
				</div>
				<div className='partners-church m-30'>
					<a href='http://www.facebook.com/bishopelias.taban' className='text-decoration-none text-white'>
						Evangelical Presbyterian Church
					</a>
				</div>
			</div>
		</section>
	);
};

export default Partners;
