import ReactPlayer from 'react-player';

const Gallery = () => {
	return (
		<section className='section section-container'>
			<div className='page-title'>Gallery</div>
			<div className='d-flex flex-column'>
				<div className='align-self-center'>
					<ReactPlayer url='https://vimeo.com/92557773' />
				</div>				
			</div>
		</section>
	);
};

export default Gallery;
