const Error = () => {
	return (
		<section className='section section-container'>
			<div
				className='page-title'
				style={{ color: 'red', fontSize: '48px', border: 'dashed' }}
			>
				Error Page
			</div>
			<div>
				Something went wrong! There was an{' '}
				<strong style={{ color: 'red', fontSize: '32px' }}>
					Error
				</strong>
				! This is the
				<strong style={{ color: 'red', fontSize: '32px' }}>
					Error Component
				</strong>
				!
			</div>
		</section>
	);
};

export default Error;
