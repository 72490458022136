import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import rssVid from '../assets/rss-anim-color.mp4';

const query = `
{
	rssNewsCollection(order: [date_DESC], limit: 3) {
	items {
		sys {
		id
		}
		title
		titleNoSpaces
	}
	}
	rssNeedsCollection(order: [date_DESC], limit: 3) {
	items {
		sys {
		id
		}
		titleNoSpaces
		title
	}
	}
}`;
const Home = () => {
	const [news, setNews] = useState(null);
	const [needs, setNeeds] = useState(null);

	useEffect(() => {
		window
			.fetch(
				`https://graphql.contentful.com/content/v1/spaces/2coqe7ro5z7q/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer c7JkGXtPKoWHPUqEGeN1hnoJYPLYET4KhVwBE8oy7b0',
					},
					body: JSON.stringify({ query }),
				}
			)
			.then((response) => response.json())
			.then(({ data, errors }) => {
				if (errors) {
					console.error(errors);
				}
				setNeeds(data.rssNeedsCollection);
				setNews(data.rssNewsCollection);
			});
	}, []);

	return (
		<section className='section section-container-video'>
			<div className='d-flex flex-column align-items-center'>
				<div className='banner'>
					<ReactPlayer
						className='home-video'
						url={[{ src: rssVid, type: 'video/mp4' }]}
						playing={true}
						muted={true}
					/>
				</div>
				<div className='home-navigation-container'>
					<div className='news home-box my-3'>
						<h2 className='home-needs-news-title d-flex flex-column justify-content-center align-items-center'>
							Latest News
						</h2>
						<ul>
							{news &&
								news.items.map((article) => {
									return (
										<div key={article.sys.id}>
											<h4 className='news-title-home'>
												<Link
													to={`/news/${article.titleNoSpaces}`}
												>
													{article.title}
												</Link>
											</h4>
										</div>
									);
								})}
						</ul>
					</div>
					<div className='home-locate-box my-3'>
						<Link
							to='/map'
							className='locate text-decoration-none text-white font-brandonTwo d-flex flex-column justify-content-center align-items-center h-100 px-5 lh-1'
						>
							<div>Locate</div>
							<div>a</div>
							<div>Station</div>
						</Link>
					</div>
					<div className='needs home-box my-3'>
						<h2 className='home-needs-news-title d-flex flex-column justify-content-center align-items-center'>
							<div>Current</div>
							<div>Needs</div>
						</h2>
						{needs &&
							needs.items.map((need) => {
								return (
									<div key={need.sys.id}>
										<h4 className='needs-title-home'>
											<Link
												to={`/needs/${need.titleNoSpaces}`}
											>
												{need.title}
											</Link>
										</h4>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Home;
